/* ===================================
    Custom
====================================== */

.center-col { float: none; margin-left: auto; margin-right: auto }
section {
  padding: 130px 0; overflow: hidden;
  .big-section { padding: 160px 0; }
}
section.extra-big-section { padding: 200px 0; }
section.half-section { padding: 80px 0; }
.overlap-section { margin-top: -14%; position: relative }
.col-2-nth .col-md-6:nth-child(2n+1) { clear: left; }
.col-2-nth .col-sm-6:nth-child(2n+1) { clear: left; }
.col-3-nth .col-md-4:nth-child(3n+1) { clear: left; }
.col-3-nth .col-sm-4:nth-child(3n+1) { clear: left; }
.col-4-nth .col-md-3:nth-child(4n+1) { clear: left; }
.col-4-nth .col-sm-3:nth-child(4n+1) { clear: left; }
/* input */
.input-border-bottom { background: transparent; border: 0; border-bottom: 1px solid #484848; padding: 10px 0; margin-bottom: 30px; font-size: 14px; }
.input-border-bottom:focus { background: transparent; border: 0; border-bottom: 1px solid #929292; }
.input-border-bottom::-webkit-input-placeholder { font-weight: 300; font-size: 14px; }
.input-border-bottom::-moz-placeholder { font-weight: 300; font-size: 14px; }
.input-border-bottom:-ms-input-placeholder { font-weight: 300; font-size: 14px; }
.extra-big-input, .extra-big-textarea, .extra-big-select select { padding: 18px 25px; font-size: 14px; line-height: 24px; height: 62px; }
.big-input, .big-textarea, .big-select select { padding: 18px 25px; font-size: 14px; border-radius: 0; }
.medium-input, .medium-textarea, .medium-select select { padding: 12px 20px; font-size: 14px; line-height: normal; border-radius: 0; }
.small-input, .small-textarea { padding: 12px 15px; font-size: 11px; line-height: normal }
.small-select select { padding: 15px 15px; line-height: normal }
.medium-input-light, .medium-textarea-light, .medium-select-light select { padding: 12px 25px; font-size: 12px; line-height: normal }
.extra-small-input, .extra-small-textarea, .extra-small-select select { padding: 9px 17px; font-size: 12px; line-height: normal }
.select-style { width: 100%; overflow: hidden; background: url("../images/select-arrow.png") no-repeat 97% 50%; border: 1px solid #d1d1d1; margin-bottom: 20px }
.select-style select { width: 100%; border: none; box-shadow: none; background-color: transparent; background-image: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; cursor: pointer; }
.select-style select:focus { outline: none; border: none; box-shadow: none }
.input-group input, .input-group textarea, .input-group select { margin: 0; border-radius: 4px 0 0 4px; border-color: $white; padding: 19px 25px; }
.input-group-btn .btn { border-radius: 0 4px 4px 0 }
.input-group-btn .btn.btn-large { line-height: 2px; height: 62px; padding: 15px 25px !important }
.input-group-404 input { height: 62px; }
.input-group .required-error { border: 1px solid #ee2323 !important }
.input-group .required-error, .input-group .required-error + .input-group-btn button, .input-group .required-error + .input-group-btn a.btn { border: 1px solid #ee2323 !important }
.input-border-white .input-border-bottom { border-bottom: 1px solid $white; color: $white; border-top: transparent !important; border-left: transparent !important; border-right: transparent !important }
.input-border-white .input-border-bottom:focus { background: transparent; border-bottom: 1px solid $white; color: $white }
.input-border-white .input-border-bottom::-webkit-input-placeholder { color: $white; }
.input-border-white .input-border-bottom::-moz-placeholder { color: $white; }
.input-border-white .input-border-bottom:-ms-input-placeholder { color: $white; }
.btn .caret { border-top: 4px solid }
input.input-bg { background-color: $gray-100; border-radius: 4px; border: 1px solid rgba($black, .1); padding: 10px 20px; margin-bottom: 15px; }
input.input-bg:focus, textarea.input-bg:focus { border: 0; border: 1px solid rgba($black, .3); }
textarea.input-bg { background-color: $gray-100; border-radius: 4px; border: 1px solid rgba($black, .1); padding: 10px 20px; margin-bottom: 15px; min-height: 130px; }
.input-bg::-webkit-input-placeholder { font-weight: 300; font-size: 14px; }
.input-bg::-moz-placeholder { font-weight: 400; font-size: 14px; }
.input-bg:-ms-input-placeholder { font-weight: 400; font-size: 14px; }
.required-error { border: 1px solid #ee2323 !important }
.input-border-bottom.required-error { border-width: 0 !important; border-bottom-width: 1px !important; border-bottom-color: #ee2323 !important }
#success-subscribe-newsletter { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a; padding: 2px 0; display: none; font-size: 11px }
#success-subscribe-newsletter2 { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a; padding: 2px 0; display: none; font-size: 11px }
#success-contact-form { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a; padding: 2px 0; display: none; font-size: 11px }
#success-contact-form-2 { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a; padding: 2px 0; display: none; font-size: 11px }
#success-contact-form-3 { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a; padding: 2px 0; display: none; font-size: 11px }
#success-project-contact-form { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a; padding: 2px 0; display: none; font-size: 11px }
#success-project-contact-form-4 { clear: both; margin: 0 15px 15px; text-align: center; border: 1px solid #1a940a; color: #1a940a; padding: 2px 0; display: none; font-size: 11px }
/* separator */
.separator-line-verticle-extra-small { width: 1px; height: 8px; }
.separator-line-verticle-small-think { width: 17px; height: 3px; }
.separator-line-verticle-small { width: 1px; height: 13px; }
.separator-line-verticle-small-think2 { width: 64px; height: 7px; }
.separator-line-verticle-large { width: 1px; height: 20px; }
.separator-line-verticle-extra-large { width: 1px; height: 30px; }
.separator-line-verticle-medium-think-full { width: 8px; height: 40px; }
.separator-line-verticle-large-thick { width: 109px; height: 11px }
.separator-line-horrizontal-medium-light { width: 36px; height: 3px; }
.separator-line-horrizontal-medium-light2 { width: 40%; height: 1px; }
.separator-line-horrizontal-medium-light3 { width: 18%; height: 1px; }
.separator-line-horrizontal-medium-think { width: 50px; height: 5px; }
.separator-line-horrizontal-full { width: 100%; height: 1px; }
/* divider */
.divider-full { width: 100%; height: 1px; display: inline-block }
/* opacity */
.opacity-very-light { position: absolute; height: 100%; width: 100%; opacity: 0.2; top: 0; left: 0; }
.opacity-light { position: absolute; height: 100%; width: 100%; opacity: 0.3; top: 0; left: 0; }
.opacity-extra-medium { position: absolute; height: 100%; width: 100%; opacity: 0.5; top: 0; left: 0; }
.opacity-medium { position: absolute; height: 100%; width: 100%; opacity: 0.75; top: 0; left: 0; }
.opacity-full { position: absolute; height: 100%; width: 100%; opacity: 0.8; top: 0; left: 0; }
.opacity-full-dark { position: absolute; height: 100%; width: 100%; opacity: 0.9; top: 0; left: 0; }
.opacity1 { opacity: .1 }
.opacity2 { opacity: .2 }
.opacity3 { opacity: .3 }
.opacity4 { opacity: .4 }
.opacity5 { opacity: .5 }
.opacity6 { opacity: .6 }
.opacity7 { opacity: .7 }
.opacity8 { opacity: .8 }
.opacity9 { opacity: .9 }
/* box-shadow */
.btn-shadow { box-shadow: 0 0 6px rgba($black, .3); }
.box-shadow-light { box-shadow: 0 0 3px rgba($black, .1); }
.box-shadow { box-shadow: 0 0 3px rgba($black, .2); }
.box-shadow-dark { box-shadow: 0 0 4px rgba($black, .4); }
.box-shadow-large { box-shadow: 0 0 12px rgba($black, .1); }
/* z-index */
.z-index-1111 { z-index: 1111; }
.z-index-111 { z-index: 111; }
.z-index-1 { z-index: 1; }
.z-index-2 { z-index: 2; }
.z-index-3 { z-index: 3; }
.z-index-4 { z-index: 4; }
.z-index-5 { z-index: 5; }
.z-index-10 { z-index: 10; }
.z-index-0 { z-index: 0; }
.z-index-minus2 { z-index: -2; }
/* verticle align */
.vertical-align-middle { vertical-align: middle; }
.vertical-align-top { vertical-align: top; }
.vertical-align-bottom { vertical-align: bottom; }
.vertical-middle { -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); position: absolute; top: 50%; left: 0; right: 0 }
.clear-both { clear: both }
.line-break { display: block; }
.no-transition *, .swiper-container *, .mfp-container *, .skillbar-bar-main *, .portfolio-grid *, .parallax, .rev-slider *, .header-searchbar *, .header-social-icon * { transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; -ms-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s; }
.absolute-middle-center { left: 50%; top: 50%; position: absolute; -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); }
.absolute-bottom-center { left: 50%; top: 80%; position: absolute; -ms-transform: translateX(-50%) translateY(-80%); -moz-transform: translateX(-50%) translateY(-80%); -webkit-transform: translateX(-50%) translateY(-80%); transform: translateX(-50%) translateY(-80%); -o-transform: translateX(-50%) translateY(-80%); }