/* ===================================
   Home page
====================================== */

/* home - creative branding agency */
.box-layout {
	padding: 0 60px;
	@media (min-width: 1200px) {
		.container {
			max-width: 1170px;
			width: 100%;
		}
	}
}

/* home - creative business */

.icon-box .icon-box-holder {
	padding-left: 55px;
	height: 112px;
}

.icon-box .icon-box-holder i {
	position: absolute;
	left: 0;
	vertical-align: middle;
	padding-top: 10px;
}

/* home - creative simple portfolio */
.head-text {
	font-size: 80px;
	line-height: 95px;
}

/* home - classic digital agency */
.image-block {
	box-shadow: 0 0 0 rgba(35, 35, 35, 0.6);
	-webkit-box-shadow: 0px 0px 10px 0px rgba($black, 0.8);
	-moz-box-shadow: 0px 0px 10px 0px rgba($black, 0.8);
	box-shadow: 0px 0px 10px 0px rgba($black, 0.5);
	-o-box-shadow: 0px 0px 10px 0px rgba($black, 0.8);
	-ms-box-shadow: 0px 0px 10px 0px rgba($black, 0.8);
}

.attr-nav > ul > li > a {
	padding: 3px 15px
}

.icon-play img {
	width: 60%
}

.icon-play:hover img {
	width: 68%
}

/* home - creative studio */
.icon-box-holder a:hover {
	color: $white;
}

/* home - portfolio personal */
.hover-title-box {
	position: absolute;
	right: -100px;
	opacity: 0;
	top: 50%;
	transform: translateY(-53px);
	-webkit-transform: translateY(-53px);
	-moz-transform: translateY(-53px);
	-ms-transform: translateY(-53px);
	-o-transform: translateY(-53px);
	z-index: 99;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}

.hover-title-box .separator {
	height: 1px;
	content: "";
	position: absolute;
	left: -10px;
	top: 30px;
}

/* home - creative small business */
.gridblock-main {
	padding: 110px 90px;
}

.gridblock-list li {
	width: 33.33%;
	float: left;
	padding: 0;
	text-align: center
}

.gridblock-list > * {
	vertical-align: top;
	display: inline-block;
	content: "";
}

.gridblock-list > :nth-child(3n) {
	margin-right: 0;
}

/* home - blog masonry */
.blog-header-style1 {
	margin: 0 8px;
	padding: 0;
	list-style: none;
	display: block
}

.blog-header-style1 li {
	width: 25%;
	padding: 0 8px;
	margin: 0 -4px 0 0;
	display: inline-block;
	box-sizing: border-box;
	overflow: hidden;
	vertical-align: top;
	transition: 0.8s;
	-webkit-transition: 0.8s;
	-moz-transition: 0.8s;
	-ms-transition: 0.8s;
	-o-transition: 0.8s;
}

.blog-header-style1 li .blog-banner {
	display: block;
	position: relative;
	height: 100%;
}

.blog-header-style1 li figure figcaption {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 50px;
	transform: translateY(60px);
	-webkit-transform: translateY(60px);
	-moz-transform: translateY(60px);
	-ms-transform: translateY(60px);
	-o-transform: translateY(60px);
}

.blog-header-style1 li.blog-column-active figure figcaption {
	transition-delay: 0.5s;
	-webkit-transition-delay: 0.5s;
	-moz-transition-delay: 0.5s;
	-ms-transition-delay: 0.5s;
	-o-transition-delay: 0.5s;
	transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
}

.blog-header-style1 li figure figcaption .btn {
	opacity: 0;
	transform: translateY(60px);
	-webkit-transform: translateY(60px);
	-moz-transform: translateY(60px);
	-ms-transform: translateY(60px);
	-o-transform: translateY(60px);
	transition-delay: 0.3s;
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	-ms-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
}

.blog-header-style1 li.blog-column-active figure figcaption .btn {
	opacity: 1;
	transition-delay: 0.6s;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
}

.blog-header-style1 li.blog-column-active {
	width: 50%;
	transition-duration: 0.8s;
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
}

.blog-header-style1 li .blog-title {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* home - blog clean */
.blog-box .blog-box-image, .blog-box .blog-box-content {
	position: absolute;
	top: 0;
	width: 100%;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
}

.blog-box .blog-box-image {
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	opacity: 1
}

.blog-box .blog-box-content {
	transform: translateY(100%);
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-o-transform: translateY(100%);
	opacity: 0
}

.blog-box:hover .blog-box-image {
	transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	opacity: 0
}

.blog-box:hover .blog-box-content {
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	opacity: 1
}

/* home - classic innovation agency */
.box-separator-line {
	height: 1px;
	content: "";
	position: absolute;
	left: -90px;
	top: 130px;
}

.right-image {
	bottom: -200px;
	position: absolute;
	right: -100px;
	width: 60%;
}

.right-image img {
	max-width: none
}

.content-right-slider .swiper-button-next {
	right: 70px;
}

.content-right-slider .swiper-button-prev {
	left: 70px;
}

/* home - creative designer */
.color-code {
	justify-content: space-around;
	align-items: center;
	z-index: 0;
}

.bg-background-fade {
	background-color: $primary;
}

.color-1, .color-2, .color-3, .color-4, .color-5 {
	-webkit-transition: background-color 700ms linear;
	-moz-transition: background-color 700ms linear;
	-o-transition: background-color 700ms linear;
	-ms-transition: background-color 700ms linear;
	transition: background-color 700ms linear;
}

.color-1 {
	background-color: #007CBC;
}

.color-2 {
	background-color: #4776C1;
}

.color-3 {
	background-color: #706EBF;
}

.color-4 {
	background-color: #9263B6;
}

.color-5 {
	background-color: #AF57A6;
}

.color-6 {
	background-color: #C44B8F;
}

/* home - classic web agency */
.after-before-separator:before, .after-before-separator:after {
	width: 35px;
	margin: 0 15px;
	height: 1px;
	background: rgba(255, 255, 255, .2);
	content: "";
	display: inline-block;
	vertical-align: super;
}

.background-slide {
	height: auto;
	min-height: 700px;
}

/* home - classic corporate */
.rev-slider .tp-bullet {
	width: 35px;
	height: 3px;
	background-color: $white;
	opacity: 0.5;
	border-radius: 0;
	padding: 0 4px;
}

.rev-slider .tp-bullet.selected, .tp-bullet:hover {
	opacity: 1;
}

/* home - portfolio metro */
.text-bold-underline:before {
	background-color: $primary;
	height: 3px;
	width: 100%;
	position: absolute;
	right: 0;
	bottom: 5px;
	content: "";
}

/* home - portfolio  parallax */
.parallax-title {
	padding: 16px 35px;
	display: inline-block
}

/* home - blog grid */
.highlight-bg-text {
	padding: 5px 16px;
	display: inline-block;
	margin-bottom: 15px;
}