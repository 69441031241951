/*==============================
    Display and float
================================*/

.display-block {
  display: block !important
}

.display-inline-block {
  display: inline-block !important
}

.display-inline-flex {
  display: inline-flex !important;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.display-inline {
  display: inline !important
}

.display-none {
  display: none !important
}

.display-inherit {
  display: inherit !important
}

.display-table {
  display: table !important
}

.display-table-cell {
  display: table-cell !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-visible {
  overflow: visible !important
}

.overflow-auto {
  overflow: auto !important
}

.display-flex {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -moz-flex;
  display: -webkit-inline-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -o-flex-flow: row wrap;
}

.display-flex > div:nth-of-type(1) {
  order: 2;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2
}

.display-flex > div:nth-of-type(2) {
  order: 1;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1
}

.display-flex > div:nth-of-type(3) {
  order: 3;
  -webkit-order: 3;
  -moz-order: 3;
  -ms-order: 3
}

/*float*/
.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.float-none {
  float: none !important
}

/*==============================
    Position
================================*/

.position-inherit {
  position: inherit !important
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-right {
  right: 0 !important;
}

.position-left {
  left: 0 !important;
}

.position-top {
  top: 0 !important;
}

/* top */
.top-auto {
  top: auto
}

.top-inherit {
  top: inherit !important;
}

.top-0 {
  top: 0 !important;
}

.top-1 {
  top: 1px
}

.top-2 {
  top: 2px
}

.top-3 {
  top: 3px
}

.top-4 {
  top: 4px
}

.top-5 {
  top: 5px
}

.top-6 {
  top: 6px
}

.top-7 {
  top: 7px
}

.top-8 {
  top: 8px
}

.top-9 {
  top: 9px
}

.top-10 {
  top: 10px
}

.top-15 {
  top: 15px
}

.top-20 {
  top: 20px
}

.top-25 {
  top: 25px
}

.top-30 {
  top: 30px
}

.top-35 {
  top: 35px
}

.top-40 {
  top: 40px
}

.top-45 {
  top: 45px
}

.top-50 {
  top: 50px
}

.top-minus1 {
  top: -1px
}

.top-minus2 {
  top: -2px
}

.top-minus3 {
  top: -3px
}

.top-minus4 {
  top: -4px
}

.top-minus5 {
  top: -5px
}

.top-minus6 {
  top: -6px
}

.top-minus7 {
  top: -7px
}

.top-minus8 {
  top: -8px
}

.top-minus9 {
  top: -9px
}

.top-minus10 {
  top: -10px
}

.top-minus15 {
  top: -15px
}

.top-minus20 {
  top: -20px
}

.top-minus25 {
  top: -25px
}

.top-minus30 {
  top: -30px
}

.top-minus35 {
  top: -35px
}

.top-minus40 {
  top: -40px
}

/* bottom */
.bottom-auto {
  bottom: auto
}

.bottom-0 {
  bottom: 0
}

.bottom-1 {
  bottom: 1px
}

.bottom-2 {
  bottom: 2px
}

.bottom-3 {
  bottom: 3px
}

.bottom-4 {
  bottom: 4px
}

.bottom-5 {
  bottom: 5px
}

.bottom-6 {
  bottom: 6px
}

.bottom-7 {
  bottom: 7px
}

.bottom-8 {
  bottom: 8px
}

.bottom-9 {
  bottom: 9px
}

.bottom-10 {
  bottom: 10px
}

.bottom-15 {
  bottom: 15px
}

.bottom-20 {
  bottom: 20px
}

.bottom-25 {
  bottom: 25px
}

.bottom-30 {
  bottom: 30px
}

.bottom-35 {
  bottom: 35px
}

.bottom-40 {
  bottom: 40px
}

.bottom-45 {
  bottom: 45px
}

.bottom-50 {
  bottom: 50px
}

/* left */
.left-auto {
  left: auto
}

.left-0 {
  left: 0
}

.left-1 {
  left: 1px
}

.left-2 {
  left: 2px
}

.left-3 {
  left: 3px
}

.left-4 {
  left: 4px
}

.left-5 {
  left: 5px
}

.left-6 {
  left: 6px
}

.left-7 {
  left: 7px
}

.left-8 {
  left: 8px
}

.left-9 {
  left: 9px
}

.left-10 {
  left: 10px
}

.left-15 {
  left: 15px
}

.left-20 {
  left: 20px
}

.left-25 {
  left: 25px
}

.left-30 {
  left: 30px
}

.left-35 {
  left: 35px
}

.left-40 {
  left: 40px
}

.left-45 {
  left: 45px
}

.left-50 {
  left: 50px
}

/* right */
.right-auto {
  right: auto
}

.right-0 {
  right: 0
}

.right-1 {
  right: 1px
}

.right-2 {
  right: 2px
}

.right-3 {
  right: 3px
}

.right-4 {
  right: 4px
}

.right-5 {
  right: 5px
}

.right-6 {
  right: 6px
}

.right-7 {
  right: 7px
}

.right-8 {
  right: 8px
}

.right-9 {
  right: 9px
}

.right-10 {
  right: 10px
}

/*================================
    Width
================================*/

.width-1px {
  width: 1px;
}

.width-2px {
  width: 2px;
}

.width-3px {
  width: 3px;
}

.width-4px {
  width: 4px;
}

.width-5px {
  width: 5px;
}

.width-6px {
  width: 6px;
}

.width-7px {
  width: 7px;
}

.width-8px {
  width: 8px;
}

.width-9px {
  width: 9px;
}

.width-10px {
  width: 10px;
}

.width-15px {
  width: 15px;
}

.width-20px {
  width: 20px;
}

.width-25px {
  width: 25px;
}

.width-30px {
  width: 30px;
}

.width-35px {
  width: 35px;
}

.width-40px {
  width: 40px;
}

.width-50px {
  width: 50px;
}

.width-55px {
  width: 55px;
}

.width-60px {
  width: 60px;
}

.width-65px {
  width: 65px;
}

.width-70px {
  width: 70px;
}

.width-75px {
  width: 75px;
}

.width-80px {
  width: 80px;
}

.width-85px {
  width: 85px;
}

.width-90px {
  width: 90px;
}

.width-100px {
  width: 100px;
}

.width-120px {
  width: 120px;
}

.width-130px {
  width: 130px;
}

.width-150px {
  width: 150px;
}

.width-180px {
  width: 180px;
}

.width-200px {
  width: 200px;
}

.width-250px {
  width: 250px;
}

.width-300px {
  width: 300px;
}

.width-350px {
  width: 350px;
}

.width-400px {
  width: 400px;
}

.width-450px {
  width: 450px;
}

.width-500px {
  width: 500px;
}

.width-550px {
  width: 550px;
}

.width-600px {
  width: 600px;
}

.width-650px {
  width: 650px;
}

.width-700px {
  width: 700px;
}

.width-750px {
  width: 750px;
}

.width-800px {
  width: 800px;
}

.width-850px {
  width: 850px;
}

.width-900px {
  width: 900px;
}

.width-950px {
  width: 950px;
}

.width-1000px {
  width: 1000px;
}

.width-10 {
  width: 10%;
}

.width-12 {
  width: 12%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-35 {
  width: 35%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 55%;
}

.width-60 {
  width: 60%;
}

.width-65 {
  width: 65%;
}

.width-70 {
  width: 70%;
}

.width-75 {
  width: 75%;
}

.width-80 {
  width: 80%;
}

.width-85 {
  width: 85%;
}

.width-90 {
  width: 90%;
}

.width-95 {
  width: 95%;
}

.width-100 {
  width: 100%;
}

.width-auto {
  width: auto
}

/*================================
    Height
================================*/

.height-1px {
  height: 1px
}

.height-2px {
  height: 2px
}

.height-3px {
  height: 3px
}

.height-4px {
  height: 4px
}

.height-5px {
  height: 5px
}

.height-6px {
  height: 6px
}

.height-7px {
  height: 7px
}

.height-8px {
  height: 8px
}

.height-9px {
  height: 9px
}

.height-10px {
  height: 10px
}

.height-80px {
  height: 80px
}

.height-90px {
  height: 90px
}

.height-100px {
  height: 100px
}

.height-130px {
  height: 130px
}

.height-150px {
  height: 150px
}

.height-200px {
  height: 200px
}

.height-250px {
  height: 250px
}

.height-300px {
  height: 300px
}

.height-350px {
  height: 350px
}

.height-400px {
  height: 400px
}

.height-450px {
  height: 450px
}

.height-500px {
  height: 500px
}

.height-550px {
  height: 550px
}

.height-600px {
  height: 600px
}

.height-650px {
  height: 650px
}

.height-700px {
  height: 700px
}

.height-50 {
  height: 50%
}

.height-100 {
  height: 100% !important
}

[data-x-mode="true"] {
  .height-100 {
    height: auto !important;
    &.swiper-auto-fade {
      height: 100% !important;
    }
  }
}

.height-auto {
  height: auto !important
}

.max-height-100 {
  max-height: 100%
}

.full-screen {
  min-height: 980px;
}

/* min-height */
.min-height-100px {
  min-height: 100px
}

.min-height-200px {
  min-height: 200px
}

.min-height-300px {
  min-height: 300px
}

.min-height-400px {
  min-height: 400px
}

.min-height-500px {
  min-height: 500px
}

.min-height-600px {
  min-height: 600px
}

.min-height-700px {
  min-height: 700px
}

/* screen height  */
.extra-small-screen {
  height: 300px;
}

.small-screen {
  height: 400px;
}

.one-second-screen {
  height: 600px;
}

.one-third-screen {
  height: 700px;
}

.one-fourth-screen {
  height: 800px;
}

.one-fifth-screen {
  height: 900px;
}

.one-sixth-screen {
  height: 887px;
}

/* equalize inner match height */
.inner-match-height {
  position: relative;
  height: 100%;
}
[data-x-mode="true"] {
  .inner-match-height {
    height: auto;
  }
}

.line-height-130px {
	line-height: 130px;
}