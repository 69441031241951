/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: March 10, 2017
 */
.swiper-container {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	z-index: 1
}

.swiper-container-no-flexbox .swiper-slide {
	float: left
}

.swiper-container-vertical > .swiper-wrapper {
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	flex-direction: column
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box
}

[data-x-mode='true'] .swiper-wrapper {
	height: auto;
}

[data-x-mode="true"] .height-100.swiper-auto-fade {
	height: 100% !important;
}

[data-x-mode="true"] .height-100.swiper-auto-fade .swiper-wrapper {
	height: 100%;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate(0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.swiper-container-multirow > .swiper-wrapper {
	-webkit-box-lines: multiple;
	-moz-box-lines: multiple;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap
}

.swiper-container-free-mode > .swiper-wrapper {
	-webkit-transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
	-ms-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	margin: 0 auto
}

.swiper-slide {
	-webkit-flex-shrink: 0;
	-ms-flex: 0 0 auto;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative
}

[data-x-mode='true'] .swiper-slide {
	height: auto;
}

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
	height: auto
}

.swiper-container-autoheight .swiper-wrapper {
	-webkit-box-align: start;
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-transition-property: -webkit-transform, height;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform, height
}

.swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000
}

.swiper-wp8-horizontal {
	-ms-touch-action: pan-y;
	touch-action: pan-y
}

.swiper-wp8-vertical {
	-ms-touch-action: pan-x;
	touch-action: pan-x
}

.swiper-button-next, .swiper-button-prev {
	position: absolute;
	top: 50%;
	width: 27px;
	height: 44px;
	margin-top: -22px;
	z-index: 10;
	cursor: pointer;
	-moz-background-size: 27px 44px;
	-webkit-background-size: 27px 44px;
	background-size: 27px 44px;
	background-position: center;
	background-repeat: no-repeat
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
	opacity: .35;
	cursor: auto;
	pointer-events: none
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
	left: 10px;
	right: auto
}

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
	right: 10px;
	left: auto
}

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-pagination {
	position: absolute;
	text-align: center;
	-webkit-transition: .3s;
	-moz-transition: .3s;
	-o-transition: .3s;
	transition: .3s;
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	z-index: 10
}

.swiper-pagination.swiper-pagination-hidden {
	opacity: 0
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 10px;
	left: 0;
	width: 100%
}

.swiper-pagination-bullet {
	width: 8px;
	height: 8px;
	display: inline-block;
	border-radius: 100%;
	background: $black;
	opacity: .2
}

button.swiper-pagination-bullet {
	border: none;
	margin: 0;
	padding: 0;
	box-shadow: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-webkit-appearance: none;
	appearance: none
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer
}

.swiper-pagination-white .swiper-pagination-bullet {
	background: $white
}

.swiper-pagination-bullet-active {
	opacity: 1;
	background: #007aff
}

.swiper-pagination-white .swiper-pagination-bullet-active {
	background: $white
}

.swiper-pagination-black .swiper-pagination-bullet-active {
	background: $black
}

.swiper-container-vertical > .swiper-pagination-bullets {
	right: 10px;
	top: 50%;
	-webkit-transform: translate3d(0, -50%, 0);
	-moz-transform: translate3d(0, -50%, 0);
	-o-transform: translate(0, -50%);
	-ms-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0)
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 5px 0;
	display: block
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 5px
}

.swiper-pagination-progress {
	background: rgba(0, 0, 0, .25);
	position: absolute
}

.swiper-pagination-progress .swiper-pagination-progressbar {
	background: #007aff;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: left top;
	-moz-transform-origin: left top;
	-ms-transform-origin: left top;
	-o-transform-origin: left top;
	transform-origin: left top
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
	-webkit-transform-origin: right top;
	-moz-transform-origin: right top;
	-ms-transform-origin: right top;
	-o-transform-origin: right top;
	transform-origin: right top
}

.swiper-container-horizontal > .swiper-pagination-progress {
	width: 100%;
	height: 4px;
	left: 0;
	top: 0
}

.swiper-container-vertical > .swiper-pagination-progress {
	width: 4px;
	height: 100%;
	left: 0;
	top: 0
}

.swiper-pagination-progress.swiper-pagination-white {
	background: rgba(255, 255, 255, .5)
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
	background: $white
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
	background: $black
}

.swiper-container-3d {
	-webkit-perspective: 1200px;
	-moz-perspective: 1200px;
	-o-perspective: 1200px;
	perspective: 1200px
}

.swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d
}

.swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 10
}

.swiper-container-3d .swiper-slide-shadow-left {
	background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-right {
	background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-top {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-bottom {
	background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
	background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
	-ms-perspective: 1200px
}

.swiper-container-cube, .swiper-container-flip {
	overflow: visible
}

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	z-index: 1
}

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
	pointer-events: none
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
	pointer-events: auto
}

.swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-top {
	z-index: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden
}

.swiper-container-cube .swiper-slide {
	visibility: hidden;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	width: 100%;
	height: 100%
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
	-webkit-transform-origin: 100% 0;
	-moz-transform-origin: 100% 0;
	-ms-transform-origin: 100% 0;
	transform-origin: 100% 0
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-next + .swiper-slide, .swiper-container-cube .swiper-slide-prev {
	pointer-events: auto;
	visibility: visible
}

.swiper-container-cube .swiper-cube-shadow {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: $black;
	opacity: .6;
	-webkit-filter: blur(50px);
	filter: blur(50px);
	z-index: 0
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	-webkit-transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
	-ms-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	transition-timing-function: ease-out
}

.swiper-container-fade .swiper-slide {
	pointer-events: none;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	transition-property: opacity
}

.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none
}

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto
}

.swiper-zoom-container {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	text-align: center
}

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain
}

.swiper-scrollbar {
	border-radius: 10px;
	position: relative;
	-ms-touch-action: none;
	background: rgba(0, 0, 0, .1)
}

.swiper-container-horizontal > .swiper-scrollbar {
	position: absolute;
	left: 1%;
	bottom: 3px;
	z-index: 50;
	height: 5px;
	width: 98%
}

.swiper-container-vertical > .swiper-scrollbar {
	position: absolute;
	right: 3px;
	top: 1%;
	z-index: 50;
	width: 5px;
	height: 98%
}

.swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background: rgba(0, 0, 0, .5);
	border-radius: 10px;
	left: 0;
	top: 0
}

.swiper-scrollbar-cursor-drag {
	}

.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	-webkit-transform-origin: 50%;
	-moz-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
	-moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
	animation: swiper-preloader-spin 1s steps(12, end) infinite
}

.swiper-lazy-preloader:after {
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position: 50%;
	-webkit-background-size: 100%;
	background-size: 100%;
	background-repeat: no-repeat
}

.swiper-lazy-preloader-white:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")
}

@-webkit-keyframes swiper-preloader-spin {
	100% {
		-webkit-transform: rotate(360deg)
	}
}

@keyframes swiper-preloader-spin {
	100% {
		transform: rotate(360deg)
	}
}

/* ===================================
    Swiper carousel
====================================== */

.swiper-container {
	overflow: hidden;
	position: relative
}

.swiper-pagination-bullet-active {
	background: #232323
}

.swiper-pagination {
	width: 100%
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 35px;
	width: 100%
}

.swiper-pagination-bullet {
	height: 9px;
	width: 9px
}

.swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 5px
}

.swiper-pagination-bottom .swiper-wrapper {
	margin: 0 0 35px 0
}

.swiper-pagination-bottom.swiper-container-horizontal .swiper-wrapper {
	margin: 0 0 95px 0
}

.swiper-slider-second {
	text-align: center;
}

/* swiper bottom scrollbar full */
.swiper-bottom-scrollbar-full.swiper-container {
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: absolute;
	top: 0;
	transition: all 0.3s ease-in-out 0.7s;
	-webkit-transition: all 0.3s ease-in-out 0.7s;
	-moz-transition: all 0.3s ease-in-out 0.7s;
	-ms-transition: all 0.3s ease-in-out 0.7s;
	-o-transition: all 0.3s ease-in-out 0.7s;
	width: 100%;
}

.swiper-bottom-scrollbar-full .swiper-scrollbar {
	left: 0;
	opacity: 1;
	position: absolute;
	width: 100%;
	bottom: 0;
	border-radius: 0;
	height: 2px
}

.swiper-bottom-scrollbar-full .swiper-scrollbar-drag {
	border-radius: 0;
	background: $primary
}

.swiper-bottom-scrollbar-full .swiper-scrollbar {
	background: rgba(255, 255, 255, .2)
}

.swiper-bottom-scrollbar-full .swiper-slide img {
	max-height: 450px;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	opacity: .8;
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
	-webkit-filter: grayscale(1);
	-moz-filter: grayscale(1);
	-ms-filter: grayscale(1);
	-o-filter: grayscale(1);
}

.swiper-bottom-scrollbar-full .swiper-slide:hover img {
	opacity: 1;
	-webkit-filter: grayscale(0);
	-moz-filter: grayscale(0);
	-ms-filter: grayscale(0);
	-o-filter: grayscale(0);
	filter: grayscale(0);
	filter: contrast(130%);
	-webkit-filter: contrast(130%);
	-moz-filter: contrast(130%);
	-ms-filter: contrast(130%);
	-o-filter: contrast(130%);
	max-height: 500px;
}

.swiper-bottom-scrollbar-full .swiper-slide .hover-title-box {
	visibility: hidden;
}

.swiper-bottom-scrollbar-full .swiper-slide:hover .hover-title-box {
	right: -160px;
	opacity: 1;
	visibility: visible;
}

.swiper-bottom-scrollbar-full .bottom-text {
	position: absolute;
	bottom: 50px;
	opacity: 0;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}

.swiper-bottom-scrollbar-full .swiper-slide:hover .bottom-text {
	opacity: .7;
	letter-spacing: 5px;
	left: 4px;
}

/* swiper vertical pagination */
.swiper-vertical-pagination {
	width: 100%;
	height: 100%;
	position: absolute;
}

.swiper-vertical-pagination .swiper-slide {
	text-align: center;
	background: $white;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-vertical-pagination > .swiper-pagination-bullets {
	right: 60px;
}

.swiper-vertical-pagination .swiper-pagination {
	width: auto
}

.swiper-vertical-pagination .swiper-slide .swiper-bottom-content {
	opacity: 0;
	transform: translate3d(0px, 150px, 0px);
	-webkit-transform: translate3d(0px, 150px, 0px);
	-moz-transform: translate3d(0px, 150px, 0px);
	-ms-transform: translate3d(0px, 150px, 0px);
	-o-transform: translate3d(0px, 150px, 0px);
	transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
}

.swiper-vertical-pagination .swiper-slide-active .swiper-bottom-content {
	opacity: 1;
	transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-moz-transform: translate3d(0px, 0px, 0px);
	-ms-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate3d(0px, 0px, 0px);
	transition: all 0.3s ease-in-out 0.3s;
	-webkit-transition: all 0.3s ease-in-out 0.3s;
	-moz-transition: all 0.3s ease-in-out 0.3s;
	-ms-transition: all 0.3s ease-in-out 0.3s;
	-o-transition: all 0.3s ease-in-out 0.3s;
}

.swiper-vertical-pagination .box-arrow {
	position: absolute;
	right: -45px;
	width: 90px;
	height: 90px;
}

/* swiper auto width */
.swiper-auto-width .swiper-slide {
	width: 60%;
}

.swiper-auto-width.swiper-container {
	height: calc(100% - 200px);
	top: 100px;
	margin: 0 auto;
	overflow: visible;
	position: absolute;
	transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transition: all 0.3s ease-in-out 0.7s;
	-webkit-transition: all 0.3s ease-in-out 0.7s;
	-moz-transition: all 0.3s ease-in-out 0.7s;
	-ms-transition: all 0.3s ease-in-out 0.7s;
	-o-transition: all 0.3s ease-in-out 0.7s;
	width: 100%;
}

.swiper-auto-width .swiper-scrollbar {
	left: 20%;
	opacity: 1;
	position: absolute;
	width: 60%;
	bottom: -50px;
	border-radius: 0;
	height: 1px
}

.swiper-auto-width .swiper-scrollbar-drag {
	border-radius: 0;
	background: transparent;
}

.swiper-auto-width .swiper-scrollbar-drag:before {
	
	position: absolute;
	content: "";
	background: $primary;
	width: 13px;
	height: 13px;
	border-radius: 100%;
	top: -6px;
	left: calc(50% - 13px);
}

.swiper-auto-width .swiper-slide {
	filter: contrast(130%);
	-webkit-filter: contrast(130%);
	-moz-filter: contrast(130%);
	-ms-filter: contrast(130%);
	-o-filter: contrast(130%);
	filter: grayscale(1);
	-webkit-filter: grayscale(1);
	-moz-filter: grayscale(1);
	-ms-filter: grayscale(1);
	-o-filter: grayscale(1);
	transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
}

.swiper-auto-width .swiper-slide.swiper-slide-active {
	filter: contrast(0);
	-webkit-filter: contrast(0);
	-moz-filter: contrast(0);
	-ms-filter: contrast(0);
	-o-filter: contrast(0);
	filter: grayscale(0);
	-webkit-filter: grayscale(0);
	-moz-filter: grayscale(0);
	-ms-filter: grayscale(0);
	-o-filter: grayscale(0);
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}

.swiper-auto-width .swiper-slide .absolute-middle-center h2 {
	opacity: 0;
	margin-bottom: 10px;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transform: translateY(-60px);
	-webkit-transform: translateY(-60px);
	-moz-transform: translateY(-60px);
	-ms-transform: translateY(-60px);
	-o-transform: translateY(-60px);
}

.swiper-auto-width .swiper-slide.swiper-slide-active .absolute-middle-center h2 {
	opacity: 1;
	transition: all 0.3s ease-in-out 0.7s;
	-webkit-transition: all 0.3s ease-in-out 0.7s;
	-moz-transition: all 0.3s ease-in-out 0.7s;
	-ms-transition: all 0.3s ease-in-out 0.7s;
	-o-transition: all 0.3s ease-in-out 0.7s;
	transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
}

.swiper-auto-width .swiper-slide span {
	opacity: 0;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
}

.swiper-auto-width .swiper-slide.swiper-slide-active span {
	opacity: 1;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
}

/* arrow pagination black and white */
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
	right: 10px;
	left: auto;
}

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white-highlight, .swiper-container-rtl .swiper-button-next.swiper-button-white-highlight {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white-highlight, .swiper-container-rtl .swiper-button-prev.swiper-button-white-highlight {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white-highlight, .swiper-button-prev.swiper-button-white-highlight {
	background-color: $black;
	background-size: 28% auto;
	border-radius: 3px;
	height: 30px;
	width: 30px;
	margin: -15px 10px 0;
	top: 50%;
}

.swiper-button-next.swiper-button-black-highlight, .swiper-container-rtl .swiper-button-prev.swiper-button-black-highlight {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-black-highlight, .swiper-container-rtl .swiper-button-next.swiper-button-black-highlight {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-black-highlight, .swiper-button-prev.swiper-button-black-highlight {
	background-color: $white;
	background-size: 28% auto;
	border-radius: 3px;
	height: 30px;
	width: 30px;
	margin: -15px 10px 0;
	top: 50%;
}

.swiper-button-next, .swiper-button-prev {
	background-size: inherit;
	height: 22px;
	width: 50px;
	top: 51%
}

.swiper-fullscreen-next {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-fullscreen-prev {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-fullscreen-next, .swiper-fullscreen-prev {
	background-color: $white;
	background-size: 28% auto;
	border-radius: 3px;
	height: 30px;
	width: 30px;
	margin: 10px;
	top: 47.5%
}

/* blog swiper */
.swiper-blog .swiper-slide .slide-content {
	opacity: 0;
	transform: translateY(100px);
	-webkit-transform: translateY(100px);
	-moz-transform: translateY(100px);
	-ms-transform: translateY(100px);
	-o-transform: translateY(100px);
}

.swiper-blog .swiper-slide.swiper-slide-active .slide-content {
	opacity: 1;
	transition-delay: 0.3s;
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	-ms-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
}

/* long arrow */
.swiper-button-prev.slider-long-arrow-white, .swiper-container-rtl .swiper-button-next.slider-long-arrow-white {
	background-image: url('../images/arrow-prev-light-dark.png');
	left: 0
}

.swiper-button-next.slider-long-arrow-white, .swiper-container-rtl .swiper-button-prev.slider-long-arrow-white {
	background-image: url('../images/arrow-next-light-dark.png');
	right: 0
}

/* next prev button style 2 */
.swiper-next-style2, .swiper-prev-style2 {
	position: absolute;
	bottom: -60px;
	cursor: pointer;
	text-transform: uppercase
}

.swiper-next-style2 {
	right: 15%;
}

.swiper-prev-style2 {
	left: 15%;
}

/* portfolio next prev button */
.swiper-portfolio-prev, .swiper-portfolio-next {
	position: absolute;
	top: 50%;
	z-index: 1;
	background: $white;
	color: #232323;
	padding: 15px;
	font-size: 18px;
	cursor: pointer;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}

.swiper-portfolio-prev.swiper-button-disabled, .swiper-portfolio-next.swiper-button-disabled {
	opacity: 0;
	visibility: hidden;
}

.swiper-portfolio-prev {
	left: 15px;
}

.swiper-portfolio-next {
	right: 15px;
}

/* next prev button style 3 */
.swiper-button-prev.swiper-prev-style3, .swiper-button-next.swiper-next-style3 {
	background-image: none;
	position: absolute;
	cursor: pointer;
	left: 41.1%;
	top: inherit;
	text-align: center;
	font-size: 16px;
	line-height: 40px;
	width: 40px;
	height: 40px;
	background-color: $primary;
	color: $white;
	z-index: 9;
}

.swiper-button-prev.swiper-prev-style3 {
	bottom: 141px
}

.swiper-button-next.swiper-next-style3 {
	bottom: 100px;
}

/* next prev button style 4 */
.navigation-area {
	position: absolute;
	bottom: 0;
	display: inline-block;
	width: 40px;
	height: 82px;
}

.swiper-button-prev.swiper-prev-style4, .swiper-button-next.swiper-next-style4 {
	background-image: none;
	background: $primary;
	color: $white;
	text-align: center;
	line-height: 40px;
	height: 40px;
	width: 40px;
	display: block;
	float: left;
	position: relative;
	left: auto;
	right: auto;
	top: 0;
	margin: 0 auto;
	i {
		line-height: inherit;
	}
}

.swiper-button-next.swiper-next-style4 {
	margin-bottom: 1px
}

/* next prev button style 5 */
.swiper-button-prev.swiper-prev-style5, .swiper-button-next.swiper-next-style5 {
	position: absolute;
	top: 22px;
	bottom: 0;
	height: 100%;
	width: 15%;
	background-image: none;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

.swiper-button-prev.swiper-prev-style5 {
	opacity: 0;
	background: -moz-linear-gradient(left, rgba($black, 0.5), transparent);
	background: -webkit-linear-gradient(left, rgba($black, 0.5), transparent);
	background: linear-gradient(to right, rgba($black, 0.5), transparent);
}

.swiper-button-next.swiper-next-style5 {
	opacity: 0;
	background: -moz-linear-gradient(right, rgba($black, 0.5), transparent);
	background: -webkit-linear-gradient(right, rgba($black, 0.5), transparent);
	background: linear-gradient(to left, rgba($black, 0.5), transparent);
}

.swiper-button-prev.swiper-prev-style5:hover, .swiper-button-next.swiper-next-style5:hover {
	opacity: 1;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

.swiper-button-prev.swiper-prev-style5 {
	left: 0;
	cursor: url('../images/arrow-prev-light-dark.png'), pointer;
}

.swiper-button-next.swiper-next-style5 {
	right: 0;
	cursor: url('../images/arrow-next-light-dark.png'), pointer;
}

/* dot pagination white */
.swiper-pagination-white .swiper-pagination-bullet {
	background: $white
}

.swiper-pagination-white .swiper-pagination-bullet-active {
	background: $white
}

.swiper-pagination-square-bottom.swiper-pagination-bullets {
	bottom: -50px
}

/* square pagination */
.swiper-pagination-square .swiper-pagination-bullet {
	height: 3px;
	width: 30px;
	border-radius: 0
}

/* square pagination */
.white-move .swiper-slide {
	cursor: pointer;
}

.black-move .swiper-slide {
	cursor: pointer;
}

/* number pagination */
.swiper-number {
	left: auto !important;
	right: 0 !important;
	width: 35px !important;
	text-align: right !important;
	top: 38% !important;
	bottom: inherit !important;
}

.swiper-number .swiper-pagination-bullet {
	background: transparent;
	color: $gray-700;
	display: block;
	font-size: 20px;
	height: 45px;
	line-height: 20px;
	opacity: .7;
	padding: 14px 0 0;
	text-align: center;
	width: 23px;
	border-radius: 0;
	margin: 0 0 5px 0;
}

.swiper-number .swiper-pagination-bullet.swiper-pagination-bullet-active {
	border-bottom: 1px solid rgba($black, .5);
	opacity: 1;
}

.swiper-number .swiper-pagination-bullet-active {
	color: $black;
	background: transparent;
}

/* revolution slider bullet */
.tp-bullet {
	border-radius: 100%;
	background: transparent;
	border: 1px solid $white;
}

.tp-bullet.selected, .tp-bullet:hover {
	background: $white
}

.hermes .tp-bullet {
	box-shadow: 0 0 0 0 $white;
	height: 12px;
	width: 12px;
	border: 2px solid $white;
}

.hermes .tp-bullet::after {
	box-shadow: 0 0 0 0 $white inset;
}

/* slider height */
.slider-half-small-screen {
	min-height: 630px
}

.slider-half-screen {
	min-height: 765px
}

.slider-medium-screen {
	min-height: 898px
}

/* scroll down */
.scroll-down {
	bottom: 0px;
	position: absolute;
	text-align: center;
	width: 100%;
	z-index: 11
}

.scroll-down a {
	background: #232323;
	display: inline-block;
	height: 57px;
	width: 57px;
	text-align: center
}

.scroll-down a i.scroll-arrow-down {
	vertical-align: top;
	background-image: url('../images/scroll-down-icon.png');
	width: 17px;
	height: 30px;
	display: inline-block
}

.scroll-down a:hover {
	background: $white;
}

.scroll-to-down {
	cursor: url("../images/arrow-down.png"), pointer;
}

.scroll-to-down-white {
	cursor: url("../images/arrow-down-white.png"), pointer;
}

.background-slider-text {
	position: absolute;
	width: 100%
}

/* cursor */
.cursor-default {
	cursor: default
}

/* ===================================
    Slider and parallax typography
====================================== */

.slider-text-middle-main {
	display: table;
	height: 100%;
	width: 100%
}

.slider-text-middle {
	display: table-cell;
	vertical-align: middle;
}

.slider-typography {
	width: 100%;
	height: 100%;
	position: absolute;
	right: 0;
	left: 0;
}

.slider-text-bottom {
	display: table-cell;
	vertical-align: bottom;
}

.slider-text-middle {

}

.slider-text-top {
	display: table-cell;
	vertical-align: top;
}

.down-section {
	position: absolute;
	width: 100%;
	bottom: 50px;
	left: 0;
	right: 0
}

.blog-slider .swiper-slide .slider-typography {
	padding: 60px;
}

.swiper-slide {
	&.width-auto {
		width: auto;
	}
	
	&.width-550px {
		width: 550px;
	}
	
	&.width-50 {
		width: 50%;
	}
}


