// Offset All
@mixin offset-all($step, $max) {
	$step: $step;
	$loop-max: $max;
	@for $i from 1 through ($loop-max / $step) {
		$value: $step * $i;
		.offset-#{$value}px-all {
			margin: #{$value}px;
		}
	}
}

$media-break: (
	 xs: 480px,
	 sm: 768px,
	 md: 992px,
	 lg: 1200px
);

@mixin gel-offset($list) {
	@each $item in $list {
		.offset-#{$item} { margin: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.offset-#{$name}-#{$item} {
					margin: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.offset-top-#{$item} { margin-top: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.offset-#{$name}-top-#{$item} {
					margin-top: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.offset-bottom-#{$item} { margin-bottom: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.offset-#{$name}-bottom-#{$item} {
					margin-bottom: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.offset-preffix-#{$item} { margin-left: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.offset-#{$name}-preffix-#{$item} {
					margin-left: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.offset-postfix-#{$item} { margin-right: #{$item}px; }
	}
	
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.offset-#{$name}-postfix-#{$item} {
					margin-right: #{$item}px;
				}
			}
		}
	}
}

@mixin gel-inset($list) {
	@each $item in $list {
		.inset-#{$item} { padding: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.inset-#{$name}-#{$item} {
					padding: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.inset-top-#{$item} { padding-top: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.inset-#{$name}-top-#{$item} {
					padding-top: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.inset-bottom-#{$item} { padding-bottom: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.inset-#{$name}-bottom-#{$item} {
					padding-bottom: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.inset-preffix-#{$item} { padding-left: #{$item}px; }
	}
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.inset-#{$name}-preffix-#{$item} {
					padding-left: #{$item}px;
				}
			}
		}
	}
	
	@each $item in $list {
		.inset-postfix-#{$item} { padding-right: #{$item}px; }
	}
	
	
	@each $name, $value in $media-break {
		@media (min-width: $value) {
			@each $item in $list {
				.inset-#{$name}-postfix-#{$item} {
					padding-right: #{$item}px;
				}
			}
		}
	}
}