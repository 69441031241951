/* ===================================
    Border
====================================== */

.border-all { border: 1px solid $gray-500 }

/* boder width */
.border-width-1 { border-width: 1px; }

.border-width-2 { border-width: 2px; }

.border-width-3 { border-width: 3px; }

.border-width-4 { border-width: 4px; }

.border-width-5 { border-width: 5px; }

.border-width-6 { border-width: 6px; }

.border-width-7 { border-width: 7px; }

.border-width-8 { border-width: 8px; }

.border-width-9 { border-width: 9px; }

.border-width-10 { border-width: 10px; }

.border-width-20 { border-width: 20px; }

@include border-width(20);

/* boder align */
.border-top { border-top: 1px solid; }

.border-bottom { border-bottom: 1px solid; }

.border-left { border-left: 1px solid; }

.border-right { border-right: 1px solid; }

.border-lr { border-left: 1px solid; border-right: 1px solid; }

.border-tb { border-top: 1px solid; border-bottom: 1px solid; }

/* border color */
.border-color-white { border-color: $white }

.border-color-black { border-color: $black }

.border-color-extra-dark-gray { border-color: #232323 }

.border-color-medium-dark-gray { border-color: #363636 }

.border-color-dark-gray { border-color: $gray-700 }

.border-color-extra-medium-gray { border-color: $gray-200 }

.border-color-medium-gray { border-color: #e4e4e4 }

.border-color-extra-light-gray { border-color: $gray-500 }

.border-color-light-gray { border-color: #f5f5f5 }

.border-color-light-pink { border-color: #862237 }

.border-color-secondary { border-color: $primary }

.border-color-transparent-pink { border-color: rgba($primary, 0.45); }

/* boder style */
.border-dotted { border-style: dotted !important; }

.border-dashed { border-style: dashed !important; }

.border-solid { border-style: solid !important; }

.border-double { border-style: double !important; }

.border-groove { border-style: groove !important; }

.border-ridge { border-style: ridge !important; }

.border-inset { border-style: inset !important; }

.border-outset { border-style: outset !important; }

.border-none { border-style: none !important; }

.border-hidden { border-style: hidden !important; }

.border-transperent { border-color: transparent !important; }

.no-border-top { border-top: 0 !important }

.no-border-bottom { border-bottom: 0 !important }

.no-border-right { border-right: 0 !important }

.no-border-left { border-left: 0 !important }

/* border for overline icon box */
.overline-icon-box { border-top-width: 4px; }

/* border color for overline icon box */
.overline-icon-box.overline-white { border-top-color: $white; }

.overline-icon-box.overline-black { border-top-color: $black; }

.overline-icon-box.overline-extra-dark-gray { border-top-color: #232323; }

.overline-icon-box.overline-medium-dark-gray { border-top-color: #363636; }

.overline-icon-box.overline-dark-gray { border-top-color: $gray-700; }

.overline-icon-box.overline-extra-light-gray { border-top-color: $gray-200; }

.overline-icon-box.overline-medium-gray { border-top-color: #e4e4e4; }

.overline-icon-box.overline-light-gray { border-top-color: #f5f5f5; }

.overline-icon-box.overline-light-pink { border-top-color: #862237; }

.overline-icon-box.overline-secondary { border-top-color: $primary; }

.overline-icon-box.overline-transparent-pink { border-top-color: rgba($primary, 0.45); }

/* transparent border */
.border-black-light { border-color: rgba($black, .1) !important; }

.border-white-light { border-color: rgba(255, 255, 255, .1) !important; }

/* border radius */
.border-radius-1 { border-radius: 1px }

.border-radius-2 { border-radius: 2px }

.border-radius-3 { border-radius: 3px }

.border-radius-4 { border-radius: 4px }

.border-radius-5 { border-radius: 5px }

.border-radius-6 { border-radius: 6px }

.border-radius-7 { border-radius: 7px }

.border-radius-8 { border-radius: 8px }

.border-radius-9 { border-radius: 9px }

.border-radius-10 { border-radius: 10px }

.border-radius-50 { border-radius: 50% }

.border-radius-100 { border-radius: 100% }

.border-radius-none { border-radius: 0 }