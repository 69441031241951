//
// Color system
//

// Gray colors
$white:     #ffffff; //
$gray-100:  #f5f5f5; //
$gray-200:  #dbdbdb; //
$gray-300:  #fafafa; //
$gray-400:  #b7b7b7;
$gray-500:  #ededed; //
$gray-600:  #e0e0e0; //
$gray-700:  #939393; //
$gray-800:  #757575; //
$gray-900:  #191919; //
$black:     #000000; //

$gray-custom-1: $gray-300; //



//
// Body
//
$body-bg:       $white;
$body-color:    $gray-500;

$primary:       #158BCF;
$secondary:     #00ABDB;

// Accent colors
$danger-color:    #f5543f;
$success-color:   #98bf44;

$gradient: linear-gradient( 135deg, $primary, $secondary);


// Fonts
$font-base: 		'Open Sans', sans-serif;
$font-sec: 			'Raleway', sans-serif;

$border-radius:          		0;

// Z-index master list
$zindex-rd-navbar:          1080;

// Map
//
$map-height: 200px;
$map-xs-height: 250px;
$map-md-height: 450px;
$map-lg-height: 650px;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// Extra small screen / phone
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

// Small screen / tablet
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

// Extra Large screen / 4k desktop
$screen-xl:                  1800px;
$screen-xl-min:              $screen-xl;
$screen-xl-desktop:          $screen-xl-min;



// Custom
$shadow-area-1:   -3px 0px 50px -2px rgba(0, 0, 0, 0.09);
$shadow-area-2:   1px 1px 10px 0 rgba(0, 0, 0, 0.2);

// Icon Fonts
$fa:  'FontAwesome';
$mdi: 'Material Design Icons';
$mi:  'Material Icons';

//
// Forms
//
$form-input-height:             50px;
$form-input-lg-height:          60px;
$form-input-sm-height:          40px;

$form-input-color:              $gray-900;
$form-input-color-placeholder:  $form-input-color;

$form-input-background:         #f5f5f5;
$form-input-border-color:       $form-input-background;
$form-input-border-radius:      $border-radius;

$form-input-font-size:          14px;
$form-input-line-height:        24px;
$form-input-font-weight:        400;

$form-input-border:             1px solid $form-input-border-color;
$form-input-padding-horizontal: 19px;
$form-input-line-height:        round($form-input-font-size * 1.7);

$form-input-padding-vertical:   round(($form-input-height - $form-input-line-height) / 2) + 1px;
$form-input-padding:            $form-input-padding-vertical $form-input-padding-horizontal;

@if ($form-input-border != none) {
	$form-input-padding-vertical: round(($form-input-height - $form-input-line-height - (nth($form-input-border, 1) * 2)) / 2);
	$form-input-padding: $form-input-padding-vertical $form-input-padding-horizontal;
}

$form-textarea-default-height:       135px;
$form-textarea-default-min-height:   $form-input-height;
$form-textarea-default-max-height:   round($form-textarea-default-height * 1.7);


$form-feedback-focus-color:   $primary;
$form-feedback-valid-color:   $success-color;
$form-feedback-invalid-color: $danger-color;