/*
*
* Material Parallax
*/

.parallax-container {
	position: relative;
	overflow: hidden;
	&.visible {
		overflow: visible;
	}
	
	&.prlx-overlay-1 {
		@media (max-width: 991px) {
			position: relative;
			z-index: 1;
			&:before {
			  content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 1;
				background-color: rgba($white, .75);
			}
		}
	}
}

.material-parallax {
	position: absolute;
	top: 0;
	left: -1px;
	right: -1px;
	bottom: 0;
}

.ipad, .iphone {
	.parallax-container {
		background-attachment: scroll !important;
	}
	.parallax-container.custom-prlx-1 {
		background-position: right top;
	}
}

.material-parallax img {
	display: none;
	position: absolute;
	left: 50%;
	bottom: 0;
	min-width: 101%;
	min-height: 101%;
	max-width: none;
	transform: translate3d(-50%, 0, 0);
	transition: 0s ease-out !important;
}

.parallax-content {
	position: relative;
	z-index: 1;
}

.bg-black,
.bg-extra-dark-gray {
	&.bg-fixed,
	&.parallax-container {
		&:before {
			content: '';
			z-index: 1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba($gray-900, .55);
		}
	}
	
	&.bg-fixed {
		position: relative;
		z-index: 10;
		&:before {
			z-index: -1;
		}
	}
}

.bg-overlay-05 {
	&.bg-fixed,
	&.parallax-container {
		&:before {
			background-color: rgba($gray-900, .5);
		}
	}
	
	&.bg-fixed {
		position: relative;
		z-index: 10;
		&:before {
			z-index: -1;
		}
	}
}

.bg-medium-gray {
	&.bg-fixed,
	&.parallax-container {
		&:before {
			content: '';
			z-index: 1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba($white, .7);
		}
	}
	
	&.bg-fixed {
		position: relative;
		z-index: 10;
		&:before {
			z-index: -1;
		}
	}
}

.parallax-content-middle {
	.parallax-content {
		position: static;
	}
}

.desktop {
	.bg-fixed {
		-webkit-background-size: cover;
		background-size: cover;
		background-attachment: fixed;
	}
}
