/* ===================================
   Others
====================================== */

/* services modern */
.arrow-bottom {
  position: relative;
}

.arrow-bottom:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(245, 245, 245, 0);
  border-top-color: #f5f5f5;
  border-width: 15px;
  margin-left: -15px;
  z-index: 9;
}

.arrow-top {
  position: relative;
}

.arrow-top:after {
  top: -29px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(245, 245, 245, 0);
  border-bottom-color: #f5f5f5;
  border-width: 15px;
  margin-left: -15px;
  z-index: 9;
}

/* our clients */
.clients-list {
  height: 190px
}

.clients-list:hover {
  box-shadow: 0 0 4px rgba($black, .1);
}

.clients-list:hover img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

/* sidebar nav style 2 */
.tparrows:before {
  font-family: 'FontAwesome';
}

.tparrows.tp-leftarrow:before {
  content: '\f060';
}

.tparrows.tp-rightarrow:before {
  content: '\f061';
}

/* rev slider */
.rev_slider_wrapper {
  height: 980px;
}

/* animation */
@-webkit-keyframes up-down-animation {
  0% {
    opacity: 1;
    top: 0px;
  }
  25% {
    opacity: .4;
    top: 2px;
  }
  50% {
    opacity: .3;
    top: 4px;
  }
  75% {
    opacity: .2;
    top: 5px;
  }
  100% {
    opacity: 0;
    top: 9px;
  }
}

@keyframes up-down-animation {
  0% {
    opacity: 1;
    top: 0px;
  }
  25% {
    opacity: .4;
    top: 4px;
  }
  50% {
    opacity: .3;
    top: 8px;
  }
  75% {
    opacity: .2;
    top: 12px;
  }
  100% {
    opacity: 0;
    top: 16px;
  }
}

/* ===================================
   demo page css
====================================== */

.demo-heading {
  font-size: 40px;
  line-height: 46px;
}

.buy-theme {
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
  position: fixed;
  top: 130px;
  right: -70px;
  background: $white;
  z-index: 1000;
  -moz-box-shadow: 0 0 10px rgba($black, 0.2);
  -webkit-box-shadow: 0 0 10px rgba($black, 0.2);
  box-shadow: 0 0 10px rgba($black, 0.2);
  z-index: 10
}

.buy-theme i {
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  color: #6f6f6f
}

.all-demo i {
  font-size: 15px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  color: #6f6f6f
}

.buy-theme:hover, .all-demo:hover {
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
  right: 0px;
  background: $primary
}

.buy-theme span, .all-demo span {
  padding: 0 9px;
  position: relative;
  top: 0;
  opacity: 0
}

.buy-theme:hover span, .all-demo:hover span {
  opacity: 1;
  color: $white
}

.buy-theme:hover i, .all-demo:hover i {
  color: $white
}

.buy-theme a, .all-demo a {
  color: #232323;
  font-size: 10px;
  text-transform: uppercase;
  padding: 2px 10px;
  display: block;
  text-decoration: none;
  font-weight: 500
}

.all-demo {
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
  position: fixed;
  top: 172px;
  right: -102px;
  background: $white;
  z-index: 1000;
  -moz-box-shadow: 0 0 10px rgba($black, 0.2);
  -webkit-box-shadow: 0 0 10px rgba($black, 0.2);
  box-shadow: 0 0 10px rgba($black, 0.2);
  z-index: 10
}