//
// Main styles
//

@import "reset";

//
// Components
//

@import "components/type";
@import "components/custom";
@import "components/button";
@import "components/aside-style";
@import "components/icons";
@import "components/header";
@import "components/elements";
@import "components/blog";
@import "components/footer";
@import "components/home-page";
@import "components/other";
@import "components/forms";
@import "components/select2";
@import "components/snackbars";
@import "components/thumb";


//
// Helpers
//
@import "helpers/offsets";
@import "helpers/utilities";
@import "helpers/background-color";
@import "helpers/background-image";
@import "helpers/border";
@import "helpers/page-layout";
@import "helpers/grid-custom";



//
// Plugins
//
@import "plugins/rd-navbar";
@import "plugins/bootsnav";
@import "plugins/animate";
@import "plugins/video";
@import "plugins/magnific-popup";
@import "plugins/swiper";
@import "plugins/swiper_custom";
@import "plugins/gallery";
@import "plugins/ui-to-top";
@import "plugins/rd-google-map";
@import "plugins/material-parallax";
@import "plugins/lightgallery";
@import "plugins/preloader";
@import "plugins/revolution-slider";


//
// Responsive
//
@import "responsive/responsive";
