@charset "utf-8";
/* ----------------------------------

Name: style.css
Version: 1.0

-------------------------------------

Table of contents
    
    01. Reset
    
    Components
      1.00 Type
      1.01 Custom
      1.02 Button
      1.03 Aside style
      1.04 Icons
      1.05 Header
      1.06 Elements
      1.07 Blog
      1.08 Footer
      1.09 Home-page
      1.10 Other
    
    Helpers
      2.00 Offsets
      2.01 Utilities
      2.02 Background color
      2.03 Background image
      2.04 Border
      2.05 Page layout
     
    Plugins
      3.00 Revolution Slider
      3.01 Bootsnav
      3.02 Animate
      3.03 Video
      3.04 Magnific popup
      3.05 Swiper
      3.06 Gallery
      
    Responsive
      4.00 Responsive

---------------------------------- */
@import "custom-style/mixins";
@import "custom-style/variables";
@import "custom-style/main-style";

body {
	overflow-x: hidden;
}

* {
	transition: .3s ease-out all;
}

a {
	* {
		transition: 0s ease-out all;
	}
	img {
		transition: .3s ease-out all;
	}
}

.icon-play {
	img {
		transition: .3s ease-out all;
	}
}

.page-revolution {
	transition: 0s  all;
	* {
		transition: 0s  all;
	}
	a {
		transition: .3s ease-out all;
	}
}


.form-serach-404 {
	background-color: $primary;
	input#text {
		padding-right: 5px;
	}
}

html {
	overflow: visible !important;
	margin-right: 0 !important;
}

[data-x-mode='true']  {
	[class*='col-'].display-table.cover-background {
		display: block !important;
	}
	
	.full-width-pull-menu {
		.height-100 {
			height: 100% !important;
		}
	}
	
	.blog-box-image.height-100 {
		height: 100% !important;
	}
	
	.cstm-gl-height.height-100 {
		height: 100% !important;
		*.height-100 {
			height: 100% !important;
		}
	}
}


.menu-logo-center {
	.logo {
		position: relative;
		top: -6px;
	}
}
