/* ===================================
    Background image
====================================== */

.parallax {
	position: relative;
	background-size: cover;
	overflow: hidden;
	background-attachment: fixed;
	&.custom-prlx {
		overflow: visible;
	}
}


.fix-background {
	position: relative;
	background-size: cover;
	animation-duration: 0s;
	animation-fill-mode: none;
	-webkit-animation-duration: 0s;
	-webkit-animation-fill-mode: none;
	-moz-animation-duration: 0s;
	-moz-animation-fill-mode: none;
	-ms-animation-duration: 0s;
	-ms-animation-fill-mode: none;
	-o-animation-fill-mode: none;
	overflow: hidden;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed
}

.cover-background {
	position: relative !important;
	background-size: cover !important;
	overflow: hidden !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}

.background-size-inherit {
	background-size: inherit
}

.background-attachment-inherit {
	background-attachment: inherit
}

.background-position-left {
	background-position: left center;
}

.background-position-left-bottom {
	background-position: left bottom;
}

.background-position-right {
	background-position: right center;
}

.background-position-top {
	background-position: center top !important;
}

.up-down-ani {
	display: table;
	position: relative;
	-webkit-animation-name: up-down-animation;
	-webkit-animation-duration: .7s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-delay: .7s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	-moz-animation-name: up-down-animation;
	-moz-animation-duration: .7s;
	-moz-animation-timing-function: linear;
	-moz-animation-delay: .7s;
	-moz-animation-iteration-count: infinite;
	-ms-animation-name: up-down-animation;
	-ms-animation-duration: .7s;
	-ms-animation-timing-function: linear;
	-ms-animation-delay: .7s;
	-ms-animation-iteration-count: infinite;
	-ms-animation-direction: alternate;
	-o-animation-direction: alternate;
	-o-animation-name: up-down-animation;
	-o-animation-duration: .7s;
	-o-animation-timing-function: linear;
	-o-animation-delay: .7s;
	-o-animation-iteration-count: infinite;
	-o-animation-direction: alternate;
	animation-direction: alternate;
	animation-name: up-down-animation;
	animation-duration: .7s;
	animation-timing-function: linear;
	animation-delay: .7s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	margin: 0 auto;
}

.background-position-x-50 {
	background-position-x: 50% !important;
}

.parallax {
	&.bg-black {
		position: relative;
		z-index: 0;
		&:before {
		  content: '';
			z-index: -1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba($black, .8);
		}
	}
}