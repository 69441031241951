/* ===================================
    Icon
====================================== */

/* icon size */
.icon-very-small { font-size: 16px }
.icon-small { font-size: 24px }
.icon-medium { font-size: 35px }
.icon-extra-medium { font-size: 40px }
.icon-large { font-size: 50px }
.icon-round-small { border-radius: 100%; display: table-cell; height: 80px; margin: 0 auto; text-align: center; vertical-align: middle; width: 80px; font-size: 32px }
.icon-round-medium { border-radius: 100%; display: table-cell; height: 90px; margin: 0 auto; text-align: center; vertical-align: middle; width: 90px; }
/* social media icon */
.icon-social-very-small { font-size: 12px }
.icon-social-small { font-size: 14px }
.icon-social-medium { font-size: 16px }
.icon-social-large { font-size: 20px }
.icon-social-extra-large { font-size: 24px }
.icon-social-very-small a i, .icon-social-small a i, .icon-social-medium a i { margin: 0 8px; vertical-align: middle; }
.icon-social-large a i { margin: 0 4px; vertical-align: middle; }
/* social media icon style */
.extra-small-icon, .small-icon, .medium-icon, .large-icon, .extra-large-icon { list-style: none; padding: 0; }
.extra-small-icon li, .small-icon li, .medium-icon li, .large-icon li, .extra-large-icon li { display: inline-block; }
footer .extra-small-icon li:first-child, footer .small-icon li:first-child, footer .medium-icon li:first-child, footer .large-icon li:first-child, footer .extra-large-icon li:first-child { margin-left: 0; }
footer .extra-small-icon li:last-child, footer .small-icon li:last-child, footer .medium-icon li:last-child, footer .large-icon li:last-child, footer .extra-large-icon li:last-child { margin-right: 0; }
.extra-small-icon li { margin: 0 2px }
.small-icon li { margin: 0 5px }
.medium-icon li { margin: 0 8px }
.large-icon li { margin: 0 10px }
.extra-large-icon li { margin: 0 12px }
.extra-small-icon a { font-size: 14px; width: 30px; height: 30px; line-height: 29px; }
.small-icon a { font-size: 16px; width: 40px; height: 40px; line-height: 38px; }
.medium-icon a { font-size: 20px; width: 50px; height: 50px; line-height: 48px; }
.large-icon a { font-size: 24px; width: 60px; height: 60px; line-height: 58px; }
.extra-large-icon a { font-size: 30px; width: 70px; height: 70px; line-height: 68px; }
/* social icon style */
.social-icon-style-1 a.facebook, .social-icon-style-2 a.facebook, .social-icon-style-3 a.facebook, .social-icon-style-4 a.facebook span, .social-icon-style-10 a.facebook span { background-color: #3b5998 }
.social-icon-style-1 a.twitter, .social-icon-style-2 a.twitter, .social-icon-style-3 a.twitter, .social-icon-style-4 a.twitter span, .social-icon-style-10 a.twitter span { background-color: #00aced }
.social-icon-style-1 a.google, .social-icon-style-2 a.google, .social-icon-style-3 a.google, .social-icon-style-4 a.google span, .social-icon-style-10 a.google span { background-color: #dc4a38 }
.social-icon-style-1 a.dribbble, .social-icon-style-2 a.dribbble, .social-icon-style-3 a.dribbble, .social-icon-style-4 a.dribbble span, .social-icon-style-10 a.dribbble span { background-color: #ea4c89 }
.social-icon-style-1 a.youtube, .social-icon-style-2 a.youtube, .social-icon-style-3 a.youtube, .social-icon-style-4 a.youtube span, .social-icon-style-10 a.youtube span { background-color: #bb0000 }
.social-icon-style-1 a.linkedin, .social-icon-style-2 a.linkedin, .social-icon-style-3 a.linkedin, .social-icon-style-4 a.linkedin span, .social-icon-style-10 a.linkedin span { background-color: #007bb6 }
.social-icon-style-1 a.instagram, .social-icon-style-2 a.instagram, .social-icon-style-3 a.instagram, .social-icon-style-4 a.instagram span, .social-icon-style-10 a.instagram span { background-color: #fe1f49 }
.social-icon-style-1 a.pinterest, .social-icon-style-2 a.pinterest, .social-icon-style-3 a.pinterest, .social-icon-style-4 a.pinterest span, .social-icon-style-10 a.pinterest span { background-color: #cb2027 }
.social-icon-style-1 a.flickr, .social-icon-style-2 a.flickr, .social-icon-style-3 a.flickr, .social-icon-style-4 a.flickr span, .social-icon-style-10 a.flickr span { background-color: #ff0084 }
.social-icon-style-1 a.rss, .social-icon-style-2 a.rss, .social-icon-style-3 a.rss, .social-icon-style-4 a.rss span, .social-icon-style-10 a.rss span { background-color: #f2890a }
.social-icon-style-1 a.behance, .social-icon-style-2 a.behance, .social-icon-style-3 a.behance, .social-icon-style-4 a.behance span, .social-icon-style-10 a.behance span { background-color: #1769ff }
.social-icon-style-1 a.vine, .social-icon-style-2 a.vine, .social-icon-style-3 a.vine, .social-icon-style-4 a.vine span, .social-icon-style-10 a.vine span { background-color: #00bf8f }
.social-icon-style-1 a.email, .social-icon-style-2 a.email, .social-icon-style-3 a.email, .social-icon-style-4 a.email span, .social-icon-style-10 a.email span { background-color: #7d7d7d }
.social-icon-style-1 a.sms, .social-icon-style-2 a.sms, .social-icon-style-3 a.sms, .social-icon-style-4 a.sms span, .social-icon-style-10 a.sms span { background-color: #ffbd00 }
.social-icon-style-1 a.sharethis, .social-icon-style-2 a.sharethis, .social-icon-style-3 a.sharethis, .social-icon-style-4 a.sharethis span, .social-icon-style-10 a.sharethis span { background-color: #95d03a }
.social-icon-style-1 a.reddit, .social-icon-style-2 a.reddit, .social-icon-style-3 a.reddit, .social-icon-style-4 a.reddit span, .social-icon-style-10 a.reddit span { background-color: #ff4500 }
.social-icon-style-1 a.tumblr, .social-icon-style-2 a.tumblr, .social-icon-style-3 a.tumblr, .social-icon-style-4 a.tumblr span, .social-icon-style-10 a.tumblr span { background-color: #32506d }
.social-icon-style-1 a.digg, .social-icon-style-2 a.digg, .social-icon-style-3 a.digg, .social-icon-style-4 a.digg span, .social-icon-style-10 a.digg span { background-color: #262626 }
.social-icon-style-1 a.stumbleupon, .social-icon-style-2 a.stumbleupon, .social-icon-style-3 a.stumbleupon, .social-icon-style-4 a.stumbleupon span, .social-icon-style-10 a.stumbleupon span { background-color: #eb4924 }
.social-icon-style-1 a.whatsapp, .social-icon-style-2 a.whatsapp, .social-icon-style-3 a.whatsapp, .social-icon-style-4 a.whatsapp span, .social-icon-style-10 a.whatsapp span { background-color: #25d366 }
.social-icon-style-1 a.vk, .social-icon-style-2 a.vk, .social-icon-style-3 a.vk, .social-icon-style-4 a.vk span, .social-icon-style-10 a.vk span { background-color: #4c6c91 }
.social-icon-style-1 a.weibo, .social-icon-style-2 a.weibo, .social-icon-style-3 a.weibo, .social-icon-style-4 a.weibo span, .social-icon-style-10 a.weibo span { background-color: #ff9933 }
.social-icon-style-1 a.odnoklassniki, .social-icon-style-2 a.odnoklassniki, .social-icon-style-3 a.odnoklassniki, .social-icon-style-4 a.odnoklassniki span, .social-icon-style-10 a.odnoklassniki span { background-color: #d7772d }
.social-icon-style-1 a.xing, .social-icon-style-2 a.xing, .social-icon-style-3 a.xing, .social-icon-style-4 a.xing span, .social-icon-style-10 a.xing span { background-color: #1a7576 }
.social-icon-style-1 a.print, .social-icon-style-2 a.print, .social-icon-style-3 a.print, .social-icon-style-4 a.print span, .social-icon-style-10 a.print span { background-color: #222222 }
.social-icon-style-1 a.blogger, .social-icon-style-2 a.blogger, .social-icon-style-3 a.blogger, .social-icon-style-4 a.blogger span, .social-icon-style-10 a.blogger span { background-color: #ff8000 }
.social-icon-style-1 a.flipboard, .social-icon-style-2 a.flipboard, .social-icon-style-3 a.flipboard, .social-icon-style-4 a.flipboard span, .social-icon-style-10 a.flipboard span { background-color: #e12828 }
.social-icon-style-1 a.meneame, .social-icon-style-2 a.meneame, .social-icon-style-3 a.meneame, .social-icon-style-4 a.meneame span, .social-icon-style-10 a.meneame span { background-color: #ff6400 }
.social-icon-style-1 a.mailru, .social-icon-style-2 a.mailru, .social-icon-style-3 a.mailru, .social-icon-style-4 a.mailru span, .social-icon-style-10 a.mailru span { background-color: #168de2 }
.social-icon-style-1 a.delicious, .social-icon-style-2 a.delicious, .social-icon-style-3 a.delicious, .social-icon-style-4 a.delicious span, .social-icon-style-10 a.delicious span { background-color: #205cc0 }
.social-icon-style-1 a.livejournal, .social-icon-style-2 a.livejournal, .social-icon-style-3 a.livejournal, .social-icon-style-4 a.livejournal span, .social-icon-style-10 a.livejournal span { background-color: #00b0ea }
/* social icon style */
.social-icon-style-6 a.facebook:hover { border-color: #3b5998; color: #3b5998 }
.social-icon-style-6 a.twitter:hover { border-color: #00aced; color: #00aced }
.social-icon-style-6 a.google:hover { border-color: #dc4a38; color: #dc4a38 }
.social-icon-style-6 a.dribbble:hover { border-color: #ea4c89; color: #ea4c89 }
.social-icon-style-6 a.youtube:hover { border-color: #bb0000; color: #bb0000 }
.social-icon-style-6 a.linkedin:hover { border-color: #0077b5; color: #0077b5 }
.social-icon-style-6 a.instagram:hover { border-color: #fe1f49; color: #fe1f49 }
.social-icon-style-6 a.pinterest:hover { border-color: #cb2027; color: #cb2027 }
.social-icon-style-6 a.flickr:hover { border-color: #ff0084; color: #ff0084 }
.social-icon-style-6 a.rss:hover { border-color: #f2890a; color: #f2890a }
.social-icon-style-6 a.behance:hover { border-color: #1769ff; color: #1769ff }
.social-icon-style-6 a.vine:hover { border-color: #00bf8f; color: #00bf8f }
.social-icon-style-6 a.email:hover { border-color: #7d7d7d; color: #7d7d7d }
.social-icon-style-6 a.sms:hover { border-color: #ffbd00; color: #ffbd00 }
.social-icon-style-6 a.sharethis:hover { border-color: #95d03a; color: #95d03a }
.social-icon-style-6 a.reddit:hover { border-color: #ff4500; color: #ff4500 }
.social-icon-style-6 a.tumblr:hover { border-color: #32506d; color: #32506d }
.social-icon-style-6 a.digg:hover { border-color: #262626; color: #262626 }
.social-icon-style-6 a.stumbleupon:hover { border-color: #eb4924; color: #eb4924 }
.social-icon-style-6 a.whatsapp:hover { border-color: #25d366; color: #25d366 }
.social-icon-style-6 a.vk:hover { border-color: #4c6c91; color: #4c6c91 }
.social-icon-style-6 a.weibo:hover { border-color: #ff9933; color: #ff9933 }
.social-icon-style-6 a.odnoklassniki:hover { border-color: #d7772d; color: #d7772d }
.social-icon-style-6 a.xing:hover { border-color: #1a7576; color: #1a7576 }
.social-icon-style-6 a.print:hover { border-color: #222222; color: #222222 }
.social-icon-style-6 a.blogger:hover { border-color: #ff8000; color: #ff8000 }
.social-icon-style-6 a.flipboard:hover { border-color: #e12828; color: #e12828 }
.social-icon-style-6 a.meneame:hover { border-color: #ff6400; color: #ff6400 }
.social-icon-style-6 a.mailru:hover { border-color: #168de2; color: #168de2 }
.social-icon-style-6 a.delicious:hover { border-color: #205cc0; color: #205cc0 }
.social-icon-style-6 a.livejournal:hover { border-color: #00b0ea; color: #00b0ea }
/* social icon style */
.social-icon-style-7 a.facebook:hover { border-color: #3b5998; background-color: #3b5998 }
.social-icon-style-7 a.twitter:hover { border-color: #00aced; background-color: #00aced }
.social-icon-style-7 a.google:hover { border-color: #dc4a38; background-color: #dc4a38 }
.social-icon-style-7 a.dribbble:hover { border-color: #ea4c89; background-color: #ea4c89 }
.social-icon-style-7 a.youtube:hover { border-color: #bb0000; background-color: #bb0000 }
.social-icon-style-7 a.linkedin:hover { border-color: #0077b5; background-color: #0077b5 }
.social-icon-style-7 a.instagram:hover { border-color: #fe1f49; background-color: #fe1f49 }
.social-icon-style-7 a.pinterest:hover { border-color: #cb2027; background-color: #cb2027 }
.social-icon-style-7 a.flickr:hover { border-color: #ff0084; background-color: #ff0084 }
.social-icon-style-7 a.rss:hover { border-color: #f2890a; background-color: #f2890a }
.social-icon-style-7 a.behance:hover { border-color: #1769ff; background-color: #1769ff }
.social-icon-style-7 a.vine:hover { border-color: #00bf8f; background-color: #00bf8f }
.social-icon-style-7 a.email:hover { border-color: #7d7d7d; background-color: #7d7d7d }
.social-icon-style-7 a.sms:hover { border-color: #ffbd00; background-color: #ffbd00 }
.social-icon-style-7 a.sharethis:hover { border-color: #95d03a; background-color: #95d03a }
.social-icon-style-7 a.reddit:hover { border-color: #ff4500; background-color: #ff4500 }
.social-icon-style-7 a.tumblr:hover { border-color: #32506d; background-color: #32506d }
.social-icon-style-7 a.digg:hover { border-color: #262626; background-color: #262626 }
.social-icon-style-7 a.stumbleupon:hover { border-color: #eb4924; background-color: #eb4924 }
.social-icon-style-7 a.whatsapp:hover { border-color: #25d366; background-color: #25d366 }
.social-icon-style-7 a.vk:hover { border-color: #4c6c91; background-color: #4c6c91 }
.social-icon-style-7 a.weibo:hover { border-color: #ff9933; background-color: #ff9933 }
.social-icon-style-7 a.odnoklassniki:hover { border-color: #d7772d; background-color: #d7772d }
.social-icon-style-7 a.xing:hover { border-color: #1a7576; background-color: #1a7576 }
.social-icon-style-7 a.print:hover { border-color: #222222; background-color: #222222 }
.social-icon-style-7 a.blogger:hover { border-color: #ff8000; background-color: #ff8000 }
.social-icon-style-7 a.flipboard:hover { border-color: #e12828; background-color: #e12828 }
.social-icon-style-7 a.meneame:hover { border-color: #ff6400; background-color: #ff6400 }
.social-icon-style-7 a.mailru:hover { border-color: #168de2; background-color: #168de2 }
.social-icon-style-7 a.delicious:hover { border-color: #205cc0; background-color: #205cc0 }
.social-icon-style-7 a.livejournal:hover { border-color: #00b0ea; background-color: #00b0ea }
/* icon like counter */
a.likes-count { padding: 0 15px; width: auto; border-radius: 30px !important; }
a.likes-count i { margin-right: 8px; }
a.likes-count span { position: relative; top: -1px }
/* icon hover effect style 1 */
.social-icon-style-1 a { text-align: center; display: inline-block; transition: all 400ms; -webkit-transition: all 400ms; -moz-transition: all 400ms; -ms-transition: all 400ms; -o-transition: all 400ms; color: $white }
.social-icon-style-1 a:hover { border-radius: 100%; }
/* icon hover effect style 2 */
.social-icon-style-2 a { text-align: center; display: inline-block; transition: all 400ms; -webkit-transition: all 400ms; -moz-transition: all 400ms; -ms-transition: all 400ms; -o-transition: all 400ms; border-radius: 100%; color: $white }
.social-icon-style-2 a:hover { border-radius: 0; }
/* icon hover effect style 3 */
.social-icon-style-3 a { text-align: center; display: inline-block; transition: all 400ms; -webkit-transition: all 400ms; -moz-transition: all 400ms; -ms-transition: all 400ms; -o-transition: all 400ms; border-radius: 100%; color: $white }
.social-icon-style-3 a:hover i { transition: transform 0.4s ease-out; -webkit-transition: transform 0.4s ease-out; -moz-transition: transform 0.4s ease-out; -ms-transition: transform 0.4s ease-out; -o-transition: transform 0.4s ease-out; transform: rotateZ(360deg); -webkit-transform: rotateZ(360deg); -moz-transform: rotateZ(360deg); -ms-transform: rotateZ(360deg); -o-transform: rotateZ(360deg); }
/* icon hover effect style 4 */
.social-icon-style-4 a { text-align: center; display: inline-block; border-radius: 100%; position: relative; border: 1px solid rgba($black, 0.1); color: #232323; text-align: center; z-index: 1; }
.social-icon-style-4 a:hover { color: $white; }
.social-icon-style-4 a span { border-radius: 0; display: block; height: 0; left: 50%; margin: 0; position: absolute; top: 50%; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -ms-transition: all 0.3s; -o-transition: all 0.3s; width: 0; z-index: -1; }
.social-icon-style-4 a:hover span { width: 100%; height: 100%; border-radius: 100%; top: 0; left: 0; }
/* icon hover effect style 5 */
.social-icon-style-5 a { text-align: center; display: inline-block; color: #232323 }
.social-icon-style-5 a:hover { color: $white; border-radius: 100%; background-color: $primary }
/* icon hover effect style- 5 light*/
.social-icon-style-5-light a { text-align: center; display: inline-block; color: $white }
.social-icon-style-5-light a:hover { color: $white; border-radius: 100%; background-color: $primary }
/* icon hover effect style 6 */
.social-icon-style-6 a { text-align: center; display: inline-block; border: 1px solid rgba($black, 0.1); color: #232323; background-color: transparent; border-radius: 100%; }
.social-icon-style-6 a:hover { border-color: $primary; color: $primary; background-color: transparent; }
/* icon hover effect style 7 */
.social-icon-style-7 a { text-align: center; display: inline-block; border-radius: 100%; color: #232323; background-color: $white; }
.social-icon-style-7 a:hover { color: $white; -webkit-transform: scale(1.2); -ms-transform: scale(1.2); -moz-transform: scale(1.2); transform: scale(1.2); -o-transform: scale(1.2); }
/* icon hover effect style 8 */
.social-icon-style-8 ul li a { text-align: center; width: auto; height: auto }
.social-icon-style-8 a:hover { color: $primary; }
/* icon hover effect style 9 */
.social-icon-style-9 ul li a { text-align: center; width: auto; height: auto }
/* social icon style */
.social-icon-style-9 a.facebook:hover { color: #3b5998; }
.social-icon-style-9 a.twitter:hover { color: #00aced; }
.social-icon-style-9 a.google:hover { color: #dc4a38; }
.social-icon-style-9 a.dribbble:hover { color: #ea4c89; }
.social-icon-style-9 a.youtube:hover { color: #bb0000; }
.social-icon-style-9 a.linkedin:hover { color: #007bb6; }
.social-icon-style-9 a.pinterest:hover { color: #cb2027 }
.social-icon-style-9 a.email:hover { color: #7d7d7d }
.social-icon-style-9 a.sms:hover { color: #ffbd00 }
.social-icon-style-9 a.sharethis:hover { color: #95d03a }
.social-icon-style-9 a.reddit:hover { color: #ff4500 }
.social-icon-style-9 a.tumblr:hover { color: #32506d }
.social-icon-style-9 a.digg:hover { color: #262626 }
.social-icon-style-9 a.stumbleupon:hover { color: #eb4924 }
.social-icon-style-9 a.whatsapp:hover { color: #25d366 }
.social-icon-style-9 a.vk:hover { color: #4c6c91 }
.social-icon-style-9 a.weibo:hover { color: #ff9933 }
.social-icon-style-9 a.odnoklassniki:hover { color: #d7772d }
.social-icon-style-9 a.xing:hover { color: #1a7576 }
.social-icon-style-9 a.print:hover { color: #222222 }
.social-icon-style-9 a.blogger:hover { color: #ff8000 }
.social-icon-style-9 a.flipboard:hover { color: #e12828 }
.social-icon-style-9 a.meneame:hover { color: #ff6400 }
.social-icon-style-9 a.mailru:hover { color: #168de2 }
.social-icon-style-9 a.delicious:hover { color: #205cc0 }
.social-icon-style-9 a.livejournal:hover { color: #00b0ea }
/* icon hover effect style 10 light */
.social-icon-style-10 { position: relative; }
.social-icon-style-10 a { text-align: center; display: inline-block; border-radius: 100%; position: relative; text-align: center }
.social-icon-style-10 a:hover { color: $white; }
.social-icon-style-10 a i { position: relative; z-index: 1; }
.social-icon-style-10 a span { border-radius: 0; display: block; height: 0; left: 50%; margin: 0; position: absolute; top: 50%; transition: all 0.3s; -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -ms-transition: all 0.3s; -o-transition: all 0.3s; width: 0; }
.social-icon-style-10 a:hover span { width: 100%; height: 100%; border-radius: 100%; top: 0; left: 0; }
/* social icon style */
.social-icon-style-11 { position: relative; }
.social-icon-style-11 a { text-align: center; display: inline-block; border-radius: 100%; position: relative; text-align: center }
.social-icon-style-11 a.facebook { color: #3b5998 }
.social-icon-style-11 a.twitter { color: #00aced }
.social-icon-style-11 a.google { color: #dc4a38 }
.social-icon-style-11 a.dribbble { color: #ea4c89 }
.social-icon-style-11 a.youtube { color: #bb0000 }
.social-icon-style-11 a.linkedin { color: #0077b5 }
.social-icon-style-11 a.instagram { color: #fe1f49 }
.social-icon-style-11 a.pinterest { color: #cb2027 }
.social-icon-style-11 a.flickr { color: #ff0084 }
.social-icon-style-11 a.rss { color: #f2890a }
.social-icon-style-11 a.behance { color: #1769ff }
.social-icon-style-11 a.vine { color: #00bf8f }
.social-icon-style-11 a.email { color: #7d7d7d }
.social-icon-style-11 a.sms { color: #ffbd00 }
.social-icon-style-11 a.sharethis { color: #95d03a }
.social-icon-style-11 a.reddit { color: #ff4500 }
.social-icon-style-11 a.tumblr { color: #32506d }
.social-icon-style-11 a.digg { color: #262626 }
.social-icon-style-11 a.stumbleupon { color: #eb4924 }
.social-icon-style-11 a.whatsapp { color: #25d366 }
.social-icon-style-11 a.vk { color: #4c6c91 }
.social-icon-style-11 a.weibo { color: #ff9933 }
.social-icon-style-11 a.odnoklassniki { color: #d7772d }
.social-icon-style-11 a.xing { color: #1a7576 }
.social-icon-style-11 a.print { color: #222222 }
.social-icon-style-11 a.blogger { color: #ff8000 }
.social-icon-style-11 a.flipboard { color: #e12828 }
.social-icon-style-11 a.meneame { color: #ff6400 }
.social-icon-style-11 a.mailru { color: #168de2 }
.social-icon-style-11 a.delicious { color: #205cc0 }
.social-icon-style-11 a.livejournal { color: #00b0ea }
.social-icon-style-11 a:hover { color: $white; }
footer .small-icon li { margin: 0 8px; }
/* country flag icon */
.icon-country { width: 16px; height: 16px; display: inline-block; margin: 0 10px 0 5px; vertical-align: middle; }
.icon-country.usa { background: url("../images/country-flag-16X16/usa.png") }
.icon-country.england { background: url("../images/country-flag-16X16/england.png") }
.icon-country.china { background: url("../images/country-flag-16X16/china.png") }
.icon-country.hong-kong { background: url("../images/country-flag-16X16/hong-kong.png") }
.icon-country.france { background: url("../images/country-flag-16X16/france.png") }

.icon-mdi {
	font-size: 48px;
}

[class*='social-icon'] a i,
[class*='social-icon'] a i:before {
	line-height: inherit;
}