/* ===================================
    Header
====================================== */

/* header icon */
.header-searchbar {
	border-left: 1px solid rgba($black, .15);
	padding-left: 15px;
	display: inline-block;
	line-height: 15px;
	position: relative;
	top: -1px;
}

.header-social-icon {
	border-left: 1px solid rgba($black, 0.15);
	padding-left: 15px;
	margin-left: 12px;
	display: inline-block;
	line-height: 15px;
	position: relative;
	top: -1px;
}

.heder-menu-button {
	display: inline-block;
	line-height: 14px;
	padding-left: 5px;
	position: relative;
	top: -1px;
}

nav.navbar.bootsnav .heder-menu-button .navbar-toggle {
	float: none;
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	top: 4px
}

.white-link .header-searchbar, .white-link .header-social-icon {
	border-left: 1px solid rgba(255, 255, 255, .15);
}

.header-social-icon a {
	color: #232323
}

.header-social-icon a i, .header-searchbar a i {
	margin: 0 11px;
}

.header-searchbar a i {
	position: relative;
	top: -1px;
}

.header-social-icon a:last-child i {
	margin-right: 0;
}

.search-icon {
	padding-right: 10px;
}

/* menu center */
.menu-center .header-right {
	flex: 1 2 auto;
}

.navbar-collapse {
	padding-left: 0;
	padding-right: 0;
}

/* menu center logo */
.menu-logo-center .navbar-left > li {
	float: none;
	display: inline-block;
	vertical-align: middle;
}

header nav.menu-logo-center .navbar-nav > li > a {
	margin: 0 20px;
}

header nav.menu-logo-center .navbar-nav > li:last-child a {
	margin-right: 20px
}

.menu-logo-center {
	@media (max-width: 991px) {
		.navbar-left.text-right {
			text-align: left;
		}
		
		.logo {
			position: absolute !important;
			left: 0 !important;
			top: -5px !important;
		}
		
		.width-40 {
			width: 100% !important;
		}
		
		.navbar-toggle {
			position: absolute !important;
			right: 25px !important;
			top: 20px !important;
		}
	}
}

/* header navigation color and logo */
header nav.navbar .navbar-nav > li > a, nav.navbar.bootsnav ul.nav > li > a {
	color: #232323;
}

header nav.navbar.white-link .navbar-nav > li > a, nav.navbar.bootsnav.white-link ul.nav > li > a,
header nav.navbar.white-link .header-social-icon a, nav.navbar.bootsnav.white-link .header-social-icon a,
header nav.navbar.white-link .header-searchbar a, nav.navbar.bootsnav.white-link .header-searchbar a,
header nav.navbar.bootsnav ul.nav.white-link > li > a, nav.navbar.white-link .heder-menu-button a {
	color: $white
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a, header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a, header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-social-icon a, header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a, header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a, header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a, header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a, header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a {
	color: #232323
}

header .logo .logo-dark, header .logo .logo-light {
	visibility: hidden;
	opacity: 0;
	width: 0;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

header .logo .logo-dark.default, header .logo .logo-light.default {
	visibility: visible;
	opacity: 1;
	width: auto;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

header.sticky nav.header-dark-transparent .logo .logo-light, header.sticky nav.header-dark .logo .logo-light {
	visibility: visible;
	opacity: 1;
	width: auto;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

header.sticky nav.header-dark-transparent .logo .logo-dark, header.sticky nav.header-dark .logo .logo-dark {
	visibility: hidden;
	opacity: 0;
	width: 0;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

header.sticky nav.header-light-transparent .logo .logo-dark, header.sticky nav.header-light .logo .logo-dark {
	visibility: visible;
	opacity: 1;
	width: auto;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

header.sticky nav.header-light-transparent .logo .logo-light, header.sticky nav.header-light .logo .logo-light {
	visibility: hidden;
	opacity: 0;
	width: 0;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
}

.nav-top-scroll {
	position: absolute;
	top: 0;
	z-index: 5;
}

header.sticky nav.nav-top-scroll {
	background-color: inherit;
}

header a.logo {
	display: inline-block;
	vertical-align: middle;
}

header a.logo img {
	// max-height: 26px;
}

header .left-nav .sidebar-part1 a.logo img {
	max-height: inherit;
}

.logo-holder img {
	max-height: 26px;
}

/* header animation */
.navbar-top {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

.sticky .navbar-top {
	top: -160px;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

.sticky.header-appear .navbar-top.navbar-transparent-no-sticky, .sticky.header-appear .navbar-fixed-top.navbar-transparent-no-sticky {
	top: -160px;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

.sticky.header-appear .navbar-top {
	top: 0;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

header .bootsnav .dropdown .animated {
	-webkit-animation-duration: 0.3s;
	-moz-animation-duration: 0.3s;
	-ms-animation-duration: 0.3s;
	-o-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
}

nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul:before {
	position: absolute;
	height: calc(100% + 20px);
	width: 100%;
	display: block;
	content: "";
}

header.sticky .navbar-scroll-fixed-top {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

header.sticky .navbar-scroll-top {
	position: fixed;
	top: -120px;
	width: 100%;
	left: 0;
	transition-duration: 0s;
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
}

header.sticky.header-appear .navbar-scroll-top {
	top: 0;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

/* navigation */
header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a:hover, header.header-appear nav.bootsnav.header-light .navbar-nav > li > a:hover, header.header-appear nav.header-light-transparent .header-social-icon a:hover, header.header-appear nav.header-light .header-social-icon a:hover, header.header-appear nav.header-light.white-link .header-social-icon a:hover, header.header-appear nav.header-light-transparent.white-link .header-social-icon a:hover, header.header-appear nav.header-light-transparent.white-link .heder-menu-button a:hover, header.header-appear nav.header-light-transparent .header-searchbar a:hover, header.header-appear nav.header-light .header-searchbar a:hover, header.header-appear nav.header-light.white-link .header-searchbar a:hover, header.header-appear nav.header-light-transparent.white-link .header-searchbar a:hover, header nav.navbar .navbar-nav > li > a:hover, nav.navbar.bootsnav ul.nav > li > a:hover, .header-search-form:hover, .header-social-icon a:hover {
	color: rgba($black, 0.6)
}

header nav.navbar.white-link .navbar-nav > li > a:hover, nav.navbar.bootsnav.white-link ul.nav > li > a:hover, header nav.navbar.white-link .header-social-icon a:hover, nav.navbar.bootsnav.white-link .header-social-icon a:hover, header nav.navbar.white-link .header-searchbar a:hover, nav.navbar.bootsnav.white-link .header-searchbar a:hover, header nav.navbar.bootsnav ul.nav.white-link > li > a:hover, header.header-appear nav.header-dark-transparent .navbar-nav > li > a:hover, header.header-appear nav.header-dark .navbar-nav > li > a:hover, header.sticky nav.header-dark .navbar-nav > li > a:hover, header.sticky nav.header-dark-transparent .navbar-nav > li > a:hover, header.header-appear nav.header-dark-transparent .search-button:hover, header.header-appear nav.header-dark .search-button:hover, header.sticky nav.header-dark .search-button:hover, header.sticky nav.header-dark-transparent .search-button:hover {
	color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a:hover, header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-social-icon a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover, header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover {
	color: rgba($black, 0.6)
}

header.sticky nav.navbar.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a:hover {
	color: rgba($black, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav > li > a:hover, header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li > a:hover, header.sticky nav.header-dark .header-social-icon a:hover {
	color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li.active > a, header.header-appear nav.bootsnav.header-light .navbar-nav > li.active > a, header nav.navbar .navbar-nav > li.active > a, nav.navbar.bootsnav ul.nav > li.active > a {
	color: rgba($black, 0.6)
}

header nav.navbar.white-link .navbar-nav > li.active > a, nav.navbar.bootsnav.white-link ul.nav > li.active > a, header nav.navbar.bootsnav ul.nav.white-link > li.active > a, header.header-appear nav.header-dark-transparent .navbar-nav > li.active > a, header.header-appear nav.header-dark .navbar-nav > li.active > a, header.sticky nav.header-dark .navbar-nav > li.active > a, header.sticky nav.header-dark-transparent .navbar-nav > li.active > a, header.sticky nav.navbar.bootsnav.header-dark-transparent.white-link .navbar-nav > li.active > a {
	color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li.active > a, header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li.active > a {
	color: rgba($black, 0.6)
}

header.sticky nav.navbar.white-link .navbar-nav > li.active > a, header.sticky nav.navbar.bootsnav.white-link ul.nav > li.active > a {
	color: rgba($black, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav > li.active > a, header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li.active > a {
	color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a.active, header.header-appear nav.bootsnav.header-light .navbar-nav > li > a.active, header nav.navbar .navbar-nav > li > a.active, nav.navbar.bootsnav ul.nav > li > a.active {
	color: rgba($black, 0.6)
}

header nav.navbar.white-link .navbar-nav > li > a.active, nav.navbar.bootsnav.white-link ul.nav > li > a.active, header nav.navbar.bootsnav ul.nav.white-link > li > a.active, header.header-appear nav.header-dark-transparent .navbar-nav > li > a.active, header.header-appear nav.header-dark .navbar-nav > li > a.active, header.sticky nav.header-dark .navbar-nav > li > a.active, header.sticky nav.header-dark-transparent .navbar-nav > li > a.active {
	color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li > a.active, header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li > a.active {
	color: rgba($black, 0.6)
}

header.sticky nav.navbar.white-link .navbar-nav > li > a.active, header.sticky nav.navbar.bootsnav.white-link ul.nav > li > a.active {
	color: rgba($black, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav > li > a.active, header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li > a.active {
	color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li.dropdown.on > a, header.header-appear nav.bootsnav.header-light .navbar-nav > li.dropdown.on > a, header nav.navbar .navbar-nav > li.dropdown.on > a, nav.navbar.bootsnav ul.nav > li.dropdown.on > a {
	color: rgba($black, 0.6)
}

header nav.navbar.white-link .navbar-nav > li.dropdown.on > a, nav.navbar.bootsnav.white-link ul.nav > li.dropdown.on > a, header nav.navbar.bootsnav ul.nav.white-link > li.dropdown.on > a, header.header-appear nav.header-dark-transparent .navbar-nav > li.dropdown.on > a, header.header-appear nav.header-dark .navbar-nav > li.dropdown.on > a, header.sticky nav.header-dark .navbar-nav > li.dropdown.on > a, header.sticky nav.header-dark-transparent .navbar-nav > li.dropdown.on > a {
	color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav > li.dropdown.on > a, header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav > li.dropdown.on > a {
	color: rgba($black, 0.6)
}

header.sticky nav.navbar.white-link .navbar-nav > li.dropdown.on > a, header.sticky nav.navbar.bootsnav.white-link ul.nav > li.dropdown.on > a {
	color: rgba($black, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav > li.dropdown.on > a, header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav > li.dropdown.on > a {
	color: rgba(255, 255, 255, 0.6);
}

header {
	width: 100%;
	position: relative;
	z-index: 10001;
}

.header-with-topbar .top-header-area {
	font-size: 11px;
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100%;
	transition: ease-in-out 0.3s;
	-webkit-transition: ease-in-out 0.3s;
	-moz-transition: ease-in-out 0.3s;
	-ms-transition: ease-in-out 0.3s;
	-o-transition: ease-in-out 0.3s;
}

.sticky.header-with-topbar .top-header-area {
	top: -45px
}

header .top-header-area .icon-social-very-small a:last-child i {
	margin-right: 0;
}

header nav .row {
	align-items: center;
	display: -ms-flex;
	display: -webkit-flex;
	display: -moz-flex;
	display: flex;
	height: auto;
	padding: 0;
	// width: 100%;
}

header nav .row > div:first-child {
	flex: 1 1 auto;
}

header nav.navbar {
	border-radius: 0;
	padding: 0;
}

header nav.navbar-default, nav.navbar.bootsnav {
	border-bottom: 0;
}

header nav {
	width: 100%;
	top: 0px;
	z-index: 99;
	margin-bottom: 0;
	display: table;
}

header nav .nav-header-container {
	display: table-cell;
	vertical-align: middle;
	position: relative;
}

header nav .navbar-nav > li > a {
	color: #232323;
	font-size: 12px;
	font-weight: 700;
	margin: 0 20px;
	padding: 5px 0px 5px;
	text-transform: uppercase;
	letter-spacing: 0.015em;
}

header nav .navbar-nav > li > a:hover, header nav .navbar-nav > li > a:focus, header nav .navbar-nav > li.active > a {
	background: transparent;
}

header nav .navbar-nav > li:last-child a {
	margin-right: 0;
}

.navbar-fixed-bottom, .navbar-fixed-top {
	z-index: 9999
}

header nav.navbar-default .navbar-nav > li > a:hover, header nav.navbar-default .navbar-nav > li > a:focus, header nav.navbar-default .navbar-nav > li.active > a {
	border-bottom-color: #232323
}

header nav .navbar-nav.text-normal > li > a {
	font-weight: 500;
	font-size: 12px
}

.navbar-nav > li {
	position: inherit;
}

.navbar-nav > li.dropdown > .fa {
	display: none
}

.navbar-nav > li ul {
	margin: 0;
	padding: 0;
}

.navbar-toggle {
	border-radius: 0;
	margin-right: 0;
}

.mobile-toggle {
	display: block
}

.sidebar-nav .mobile-toggle {
	display: none
}

.mobile-toggle span {
	display: block;
	width: 16px;
	height: 2px;
	background: #232323;
	content: "";
	margin: 3px 0px
}

.mobile-toggle:hover span, .white-link .mobile-toggle:hover span {
	background-color: $primary;
}

.white-link .mobile-toggle span {
	background: $white;
}

header nav .brand-logo {
	padding-top: 20px;
	padding-bottom: 20px;
}

header.sticky nav .brand-logo {
	padding-top: 15px;
	padding-bottom: 15px;
}

header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav > li > a, header.sticky nav.navbar.navbar-default.navbar-top ul.nav > li > a {
	padding: 20px 0 20px;
}

/* mega menu */
nav .accordion-menu {
	position: inherit;
}

nav.navbar.bootsnav li.dropdown ul li {
	list-style: none;
	padding: 0;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full {
	width: 100%;
	box-sizing: border-box;
	background: rgba(35, 35, 35, 1);
	padding: 45px 50px;
	box-shadow: none;
	border-radius: 0;
	border: 0;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full ul {
	margin: 0;
	list-style: none;
}

nav.navbar.bootsnav li.dropdown .menu-back-div > ul {
	margin: 0 auto;
	width: 1070px;
	display: table;
	background: rgba(35, 35, 35, 1);
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li.dropdown-header {
	color: $white;
	display: inline-block;
	font-size: 11px;
	font-weight: 500;
	margin-bottom: 8px;
	white-space: inherit;
	padding: 0 40px;
	border-bottom: none;
	text-transform: uppercase
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li a {
	text-align: left;
	font-size: 12px;
	color: #9d9d9d;
	padding: 3px 40px;
	text-transform: capitalize;
	margin: 0;
	display: block;
	position: relative;
	overflow: hidden;
	left: 0
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li:first-child a, nav.navbar.bootsnav li.dropdown .mega-menu-full > ul li:first-child .dropdown-header {
	padding-left: 0;
}

nav.navbar.bootsnav li.dropdown.megamenu-fw .icon-list-menu li > a > i {
	font-size: 14px;
	vertical-align: middle;
	width: 24px;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li {
	border-right: 1px solid rgba(255, 255, 255, 0.05);
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child {
	border: 0;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li:last-child img {
	padding-left: 40px;
	background-size: cover;
	background-position: bottom right;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li > ul {
	margin-top: 18px;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full > ul > li > ul:first-child {
	margin-top: 0;
}

nav.navbar.bootsnav li.dropdown .megamenu-fw .icon-list-menu li > a > i {
	font-size: 14px;
	vertical-align: middle;
	width: 24px;
}

nav.navbar.navbar-default ul li.dropdown .dropdown-menu .mega-menu-column li > a.menu-banner-image, nav.navbar.navbar-default ul li.dropdown .dropdown-menu .mega-menu-column li > a:hover.menu-banner-image {
	left: 0;
	padding: 0;
	margin-bottom: 20px
}

nav.navbar.navbar-default ul li.dropdown .dropdown-menu .mega-menu-column li:last-child > a.menu-banner-image {
	margin-bottom: 0;
}

/* header center logo */
.nav-left, .nav-right, .nav-center {
	display: table-cell;
	vertical-align: middle;
	width: 33.33%
}

header nav .navbar-nav.navbar-center > li:last-child a {
	margin-right: 20px;
}

.social-icon a {
	margin: 0 15px;
}

.social-icon a:first-child {
	margin-left: 0;
}

.social-icon a:last-child {
	margin-right: 0;
}

header nav.nav-center-logo .row {
	min-height: 0;
	height: auto
}

header nav.nav-center-logo .row {
	display: block;
}

header nav.nav-center-logo .row > div:first-child {
	flex: none;
}

.nav-center-logo .navbar-nav > li {
	float: none;
	display: inline-block;
	vertical-align: middle;
}

.nav-center-logo .navbar-nav > li > a {
	display: flex
}

.nav-center-logo .navbar-left {
	margin-left: -15px;
}

.nav-center-logo .navbar-left li:first-child a {
	margin-left: 0;
}

nav.nav-center-logo.navbar.bootsnav li.dropdown ul.dropdown-menu, nav.bootsnav.brand-center ul.nav > li.dropdown > ul.dropdown-menu {
	margin-top: 0px;
}

.center-logo {
	left: 50%;
	position: absolute;
	margin-top: -14px;
	top: 50%;
	text-align: center;
	transform: translateX(-50%);
	z-index: 1;
	width: 105px;
	max-width: 20%
}

.page nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a {
	text-align: left
}

header nav.navbar-fixed .nav-header-container {
	width: 100%;
	float: left;
}

header .menu-logo-center .navbar-nav > li.social-links {
	padding: 26px 0;
}

header.sticky .menu-logo-center .navbar-nav > li.social-links {
	padding: 20px 0;
}

/* top logo */
.navbar.navbar-brand-top > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
	margin-left: 0;
}

.navbar-brand-top .navbar-brand {
	height: auto;
}

nav.navbar-brand-top.navbar.navbar-default ul.nav > li > a {
	padding: 20px 0;
}

.navbar-brand-top .navbar-collapse.collapse {
	display: inline-block !important;
	vertical-align: middle;
}

/* center navigation */
.center-nav {
	float: none;
	text-align: center
}

.center-nav > li {
	float: none;
	display: inline-block;
}

nav.navbar.bootsnav.menu-center ul.nav.navbar-center {
	display: table;
	float: none;
	margin: 0 auto;
	table-layout: fixed;
}

/* simple dropdown menu */
.simple-dropdown.dropdown {
	position: relative;
}

.simple-dropdown .dropdown-menu {
	position: absolute;
	left: 0;
	background: rgba(35, 35, 35, 1);
	min-width: 260px;
	padding: 13px 0 15px;
	box-shadow: none;
}

nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li {
	padding-left: 28px;
	padding-right: 28px;
}

.simple-dropdown .dropdown-menu > li > a {
	font-size: 12px;
	color: #9d9d9d;
}

.simple-dropdown .dropdown-menu > li a {
	padding: 9px 0 7px;
	overflow: hidden;
	position: relative;
	left: 0;
}

.simple-dropdown .dropdown-menu > li a:hover {
	background-color: $black;
	color: $white;
}

.simple-dropdown .dropdown-menu > li > a.dropdown-toggle .fa, nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li.dropdown > a > .fa {
	position: absolute;
	right: 0;
	font-size: 12px;
	margin-top: 0;
	height: 20px;
	width: 20px;
	line-height: 20px;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li a .fa {
	font-size: 16px;
}

nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul, nav.navbar.bootsnav .simple-dropdown .dropdown-menu > li.dropdown > ul > li.dropdown > ul {
	background: rgba(35, 35, 35, 1);
	display: none;
	left: 240px;
	margin: -13px 0 0 1px;
	min-width: 220px;
	white-space: nowrap;
	position: absolute;
	top: 0;
	padding: 13px 0;
	font-weight: 400;
	text-transform: capitalize;
}

.simple-dropdown .dropdown-menu > li.dropdown > ul li a {
	color: #9d9d9d;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
}

nav.navbar.bootsnav.navbar-default li.dropdown.simple-dropdown > ul.dropdown-menu > li.active > a {
	color: $white;
}

/* light transparent navigation */
header.header-appear nav.header-light-transparent, header.sticky nav.navbar-fixed-top.header-light-transparent, header.sticky nav.navbar-scroll-fixed-top.header-light-transparent {
	background: rgba(255, 255, 255, 0.95);
	box-shadow: 0 5px 10px -10px rgba($black, 0.6)
}

header.header-appear nav.header-light-transparent .separator-line-verticle-small, header.header-appear nav.header-light .separator-line-verticle-small {
	background: rgba($black, 0.4)
}

header.header-appear nav.header-light-transparent .header-social-icon a, header.header-appear nav.header-light .header-social-icon a, header.header-appear nav.header-light.white-link .header-social-icon a, header.header-appear nav.header-light-transparent.white-link .header-social-icon a, header.header-appear nav.header-light-transparent.white-link .heder-menu-button a, header.header-appear nav.header-light-transparent .header-searchbar a, header.header-appear nav.header-light .header-searchbar a, header.header-appear nav.header-light.white-link .header-searchbar a, header.header-appear nav.header-light-transparent.white-link .header-searchbar a {
	color: #232323
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav > li > a, header.header-appear nav.bootsnav.header-light .navbar-nav > li > a {
	color: #232323;
}

header.header-appear nav.header-light-transparent .header-searchbar, header.header-appear.header-light-transparent .header-social-icon, header.header-appear nav.header-light .header-searchbar, header.header-appear nav.header-light-transparent.white-link .header-social-icon, header.sticky nav.header-light-transparent .header-searchbar, header.sticky nav.header-light-transparent .header-social-icon, header.sticky nav.header-light .header-searchbar, header.sticky nav.header-light .header-social-icon {
	border-left: 1px solid rgba($black, 0.25);
}

header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .navbar-nav > li > a, header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .header-social-icon a, header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .header-searchbar a, header.header-appear nav.nav-scroll-fixed-top.header-light-transparent.white-link .navbar-nav > li > a, header.header-appear nav.nav-scroll-fixed-top.header-light-transparent.white-link .header-social-icon a, header.header-appear nav.nav-scroll-fixed-top.header-light-transparent.white-link .header-searchbar a {
	color: #232323
}

header.sticky nav.header-light-transparent .heder-menu-button .mobile-toggle span, header.sticky nav.header-light .heder-menu-button .mobile-toggle span {
	background: #232323;
}

/* dark transparent navigation */
header.header-appear nav.header-dark-transparent, header.sticky nav.navbar-fixed-top.header-dark-transparent, header.sticky nav.navbar-scroll-fixed-top.header-dark-transparent {
	background: rgba(23, 23, 23, 0.85);
}

header.header-appear nav.header-dark-transparent .separator-line-verticle-small, header.header-appear nav.header-dark .separator-line-verticle-small {
	background: $white
}

header.header-appear nav.header-dark-transparent .navbar-nav > li > a, header.header-appear nav.header-dark .navbar-nav > li > a, header.sticky nav.header-dark .navbar-nav > li > a, header.sticky nav.header-dark-transparent .navbar-nav > li > a {
	color: $white
}

header.header-appear nav.header-dark-transparent .header-social-icon a, header.header-appear nav.header-dark .header-social-icon a, header.sticky nav.header-dark .header-social-icon a, header.sticky nav.header-dark-transparent .header-social-icon a {
	color: $white
}

header.header-appear nav.header-dark-transparent .search-button, header.header-appear nav.header-dark-transparent .right-menu-button, header.header-appear nav.header-dark .search-button, header.header-appear nav.header-dark .right-menu-button, header.sticky nav.header-dark .search-button, header.sticky nav.header-dark .right-menu-button, header.sticky nav.header-dark-transparent .search-button, header.sticky nav.header-dark-transparent .right-menu-button {
	color: $white
}

header.sticky nav.header-dark-transparent .header-social-icon, header.sticky nav.header-dark .header-social-icon, header.sticky nav.header-dark-transparent .header-searchbar, header.sticky nav.header-dark .header-searchbar {
	border-left: 1px solid rgba(255, 255, 255, 0.4);
}

header.sticky nav.header-dark-transparent .mobile-toggle span, header.sticky .header-dark .mobile-toggle span {
	background: $white;
}

header.sticky nav.header-dark-transparent .mobile-toggle:hover span, header.sticky .header-dark .mobile-toggle:hover span {
	background: red;
}

/* light navigation */
header.header-appear nav.header-light, header.sticky nav.navbar-fixed-top.header-light, header.sticky nav.navbar-scroll-fixed-top.header-light {
	background-color: $white;
	box-shadow: 0 5px 10px -10px rgba($black, 0.6)
}

/* dark navigation */
header.header-appear nav.header-dark, header.sticky nav.navbar-fixed-top.header-dark, header.sticky nav.navbar-scroll-fixed-top.header-dark {
	background-color: $black;
}

/* collepsed menu icon */
.navbar-default .navbar-toggle .icon-bar, header.sticky .navbar-default.header-light .navbar-toggle .icon-bar, header.sticky .navbar-default.header-light-transparent .navbar-toggle .icon-bar {
	background: #232323;
}

.navbar-default.white-link .navbar-toggle .icon-bar, header.sticky .navbar-default.header-dark .navbar-toggle .icon-bar, header.sticky .navbar-default.header-dark-transparent .navbar-toggle .icon-bar {
	background: $white;
}

/* hamburger menu */
nav.navbar.bootsnav .btn-hamburger {
	padding: 0;
}

nav.navbar.bootsnav .btn-hamburger .navbar-toggle {
	margin: 0;
	top: -2px;
}

.hamburger-menu-wrepper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $black;
	z-index: -10005;
	padding: 55px 80px;
	display: table;
	opacity: 0;
	visibility: collapse;
}

.menu-middle {
	display: table;
	height: 100%;
	width: 65%;
	margin: 0 auto;
	opacity: 0;
}

.hamburger-menu-links {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	font-size: 52px;
	line-height: 55px;
	opacity: 0;
}

.hamburger-menu-links li {
	float: left;
	margin-bottom: 36px;
	width: 50%;
}

.hamburger-menu-links li a {
	color: $white;
	position: relative;
}

.hamburger-menu-links li.open > a {
	color: $primary;
}

.hamburger-menu-links li a:hover, .follow-box a:hover {
	color: $primary;
}

.animation-box {
	position: absolute;
	top: 5%;
	height: 100%;
	width: 100%;
	left: 0;
	transform: perspective(600px) rotateX(-20deg);
	-weblit-transform: perspective(600px) rotateX(-20deg);
	-moz-transform: perspective(600px) rotateX(-20deg);
	-ms-transform: perspective(600px) rotateX(-20deg);
	-o-transform: perspective(600px) rotateX(-20deg);
}

.hamburger-menu.show-menu .hamburger-menu-wrepper {
	z-index: 10004;
	opacity: 1;
	visibility: visible;
}

.hamburger-menu.show-menu .animation-box {
	top: 0;
	transform: perspective(600px) rotateX(0deg);
	-webkit-transform: perspective(600px) rotateX(0deg);
	-moz-transform: perspective(600px) rotateX(0deg);
	-o-transform: perspective(600px) rotateX(0deg);
	-ms-transform: perspective(600px) rotateX(0deg);
	opacity: 1;
}

.hamburger-menu.show-menu .hamburger-menu-links, .hamburger-menu.show-menu .menu-middle {
	opacity: 1;
}

.hamburger-menu.show-menu .btn-hamburger .mobile-toggle, .hamburger-menu .btn-hamburger .close-menu {
	transform: scale(0);
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
}

.hamburger-menu.show-menu .btn-hamburger .close-menu, .hamburger-menu .btn-hamburger .mobile-toggle {
	transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
}

.hamburger-menu .hamburger-menu-wrepper .btn-hamburger {
	border-color: $white;
	height: 18px;
	position: absolute;
	width: 18px;
	z-index: 99;
	padding: 0;
	margin: -27px 0 0 -15px;
	opacity: 0;
}

.hamburger-menu.show-menu .hamburger-menu-wrepper .btn-hamburger {
	opacity: 1;
	right: 40px;
	transition-delay: 0.2s;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
}

.hamburger-menu .close-button-menu {
	float: none;
	height: auto;
	margin: 0;
	padding: 0;
	position: inherit;
	right: -26px;
	top: 44px;
	width: auto;
}

.hamburger-menu .close-button-menu:after, .hamburger-menu .close-button-menu:before {
	background: $white;
	width: 22px;
	right: 26px;
	top: -34px;
}

.hamburger-menu-links li.dropdown > a:after {
	content: "\f107";
	display: inline-block;
	position: absolute;
	right: -60px;
	top: 15px;
	font-family: 'FontAwesome';
	font-size: 42px
}

/* header style two */
.header-with-topbar .navbar-top {
	top: 36px;
	background: $white;
	box-shadow: 0 4px 10px -10px rgba($black, 0.6)
}

.header-with-topbar.sticky .navbar-top {
	top: -160px;
}

.header-with-topbar.sticky.header-appear .navbar-top {
	top: 0;
}

/* full width light transparent navigation */
header nav.nav-full-width {
	width: 100%
}

.close-button-menu {
	float: right;
	padding: 10px;
	background-color: transparent;
	color: #232323;
	border: 0;
	position: absolute;
	height: 40px;
	width: 40px;
	outline: none;
	top: 20px;
	right: 20px
}

.full-width-pull-menu .dropdown-toggle {
	position: relative;
}

.full-width-pull-menu span.dropdown-toggle {
	position: absolute;
	right: 30px;
	width: 40px;
	height: 40px;
	text-align: center;
	top: 43px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}

.full-width-pull-menu .dropdown .dropdown-toggle:before {
	content: "";
	display: block;
	position: absolute;
	right: 20px;
	top: 15px;
	height: 11px;
	width: 1px;
	background: #232323;
}

.full-width-pull-menu .dropdown.open .dropdown-toggle:before {
	height: 0;
}

.full-width-pull-menu .dropdown .dropdown-toggle:after {
	content: "";
	display: block;
	position: absolute;
	right: 15px;
	top: 20px;
	height: 1px;
	width: 11px;
	background: #232323;
}

.full-width-pull-menu .dropdown ul {
	background-color: inherit;
}

/* full width navigation style 1 */
.full-width-pull-menu .link-style-1 .link-style-1 ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.full-width-pull-menu .link-style-1 ul li {
	margin: 20px 0;
	display: table
}

.full-width-pull-menu .link-style-1 ul li a {
	background: inherit;
	color: #232323;
	font-size: 22px;
	line-height: 26px;
	position: relative;
	padding-left: 35px;
}

.full-width-pull-menu .link-style-1 ul li:hover > a, .full-width-pull-menu .link-style-1 ul li.open > a {
	color: $primary
}

.full-width-pull-menu .link-style-1 ul li:hover > a:after, .full-width-pull-menu .link-style-1 ul li.open > a:after {
	background: $primary
}

.full-width-pull-menu .link-style-1 ul li a:after {
	background: $gray-200;
	content: "";
	display: inline-block;
	height: 4px;
	left: 0;
	position: absolute;
	top: 14px;
	width: 18px;
}

.full-width-pull-menu .link-style-1 .dropdown.open ul {
	display: block;
}

.full-width-pull-menu .link-style-1 .dropdown ul {
	position: inherit;
	left: 0;
	right: 0;
	padding: 10px 30px 0;
	background-color: inherit;
	border: 0;
	float: none;
	box-shadow: none;
	margin: 0;
}

.full-width-pull-menu .link-style-1 ul li li a {
	font-size: 18px;
	line-height: 22px;
	padding-left: 30px;
}

.full-width-pull-menu .link-style-1 .dropdown ul li a:hover, .dropdown ul li a:focus {
	background-color: inherit
}

.full-width-pull-menu .link-style-1 .dropdown ul li {
	margin: 5px;
}

.full-width-pull-menu .link-style-1 .dropdown ul li:last-child {
	margin-bottom: 0;
}

/* full width navigation style 2 */
.full-width-pull-menu .link-style-2 .link-style-2 ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.full-width-pull-menu .link-style-2 ul li {
	display: table;
	width: 90%;
}

.full-width-pull-menu .link-style-2 ul li a {
	background: inherit;
	color: #232323;
	font-size: 48px;
	line-height: 85px;
	position: relative;
}

.full-width-pull-menu .link-style-2 ul li:hover > a, .full-width-pull-menu .link-style-2 ul li.open > a {
	color: $primary
}

.full-width-pull-menu .link-style-2 ul li.open > a:after {
	background: $primary
}

.full-width-pull-menu .link-style-2 .dropdown ul {
	display: block !important;
	height: 0;
	overflow: hidden
}

.full-width-pull-menu .link-style-2 .dropdown ul {
	position: inherit;
	left: 0;
	right: 0;
	padding: 0;
	background-color: inherit;
	border: 0;
	float: none;
	box-shadow: none;
	margin: 0;
}

.full-width-pull-menu .link-style-2 .dropdown.open ul {
	display: block;
	height: 100%;
}

.full-width-pull-menu .link-style-2 .dropdown ul li a {
	font-size: 14px;
	line-height: 18px;
	color: $gray-800;
	padding: 0;
	margin: 0;
}

.full-width-pull-menu .link-style-2 .dropdown ul li {
	border-bottom: 1px solid #e3e3e3;
	padding: 7px 0;
}

.full-width-pull-menu .link-style-2 .dropdown ul li:last-child {
	border-bottom: 1px solid transparent;
}

.full-width-pull-menu .link-style-2 .dropdown ul li a:hover, .dropdown ul li a:focus {
	background-color: inherit
}

.full-width-pull-menu .link-style-2 .dropdown ul li ul.sub-menu {
	padding-left: 15px;
	padding-top: 2px;
}

.full-width-pull-menu .link-style-2 .dropdown ul li ul.sub-menu li {
	border-bottom: 0;
	padding: 5px 0 0 0;
}

.full-width-pull-menu .link-style-2 .dropdown ul li:last-child {
	margin-bottom: 0;
}

.full-width-pull-menu .link-style-2 .icon-social-medium a:first-child {
	margin-left: 0;
}

.full-width-pull-menu .link-style-2 .icon-social-medium a:last-child {
	margin-right: 0;
}

.close-button-menu:after, .close-button-menu:before {
	content: "";
	display: block;
	background: $black;
	height: 2px;
	width: 18px;
	position: absolute;
}

.close-button-menu:after {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
}

.close-button-menu:before {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
}

/* full navigation */
.show-menu .menu-wrap, .menu-wrap {
	left: auto
}

.show-menu .menu-wrap {
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	opacity: 1;
	visibility: visible;
	z-index: 10001;
}

.menu-wrap {
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	width: 100%;
	z-index: -10001;
	position: fixed;
	top: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
}

/* container fluid navigation padding */
header nav.nav-box-width {
	padding: 0 50px;
}

/* hamburger menu right side */
.big-menu-right {
	position: fixed;
	top: 0;
	right: 0;
	width: 40%;
	height: 100%;
	padding: 0 5%;
	opacity: 0;
	transform: translateX(30%) scaleX(0.6);
	-webkit-transform: translateX(30%) scaleX(0.6);
	-moz-transform: translateX(30%) scaleX(0.6);
	-o-transform: translateX(30%) scaleX(0.6);
	-ms-transform: translateX(30%) scaleX(0.6);
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	z-index: -1;
}

.big-menu-links {
	list-style: none;
	margin: 0;
	padding: 0;
}

.big-menu-links li {
	margin-bottom: 15px;
	display: block;
	width: 100%;
	float: left
}

.big-menu-links li a {
	font-size: 50px;
	line-height: 1.5;
	color: #676767;
	float: left
}

.big-menu-links li a:after {
	width: 0;
	background: $primary;
	height: 3px;
	content: "";
	display: block;
	transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
}

.big-menu-links li a:hover {
	color: #dddddd;
}

.big-menu-links li a:hover:after {
	width: 100px;
	transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
}

.big-menu-right.open {
	transform: translateX(0) scaleX(1);
	-moz-transform: translateX(0) scaleX(1);
	-webkit-transform: translateX(0) scaleX(1);
	-o-transform: translateX(0) scaleX(1);
	-ms-transform: translateX(0) scaleX(1);
	opacity: 1;
	z-index: 9999
}

.big-menu-close {
	background: $white;
	position: absolute;
	right: 0;
	width: 40px;
	height: 40px;
	color: #232323;
}

/* dropdown menu */
nav.navbar.navbar-default ul.nav > li > a {
	padding: 26px 0;
	background: transparent;
	border-color: transparent;
	font-weight: 500
}

nav.navbar.navbar-default ul.nav > li.dropdown > a.dropdown-toggle::after, nav.navbar.sidebar-nav ul.nav > li.dropdown > a.dropdown-toggle::after {
	content: "";
	vertical-align: middle;
	position: relative;
	top: -1px;
	margin-top: 0;
}

nav.navbar.navbar-default ul li.dropdown ul.dropdown-menu > li:last-child {
	border-bottom: 0;
	border-bottom: 0;
}

nav.navbar.navbar-default ul li.dropdown .dropdown-menu li > a:hover {
	color: $white;
	background: transparent;
	left: 5px;
}

nav.navbar.navbar-default ul li.dropdown .dropdown-menu li.active > a {
	color: $white;
}

nav.navbar.navbar-default ul.menu-links li.dropdown ul.dropdown-menu li > a:hover, nav.navbar.navbar-default ul.menu-links li.dropdown ul.dropdown-menu li.active > a {
	color: #232323
}

.dropdown ul.second-level {
	left: 270px;
	top: 0;
	height: 100%;
}

.dropdown ul.third-level {
	left: 530px;
	top: 0;
	height: 100%;
}

nav.navbar ul.nav .dropdown-menu.second-level .dropdown-menu {
	margin-top: 0
}

.navbar-default ul li li.dropdown ul {
	display: none
}

.navbar-default ul li li.dropdown.on > ul {
	display: block;
	position: absolute;
	left: 100%;
	margin-top: 0;
	padding: 0;
	top: 0;
}

.navbar-default ul li li.dropdown.on > ul > li.dropdown.on > ul {
	display: block;
	position: absolute;
	left: 100%;
	margin-top: 0;
	padding: 0;
	top: 0;
}

.navbar-default ul li li.dropdown ul.dropdown-menu {
	position: absolute;
	left: 100%;
	margin-top: 0;
	padding: 0;
	top: 0;
	box-shadow: none;
}

.navbar-default ul li li.dropdown ul.dropdown-menu li {
	padding: 0;
}

.dropdown-menu .dropdown a {
	position: relative
}

/* navigation left sidebar */
header .menu-wrap .widget {
	margin-bottom: 30px;
	display: inline-block;
	width: 100%
}

.nav.navbar-left-sidebar {
	transition: all 0s ease-in-out 0s;
	-webkit-transition: all 0s ease-in-out 0s;
	-moz-transition: all 0s ease-in-out 0s;
	-ms-transition: all 0s ease-in-out 0s;
	-o-transition: all 0s ease-in-out 0s;
	overflow-y: auto;
}

.nav.navbar-left-sidebar li {
	display: inline-block;
	width: 100%;
	padding: 0 45px;
}

.nav.navbar-left-sidebar li a, nav.navbar.bootsnav.navbar-left-sidebar ul.nav > li > a {
	font-size: 11px;
	text-transform: uppercase;
	color: $gray-700;
	background: transparent !important;
	border-bottom: 1px solid #e5e5e5 !important;
	padding: 15px 0
}

.nav.navbar-left-sidebar li:last-child a {
	border-bottom: none
}

.nav.navbar-left-sidebar li a:hover {
	color: $white
}

.nav.navbar-left-sidebar li a .nav-caret {
	height: 35px;
	text-align: right;
	width: 35px;
}

.nav.navbar-left-sidebar .dropdown .second-level {
	opacity: 0 !important;
	left: 245px;
	display: block !important;
	visibility: hidden;
}

.nav.navbar-left-sidebar .dropdown.on .second-level {
	opacity: 1 !important;
	left: 289px;
	visibility: visible;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

.nav.navbar-left-sidebar .second-level .dropdown .third-level {
	opacity: 0 !important;
	display: block !important;
	visibility: hidden;
	left: 525px;
}

.nav.navbar-left-sidebar .second-level .dropdown.on .third-level {
	opacity: 1 !important;
	left: 569px;
	visibility: visible;
	transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
}

.nav.navbar-left-sidebar .dropdown-menu .dropdown > a:after {
	display: none
}

/* pull left sidebar */
.cbp-spmenu {
	background: $white;
	position: fixed;
	z-index: 1;
}

.cbp-spmenu-vertical {
	width: 455px;
	height: 100%;
	top: 0;
	z-index: 10005;
}

.cbp-spmenu-right {
	right: -455px;
}

.cbp-spmenu-left {
	left: -100%;
}

.cbp-spmenu-left.cbp-spmenu-open {
	left: 0;
}

.cbp-spmenu-right.cbp-spmenu-open {
	right: 0px;
	overflow-x: auto;
	box-shadow: 0 0 4px rgba($black, .2);
}

.cbp-spmenu-push {
	overflow-x: hidden;
	position: relative;
	left: 0;
}

.cbp-spmenu, .cbp-spmenu-push {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/* custome scrollbar */
.cbp-spmenu-right::-webkit-scrollbar, .navbar-left-sidebar::-webkit-scrollbar {
	width: 5px;
}

.cbp-spmenu-right::-webkit-scrollbar-track, .navbar-left-sidebar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba($black, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba($black, 0.3);
	-moz-box-shadow: inset 0 0 6px rgba($black, 0.3);
	-ms-box-shadow: inset 0 0 6px rgba($black, 0.3);
	-o-box-shadow: inset 0 0 6px rgba($black, 0.3);
}

.cbp-spmenu-right::-webkit-scrollbar-thumb, .navbar-left-sidebar::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

/* sidebar navigation */
nav.navbar.sidebar-nav .container {
	display: table-cell;
	height: 75px;
	position: relative;
	vertical-align: top;
}

nav.navbar.bootsnav.sidebar-nav .navbar-left-sidebar > li > a {
	color: #626262;
	border-color: $gray-500;
	padding: 15px 0;
}

nav.navbar.sidebar-nav .navbar-left-sidebar li a:hover, nav.navbar.bootsnav.sidebar-nav ul.nav.navbar-left-sidebar li.active > a {
	color: $black
}

.sidebar-nav .logo-holder {
	text-align: center;
	min-height: 107px;
}

.sidebar-nav .footer-holder {
	text-align: center
}

nav.navbar .toggle-button {
	background: $black;
	border: medium none;
	height: auto;
	margin: 0;
	padding: 0;
	width: auto;
}

.sidebar-nav .dropdown-menu {
	box-shadow: none;
	height: 100%;
	left: 270px;
	padding: 0;
	position: fixed;
	top: 0;
	width: 240px;
	padding: 80px 0
}

nav.navbar.sidebar-nav li.dropdown ul.dropdown-menu {
	border: none
}

nav.navbar.bootsnav.sidebar-nav li.dropdown ul.dropdown-menu {
	padding-top: 160px;
}

.sidebar-nav .dropdown-menu li > a {
	margin: 0 45px
}

nav.navbar.sidebar-nav {
	border: none;
	display: inline-table;
	width: 290px;
	border-radius: 0;
	position: fixed;
	top: 0;
	z-index: 10005;
	height: 100%;
	padding: 50px 0px 25px;
	border-right: 1px solid rgba($black, .07);
	left: 0
}

.sidebar-wrapper {
	padding-left: 290px;
}

/* sidebar navigation style 1 */
.sidebar-nav-style-1 .dropdown .second-level {
	top: 0;
	left: 290px;
	width: 280px
}

.sidebar-nav-style-1 .dropdown.on .second-level {
	background: #fbfbfb;
}

.sidebar-nav-style-1 .dropdown .third-level {
	top: 0;
	left: 570px;
	width: 280px;
	background: #f3f3f3;
}

.sidebar-nav-style-1 .dropdown ul.second-level li ul {
	display: none;
	opacity: 0
}

/* sidebar navigation style 2 */
.left-nav-sidebar {
	padding-left: 90px;
	transition: all 0.3s ease 0s;
}

.left-nav {
	position: fixed;
	top: 0;
	width: auto;
	left: 0;
	height: 100%;
	background-color: $white;
	z-index: 99999;
	transition: all 0.3s ease 0s
}

.sidebar-part1 {
	position: absolute;
	top: 0;
	width: 90px;
}

.sidebar-part2 {
	padding: 0 0 0 110px;
	border-right: 1px solid #dfdfdf;
	width: 400px;
	height: 100%;
	position: fixed;
	background-color: $white;
	z-index: -1;
	left: -370px;
}

.sidebar-part3 {
	position: absolute;
	bottom: 0;
}

.left-nav-on .sidebar-part2 {
	left: 0;
}

.sidebar-part2:before {
	width: 100%;
	height: 1px;
	background-color: #dfdfdf;
	content: "";
	position: absolute;
	bottom: 75px;
	left: 0;
	display: inline-block
}

.left-nav-on .sidebar-part2 .sidebar-middle .sidebar-middle-menu .nav {
	overflow-y: auto;
	overflow-x: hidden
}

.sidebar-part2 .sidebar-middle {
	padding: 180px 0
}

.sidebar-part2 .sidebar-middle-menu {
	display: table-cell;
	height: 100%;
	width: 100%;
	vertical-align: middle
}

.bottom-menu-icon {
	width: 90px;
	padding: 22px 30px;
	text-align: center;
}

header .sidebar-part2 nav.navbar.bootsnav ul > li > a {
	font-size: 36px;
	display: block;
	padding: 0;
	color: #0f0f0f;
	text-transform: none;
	margin: 0;
	line-height: 40px;
	font-weight: 500;
	position: relative;
}

header .sidebar-part2 nav.navbar.bootsnav ul > li > a > .fa {
	font-size: 25px;
	top: 10px;
	position: absolute;
	right: 18px;
}

header .sidebar-part2 nav.navbar.bootsnav ul > li > .second-level > li > a > .fa {
	font-size: 14px;
	line-height: 30px;
}

.sidebar-part2 ul > li {
	margin: 0;
	float: none;
	padding: 15px 20px 15px 0;
	width: 291px;
	word-break: break-all;
	position: initial;
}

.sidebar-part2 ul li a:hover {
	background-color: inherit;
}

.sidebar-part2 nav.navbar.bootsnav {
	border-bottom: 0;
	position: inherit;
}

.sidebar-part2 .right-bg {
	position: fixed;
	height: 100%;
	left: 0;
	top: 0;
	background-color: $white;
	width: 90px;
}

.sidebar-part2 .right-bg:before {
	background-color: #dfdfdf;
	bottom: 75px;
	content: "";
	display: inline-block;
	height: 1px;
	left: 0;
	position: absolute;
	width: 100%;
}

.sidebar-part2 .right-bg img {
	position: absolute;
	top: 50%;
	left: 37px;
	transform: translateY(-100px);
	-webkit-transform: translateY(-100px);
	-moz-transform: translateY(-100px);
	-ms-transform: translateY(-100px);
	-o-transform: translateY(-100px);
}

header .sidebar-part2 nav.navbar.bootsnav ul li ul li a {
	font-size: 14px;
	line-height: 20px;
	width: 100%;
	padding: 15px 0;
	border-bottom: 1px solid #f0f0f0;
	color: $gray-700;
	font-weight: 400;
}

header .sidebar-part2 nav.navbar.bootsnav ul li.dropdown > a, .sidebar-nav-style-1 .navbar-left-sidebar li.dropdown > a {
	padding-right: 20px;
}

header .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover {
	border-bottom: 1px solid $primary;
	background: inherit
}

header .sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li:last-child > a:hover {
	border-bottom: 0;
}

header .sidebar-part2 nav.navbar.bootsnav ul li ul li a > i {
	vertical-align: middle;
	margin-right: 5px;
}

header .sidebar-part2 nav.navbar.bootsnav ul li ul li a:hover {
	color: $primary
}

header .sidebar-part2 nav.navbar.bootsnav ul li ul li {
	margin: 0;
	padding: 0 30px;
	width: 100%;
	word-break: normal;
}

header .sidebar-part2 nav.navbar.bootsnav ul li ul li.dropdown {
	border-left: 0;
}

.sidebar-part2 .dropdown ul.second-level, .sidebar-part2 .dropdown ul.third-level {
	border-left: 1px solid #eeeeee !important;
}

.sidebar-part2 .dropdown ul.second-level {
	left: 399px;
	transition: 0s;
	-webkit-transition: 0s;
	-moz-transition: 0s;
	-ms-transition: 0s;
	-o-transition: 0s;
}

.sidebar-part2 .dropdown ul.third-level {
	left: 249px;
	overflow-y: auto;
}

.sidebar-part2 nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
	box-shadow: none;
	height: 100%;
	position: absolute;
	transition: 0s;
	-webkit-transition: 0s;
	-moz-transition: 0s;
	-ms-transition: 0s;
	-o-transition: 0s;
	width: 250px;
	padding: 180px 0;
	background: $white;
}

.bottom-menu-icon a {
	font-size: 24px;
	width: 23px;
	height: 18px;
	display: inline-block;
	position: relative;
}

header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active > a, header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active ul li.active > a {
	color: $primary
}

.nav-icon span {
	display: block;
	position: absolute;
	height: 2px;
	width: 23px;
	background: #232323;
	border-radius: 0;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.nav-icon:hover span, .nav-icon.active span {
	background-color: $primary;
}

.nav-icon span:nth-child(1) {
	top: 0px;
}

.nav-icon span:nth-child(2), .nav-icon span:nth-child(3) {
	top: 8px;
}

.nav-icon span:nth-child(4) {
	top: 16px;
}

.nav-icon.active span:nth-child(1) {
	top: 8px;
	width: 0%;
	left: 50%;
}

.nav-icon.active span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.nav-icon.active span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.nav-icon.active span:nth-child(4) {
	top: 8px;
	width: 0%;
	left: 50%;
}

nav.navbar.brand-center ul.nav > li > a {
	padding: 26px;
	margin: 0;
}

header.sticky nav.navbar.brand-center ul.nav > li > a {
	padding: 20px;
	margin: 0 20px;
}

/* navigation widget */
.menu-wrap .widget {
	display: inline-block;
	width: 100%
}

