/* ===================================
    Typography
====================================== */

//
// Font family
//
.text-font-base {
	font-family: $font-base;
}

.text-font-sec {
	font-family: $font-sec;
	letter-spacing: 0.052em;
}

/* heading */
h1, h2, h3, h4, h5, h6 {
	margin: 0 0 25px;
	padding: 0;
	letter-spacing: 0;
	font-weight: 400;
}

h1 {
	font-size: 70px;
	line-height: 70px;
}

h2 {
	font-size: 55px;
	line-height: 60px
}

h3 {
	font-size: 48px;
	line-height: 54px
}

h4 {
	font-size: 40px;
	line-height: 46px
}

h5 {
	font-size: 32px;
	line-height: 40px
}

h6 {
	font-size: 25px;
	line-height: 30px
}

/* text size */
.text-extra-small {
	font-size: 11px;
	line-height: 14px
}

.text-small {
	font-size: 12px;
	line-height: 20px
}

.text-medium:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.portfolio-icon):not(.percent) {
	font-size: 16px;
	line-height: 23px
}

h1, h2, h3, h4, h5, h6 {
	a.text-medium {
		font-size: inherit !important;
		line-height: inherit !important;
	}
}

.text-med {

}

.text-large {
	font-size: 18px;
	line-height: 26px
}

.text-extra-large {
	font-size: 20px;
	line-height: 26px
}

.title-large {
	font-size: 100px;
	line-height: 95px
}

.title-extra-large {
	font-size: 130px;
	line-height: 120px
}

/* text color */
.text-white {
	color: $white
}

.text-black {
	color: $black
}

.text-extra-dark-gray {
	color: #232323
}

.text-dark-gray {
	color: #626262
}

.text-extra-medium-gray {
	color: $gray-800
}

.text-medium-gray {
	color: $gray-700
}

.text-extra-light-gray {
	color: #b7b7b7
}

.text-light-gray {
	color: #d6d5d5
}

.text-very-light-gray {
	color: $gray-500
}

.text-secondary {
	color: $primary;
}

/* dropcap */
.first-letter {
	float: left;
	font-size: 50px;
	line-height: 1.2;
	margin: 0 20px 0 0;
	text-align: center;
	padding: 10px 0;
	font-weight: 600
}

.first-letter-big {
	float: left;
	font-size: 110px;
	line-height: 110px;
	margin: 0 20px 0 0;
	padding: 0 8px;
	text-align: center;
	font-weight: 600;
	position: relative;
}

.first-letter-big:before {
	position: absolute;
	border-bottom: 1px solid;
	content: "";
	display: block;
	width: 100%;
	top: 55%;
	left: 0
}

.first-letter-block {
	font-size: 30px;
	height: 55px;
	line-height: 22px;
	padding: 15px 0;
	width: 55px;
	font-weight: 500;
	margin-top: 5px
}

.first-letter-block-round {
	border-radius: 6px;
	border: 1px solid;
}

.first-letter-round {
	border-radius: 50%;
	font-size: 35px;
	padding: 15px;
	width: 65px;
	height: 65px;
	line-height: 32px;
}

/* blockquote */
blockquote {
	padding: 20px 30px
}

blockquote p {
	font-size: 18px;
	line-height: 30px;
	margin-bottom: 17px !important;
	font-weight: 300
}

blockquote {
	border-left: 2px solid;
	padding: 15px 40px;
	margin: 45px 0
}

blockquote footer {
	color: $gray-700;
}

.blog-image blockquote {
	padding: 60px;
	border: 0;
	margin: 0;
}

.blog-image blockquote h6:before {
	content: "\e67f";
	font-family: 'themify';
	font-size: 40px;
	top: 5px;
	position: relative;
	margin-right: 12px;
	color: $primary
}

/* text link hover color */
a.text-link-white, a.text-link-white i {
	color: $white
}

a.text-link-white:hover, a.text-link-white:hover i, a.text-link-white:focus, a.text-link-white:focus i {
	color: $primary
}

a.text-link-black, a.text-link-black i {
	color: $black
}

a.text-link-dark-gray {
	color: $gray-700
}

a.text-link-dark-gray:hover, .text-link-dark-gray:focus {
	color: #232323
}

a.text-link-extra-dark-gray {
	color: #232323;
	
}

a.text-link-extra-dark-gray:hover, a.text-link-extra-dark-gray:focus {
	color: $primary;
}

a.text-link-secondary, a.text-link-secondary i {
	color: $primary
}

a.text-link-secondary:hover, a.text-link-secondary:hover i, a.text-link-secondary:focus, a.text-link-secondary:focus i {
	color: $white
}

/* hover color */
a.text-white-hover:hover, a.text-white-hover:focus {
	color: $white
}

a.text-black-hover:hover, a.text-black-hover:focus {
	color: $black
}

a.text-secondary-hover:hover, a.text-secondary-hover:focus {
	color: $primary
	i {
		color: inherit;
	}
}

a.text-decoration-underline-hover {
	&:hover,
	&:focus {
		color: inherit;
		text-decoration: underline;
	}
}

a.text-extra-dark-gray-hover:hover {
	color: #232323
}

a.text-dark-gray-hover:hover {
	color: #626262
}

a.text-extra-medium-gray-hover:hover {
	color: $gray-800
}

a.text-medium-gray-hover:hover {
	color: $gray-700
}

a.text-extra-light-gray-hover:hover {
	color: #b7b7b7
}

a.text-light-gray-hover:hover {
	color: #d6d5d5
}

a.text-very-light-gray-hover:hover {
	color: $gray-500
}

/* letter spacing */
.no-letter-spacing {
	letter-spacing: 0;
}

.letter-spacing-1 {
	letter-spacing: 1px
}

.letter-spacing-2 {
	letter-spacing: 2px
}

.letter-spacing-3 {
	letter-spacing: 3px
}

.letter-spacing-4 {
	letter-spacing: 4px
}

.letter-spacing-5 {
	letter-spacing: 5px
}

.letter-spacing-6 {
	letter-spacing: 6px
}

.letter-spacing-7 {
	letter-spacing: 7px
}

.letter-spacing-8 {
	letter-spacing: 8px
}

.letter-spacing-9 {
	letter-spacing: 9px
}

.letter-spacing-10 {
	letter-spacing: 10px
}

.letter-spacing-minus-1 {
	letter-spacing: -1px
}

.letter-spacing-minus-2 {
	letter-spacing: -2px
}

.letter-spacing-minus-3 {
	letter-spacing: -3px
}

.letter-spacing-minus-4 {
	letter-spacing: -4px
}

.letter-spacing-minus-5 {
	letter-spacing: -5px
}

.letter-spacing-minus-6 {
	letter-spacing: -6px
}

.letter-spacing-minus-7 {
	letter-spacing: -7px
}

.letter-spacing-minus-8 {
	letter-spacing: -8px
}

.letter-spacing-minus-9 {
	letter-spacing: -9px
}

.letter-spacing-minus-10 {
	letter-spacing: -10px
}

/* font weight */
.text-thin {
	font-weight: 100
}

.text-extra-light {
	font-weight: 200
}

.text-light {
	font-weight: 300
}

.text-regular {
	font-weight: 400
}

.text-medium {
	font-weight: 500
}

.text-semi-bold {
	font-weight: 600
}

.text-bold {
	font-weight: 700
}

.text-extra-bold {
	font-weight: 900
}

.text-300 {
	font-weight: 300 !important;
}

.text-500 {
	font-weight: 500 !important;
}

/* text property */
.text-transform-unset {
	text-transform: unset
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-decoration-line-through {
	text-decoration: line-through;
}

.text-line-height-unset {
	line-height: unset
}

.text-line-height-normal {
	line-height: normal
}

.text-line-height-none {
	line-height: 0
}

.word-wrap {
	word-wrap: break-word
}

.text-nowrap {
	white-space: nowrap;
}

.text-transform-none {
	text-transform: none
}

.title-line-through {
	padding: 0 15px
}

.text-middle-line {
	position: relative;
	white-space: nowrap
}

.text-outside-line {
	position: relative
}

.text-outside-line::before {
	border-top: 1px solid;
	content: "";
	height: 0;
	left: auto;
	position: absolute;
	right: -60px;
	top: 50%;
	width: 40px;
	opacity: .4;
}

.text-outside-line::after {
	border-top: 1px solid;
	content: "";
	height: 0;
	left: auto;
	position: absolute;
	left: -60px;
	top: 50%;
	width: 40px;
	opacity: .4;
}

.text-leftside-line {
	position: relative;
	padding-left: 35px
}

.text-leftside-line:before {
	border-top: 1px solid;
	content: "";
	height: 0;
	left: auto;
	position: absolute;
	left: 0;
	top: 45%;
	width: 25px;
	opacity: .7
}

.text-outside-line-full {
	position: relative;
	display: inline-block
}

.text-outside-line-full::before {
	content: "";
	position: absolute;
	top: 50%;
	height: 1px;
	width: 99%;
	display: block;
	border-bottom: 1px solid;
	right: 100%;
	margin-right: 25px;
	opacity: .15
}

.text-outside-line-full::after {
	content: "";
	position: absolute;
	top: 50%;
	height: 1px;
	width: 99%;
	display: block;
	border-bottom: 1px solid;
	left: 100%;
	margin-left: 25px;
	opacity: .15
}

.text-bottom-line {
	width: 1px;
	border-top: 30px solid;
	margin-left: auto;
	margin-right: auto;
}

.text-middle-line-secondary:before {
	border-bottom: 1px solid $primary;
	position: absolute;
	content: "";
	width: 100%;
	margin-top: 5px;
	margin-left: -20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.text-decoration-line-through-black, .text-decoration-line-through-secondary {
	position: relative;
	&:hover {
		&:before {
			width: 0;
		}
	}
}

.text-decoration-line-through-secondary:before {
	background: $primary;
}

.text-decoration-line-through-black:before {
	background: $black;
}

.text-decoration-line-through-black::before,
.text-decoration-line-through-secondary:before {
	content: "";
	height: 1px;
	left: -10px;
	position: absolute;
	top: 53%;
	width: calc(100% + 20px);
	-webkit-transform: translateY(-53%);
	-moz-transform: translateY(-53%);
	-ms-transform: translateY(-53%);
	-o-transform: translateY(-53%);
	transform: translateY(-53%);
	transition: all .3s;
	
}

a {
	transition: .3s ease-out all;
}




.d-lg-none {
	@media (min-width: 1200px) {
		display: none;
	}
}