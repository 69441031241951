/*==============================
    Margin
================================*/

.offset-half-all { margin: .5% }
.offset-one-all { margin: 1% }
.offset-one-half-all { margin: 1.5% }
.offset-two-all { margin: 2% }
.offset-two-half-all { margin: 2.5% }
.offset-three-all { margin: 3% }
.offset-three-half-all { margin: 3.5% }
.offset-four-all { margin: 4% }
.offset-four-half-all { margin: 4.5% }
.offset-five-all { margin: 5% }
.offset-five-half-all { margin: 5.5% }
.offset-six-all { margin: 6% }
.offset-six-half-all { margin: 6.5% }
.offset-seven-all { margin: 7% }
.offset-seven-half-all { margin: 7.5% }
.offset-eight-all { margin: 8% }
.offset-eight-half-all { margin: 8.5% }
.offset-nine-all { margin: 9% }
.offset-nine-half-all { margin: 9.5% }
.offset-ten-all { margin: 10% }
.offset-ten-half-all { margin: 10.5% }
.offset-eleven-all { margin: 11% }
.offset-eleven-half-all { margin: 11.5% }
.offset-twelve-all { margin: 12% }
.offset-twelve-half-all { margin: 12.5% }
.offset-thirteen-all { margin: 13% }
.offset-thirteen-half-all { margin: 13.5% }
.offset-fourteen-all { margin: 14% }
.offset-fourteen-half-all { margin: 14.5% }
.offset-fifteen-all { margin: 15% }
.offset-fifteen-half-all { margin: 15.5% }
.offset-sixteen-all { margin: 16% }
.offset-sixteen-half-all { margin: 16.5% }
.offset-seventeen-all { margin: 17% }
.offset-seventeen-half-all { margin: 17.5% }
.offset-eighteen-all { margin: 18% }
.offset-eighteen-half-all { margin: 18.5% }
.offset-nineteen-all { margin: 19% }
.offset-nineteen-half-all { margin: 19.5% }
.offset-twenty-all { margin: 20% }
.offset-twenty-half-all { margin: 20.5% }
.offset-twenty-one-all { margin: 21% }
.offset-twenty-one-half-all { margin: 21.5% }
.offset-twenty-two-all { margin: 22% }
.offset-twenty-two-half-all { margin: 22.5% }
.offset-twenty-three-all { margin: 23% }
.offset-twenty-three-half-all { margin: 23.5% }
.offset-twenty-four-all { margin: 24% }
.offset-twenty-four-half-all { margin: 24.5% }
.offset-twenty-five-all { margin: 25% }

// Offset All
@include offset-all(5, 120);



.no-margin { margin: 0 !important }

.no-offset-lr { margin-left: 0 !important; margin-right: 0 !important }

.no-offset-tb { margin-top: 0 !important; margin-bottom: 0 !important }

.no-offset-top { margin-top: 0 !important }

.no-offset-bottom { margin-bottom: 0 !important }

.no-offset-left { margin-left: 0 !important }

.no-offset-right { margin-right: 0 !important }

.offset-lr-auto { margin-left: auto !important; margin-right: auto !important }
.offset-auto { margin: 0 auto !important; }
/* margin top */
.offset-one-top { margin-top: 1% }
.offset-one-half-top { margin-top: 1.5% }
.offset-two-top { margin-top: 2% }
.offset-two-half-top { margin-top: 2.5% }
.offset-three-top { margin-top: 3% }
.offset-three-half-top { margin-top: 3.5% }
.offset-four-top { margin-top: 4% }
.offset-four-half-top { margin-top: 4.5% }
.offset-five-top { margin-top: 5% }
.offset-five-half-top { margin-top: 5.5% }
.offset-six-top { margin-top: 6% }
.offset-six-half-top { margin-top: 6.5% }
.offset-seven-top { margin-top: 7% }
.offset-seven-half-top { margin-top: 7.5% }
.offset-eight-top { margin-top: 8% }
.offset-eight-half-top { margin-top: 8.5% }
.offset-nine-top { margin-top: 9% }
.offset-nine-half-top { margin-top: 9.5% }
.offset-ten-top { margin-top: 10% }
.offset-ten-half-top { margin-top: 10.5% }
.offset-eleven-top { margin-top: 11% }
.offset-eleven-half-top { margin-top: 11.5% }
.offset-twelve-top { margin-top: 12% }
.offset-twelve-half-top { margin-top: 12.5% }
.offset-thirteen-top { margin-top: 13% }
.offset-thirteen-half-top { margin-top: 13.5% }
.offset-fourteen-top { margin-top: 14% }
.offset-fourteen-half-top { margin-top: 14.5% }
.offset-fifteen-top { margin-top: 15% }
.offset-fifteen-half-top { margin-top: 15.5% }
.offset-sixteen-top { margin-top: 16% }
.offset-sixteen-half-top { margin-top: 16.5% }
.offset-seventeen-top { margin-top: 17% }
.offset-seventeen-half-top { margin-top: 17.5% }
.offset-eighteen-top { margin-top: 18% }
.offset-eighteen-half-top { margin-top: 18.5% }
.offset-nineteen-top { margin-top: 19% }
.offset-nineteen-half-top { margin-top: 19.5% }
.offset-twenty-top { margin-top: 20% }
.offset-twenty-half-top { margin-top: 20.5% }
.offset-twenty-one-top { margin-top: 21% }
.offset-twenty-one-half-top { margin-top: 21.5% }
.offset-twenty-two-top { margin-top: 22% }
.offset-twenty-two-half-top { margin-top: 22.5% }
.offset-twenty-three-top { margin-top: 23% }
.offset-twenty-three-half-top { margin-top: 23.5% }
.offset-twenty-four-top { margin-top: 24% }
.offset-twenty-four-half-top { margin-top: 24.5% }
.offset-twenty-five-top { margin-top: 25% }
.offset-5px-top { margin-top: 5px }
.offset-10px-top { margin-top: 10px }
.offset-15px-top { margin-top: 15px }
.offset-20px-top { margin-top: 20px }
.offset-25px-top { margin-top: 25px }
.offset-30px-top { margin-top: 30px }
.offset-35px-top { margin-top: 35px }
.offset-40px-top { margin-top: 40px }
.offset-45px-top { margin-top: 45px }
.offset-50px-top { margin-top: 50px }
.offset-55px-top { margin-top: 55px }
.offset-60px-top { margin-top: 60px }
.offset-65px-top { margin-top: 65px }
.offset-70px-top { margin-top: 70px }
.offset-75px-top { margin-top: 75px }
.offset-80px-top { margin-top: 80px }
.offset-90px-top { margin-top: 90px }
.offset-100px-top { margin-top: 100px }

/* margin bottom */
.offset-one-bottom { margin-bottom: 1% }

.offset-one-half-bottom { margin-bottom: 1.5% }
.offset-two-bottom { margin-bottom: 2% }
.offset-two-half-bottom { margin-bottom: 2.5% }
.offset-three-bottom { margin-bottom: 3% }
.offset-three-half-bottom { margin-bottom: 3.5% }
.offset-four-bottom { margin-bottom: 4% }
.offset-four-half-bottom { margin-bottom: 4.5% }
.offset-five-bottom { margin-bottom: 5% }
.offset-five-half-bottom { margin-bottom: 5.5% }
.offset-six-bottom { margin-bottom: 6% }
.offset-six-half-bottom { margin-bottom: 6.5% }
.offset-seven-bottom { margin-bottom: 7% }
.offset-seven-half-bottom { margin-bottom: 7.5% }
.offset-eight-bottom { margin-bottom: 8% }
.offset-eight-half-bottom { margin-bottom: 8.5% }
.offset-nine-bottom { margin-bottom: 9% }
.offset-nine-half-bottom { margin-bottom: 9.5% }
.offset-ten-bottom { margin-bottom: 10% }
.offset-ten-half-bottom { margin-bottom: 10.5% }
.offset-eleven-bottom { margin-bottom: 11% }
.offset-eleven-half-bottom { margin-bottom: 11.5% }
.offset-twelve-bottom { margin-bottom: 12% }
.offset-twelve-half-bottom { margin-bottom: 12.5% }
.offset-thirteen-bottom { margin-bottom: 13% }
.offset-thirteen-half-bottom { margin-bottom: 13.5% }
.offset-fourteen-bottom { margin-bottom: 14% }
.offset-fourteen-half-bottom { margin-bottom: 14.5% }
.offset-fifteen-bottom { margin-bottom: 15% }
.offset-fifteen-half-bottom { margin-bottom: 15.5% }
.offset-sixteen-bottom { margin-bottom: 16% }
.offset-sixteen-half-bottom { margin-bottom: 16.5% }
.offset-seventeen-bottom { margin-bottom: 17% }
.offset-seventeen-half-bottom { margin-bottom: 17.5% }
.offset-eighteen-bottom { margin-bottom: 18% }
.offset-eighteen-half-bottom { margin-bottom: 18.5% }
.offset-nineteen-bottom { margin-bottom: 19% }
.offset-nineteen-half-bottom { margin-bottom: 19.5% }
.offset-twenty-bottom { margin-bottom: 20% }
.offset-twenty-half-bottom { margin-bottom: 20.5% }
.offset-twenty-one-bottom { margin-bottom: 21% }
.offset-twenty-one-half-bottom { margin-bottom: 21.5% }
.offset-twenty-two-bottom { margin-bottom: 22% }
.offset-twenty-two-half-bottom { margin-bottom: 22.5% }
.offset-twenty-three-bottom { margin-bottom: 23% }
.offset-twenty-three-half-bottom { margin-bottom: 23.5% }
.offset-twenty-four-bottom { margin-bottom: 24% }
.offset-twenty-four-half-bottom { margin-bottom: 24.5% }
.offset-twenty-five-bottom { margin-bottom: 25% }
.offset-5px-bottom { margin-bottom: 5px }
.offset-10px-bottom { margin-bottom: 10px }
.offset-15px-bottom { margin-bottom: 15px }
.offset-20px-bottom { margin-bottom: 20px }
.offset-25px-bottom { margin-bottom: 25px }
.offset-30px-bottom { margin-bottom: 30px }
.offset-35px-bottom { margin-bottom: 35px }
.offset-40px-bottom { margin-bottom: 40px }
.offset-45px-bottom { margin-bottom: 45px }
.offset-50px-bottom { margin-bottom: 50px }
.offset-55px-bottom { margin-bottom: 55px }
.offset-60px-bottom { margin-bottom: 60px }
.offset-65px-bottom { margin-bottom: 65px }
.offset-70px-bottom { margin-bottom: 70px }
.offset-75px-bottom { margin-bottom: 75px }
.offset-80px-bottom { margin-bottom: 80px }
.offset-85px-bottom { margin-bottom: 85px }
.offset-90px-bottom { margin-bottom: 90px }
.offset-95px-bottom { margin-bottom: 95px }
.offset-100px-bottom { margin-bottom: 100px }
/* margin right */
.offset-one-right { margin-right: 1% }

.offset-one-half-right { margin-right: 1.5% }
.offset-two-right { margin-right: 2% }
.offset-two-half-right { margin-right: 2.5% }
.offset-three-right { margin-right: 3% }
.offset-three-half-right { margin-right: 3.5% }
.offset-four-right { margin-right: 4% }
.offset-four-half-right { margin-right: 4.5% }
.offset-five-right { margin-right: 5% }
.offset-five-half-right { margin-right: 5.5% }
.offset-six-right { margin-right: 6% }
.offset-six-half-right { margin-right: 6.5% }
.offset-seven-right { margin-right: 7% }
.offset-seven-half-right { margin-right: 7.5% }
.offset-eight-right { margin-right: 8% }
.offset-eight-half-right { margin-right: 8.5% }
.offset-nine-right { margin-right: 9% }
.offset-nine-half-right { margin-right: 9.5% }
.offset-ten-right { margin-right: 10% }
.offset-ten-half-right { margin-right: 10.5% }
.offset-eleven-right { margin-right: 11% }
.offset-eleven-half-right { margin-right: 11.5% }
.offset-twelve-right { margin-right: 12% }
.offset-twelve-half-right { margin-right: 12.5% }
.offset-thirteen-right { margin-right: 13% }
.offset-thirteen-half-right { margin-right: 13.5% }
.offset-fourteen-right { margin-right: 14% }
.offset-fourteen-half-right { margin-right: 14.5% }
.offset-fifteen-right { margin-right: 15% }
.offset-fifteen-half-right { margin-right: 15.5% }
.offset-sixteen-right { margin-right: 16% }
.offset-sixteen-half-right { margin-right: 16.5% }
.offset-seventeen-right { margin-right: 17% }
.offset-seventeen-half-right { margin-right: 17.5% }
.offset-eighteen-right { margin-right: 18% }
.offset-eighteen-half-right { margin-right: 18.5% }
.offset-nineteen-right { margin-right: 19% }
.offset-nineteen-half-right { margin-right: 19.5% }
.offset-twenty-right { margin-right: 20% }
.offset-twenty-half-right { margin-right: 20.5% }
.offset-twenty-right { margin-right: 21% }
.offset-twenty-one-half-right { margin-right: 21.5% }
.offset-twenty-two-right { margin-right: 22% }
.offset-twenty-two-half-right { margin-right: 22.5% }
.offset-twenty-three-right { margin-right: 23% }
.offset-twenty-three-half-right { margin-right: 23.5% }
.offset-twenty-four-right { margin-right: 24% }
.offset-twenty-four-half-right { margin-right: 24.5% }
.offset-twenty-five-right { margin-right: 25% }
.offset-5px-right { margin-right: 5px }
.offset-10px-right { margin-right: 10px }
.offset-15px-right { margin-right: 15px }
.offset-20px-right { margin-right: 20px }
.offset-25px-right { margin-right: 25px }
.offset-30px-right { margin-right: 30px }
.offset-35px-right { margin-right: 35px }
.offset-40px-right { margin-right: 40px }
.offset-45px-right { margin-right: 45px }
.offset-50px-right { margin-right: 50px }
.offset-55px-right { margin-right: 55px }
.offset-60px-right { margin-right: 60px }
.offset-65px-right { margin-right: 65px }
.offset-70px-right { margin-right: 70px }
.offset-75px-right { margin-right: 75px }
.offset-80px-right { margin-right: 80px }
.offset-85px-right { margin-right: 85px }
.offset-90px-right { margin-right: 90px }
.offset-95px-right { margin-right: 95px }
.offset-100px-right { margin-right: 100px }
/* margin left */
.offset-one-left { margin-left: 1% }

.offset-one-half-left { margin-left: 1.5% }
.offset-two-left { margin-left: 2% }
.offset-two-half-left { margin-left: 2.5% }
.offset-three-left { margin-left: 3% }
.offset-three-half-left { margin-left: 3.5% }
.offset-four-left { margin-left: 4% }
.offset-four-half-left { margin-left: 4.5% }
.offset-five-left { margin-left: 5% }
.offset-five-half-left { margin-left: 5.5% }
.offset-six-left { margin-left: 6% }
.offset-six-half-left { margin-left: 6.5% }
.offset-seven-left { margin-left: 7% }
.offset-seven-half-left { margin-left: 7.5% }
.offset-eight-left { margin-left: 8% }
.offset-eight-half-left { margin-left: 8.5% }
.offset-nine-left { margin-left: 9% }
.offset-nine-half-left { margin-left: 9.5% }
.offset-ten-left { margin-left: 10% }
.offset-ten-half-left { margin-left: 10.5% }
.offset-eleven-left { margin-left: 11% }
.offset-eleven-half-left { margin-left: 11.5% }
.offset-twelve-left { margin-left: 12% }
.offset-twelve-half-left { margin-left: 12.5% }
.offset-thirteen-left { margin-left: 13% }
.offset-thirteen-half-left { margin-left: 13.5% }
.offset-fourteen-left { margin-left: 14% }
.offset-fourteen-half-left { margin-left: 14.5% }
.offset-fifteen-left { margin-left: 15% }
.offset-fifteen-half-left { margin-left: 15.5% }
.offset-sixteen-left { margin-left: 16% }
.offset-sixteen-half-left { margin-left: 16.5% }
.offset-seventeen-left { margin-left: 17% }
.offset-seventeen-half-left { margin-left: 17.5% }
.offset-eighteen-left { margin-left: 18% }
.offset-eighteen-half-left { margin-left: 18.5% }
.offset-nineteen-left { margin-left: 19% }
.offset-nineteen-half-left { margin-left: 19.5% }
.offset-twenty-left { margin-left: 20% }
.offset-twenty-half-left { margin-left: 20.5% }
.offset-twenty-one-left { margin-left: 21% }
.offset-twenty-one-half-left { margin-left: 21.5% }
.offset-twenty-two-left { margin-left: 22% }
.offset-twenty-two-half-left { margin-left: 22.5% }
.offset-twenty-three-left { margin-left: 23% }
.offset-twenty-three-half-left { margin-left: 23.5% }
.offset-twenty-four-left { margin-left: 24% }
.offset-twenty-four-half-left { margin-left: 24.5% }
.offset-twenty-five-left { margin-left: 25% }
.offset-5px-left { margin-left: 5px }
.offset-10px-left { margin-left: 10px }
.offset-15px-left { margin-left: 15px }
.offset-20px-left { margin-left: 20px }
.offset-25px-left { margin-left: 25px }
.offset-30px-left { margin-left: 30px }
.offset-35px-left { margin-left: 35px }
.offset-40px-left { margin-left: 40px }
.offset-45px-left { margin-left: 45px }
.offset-50px-left { margin-left: 50px }
.offset-55px-left { margin-left: 55px }
.offset-60px-left { margin-left: 60px }
.offset-65px-left { margin-left: 65px }
.offset-70px-left { margin-left: 70px }
.offset-75px-left { margin-left: 75px }
.offset-80px-left { margin-left: 80px }
.offset-85px-left { margin-left: 85px }
.offset-90px-left { margin-left: 90px }
.offset-95px-left { margin-left: 95px }
.offset-100px-left { margin-left: 100px }
/* margin left and right */
.offset-one-lr { margin-left: 1%; margin-right: 1%; }

.offset-one-half-lr { margin-left: 1.5%; margin-right: 1.5%; }
.offset-two-lr { margin-left: 2%; margin-right: 2%; }
.offset-two-half-lr { margin-left: 2.5%; margin-right: 2.5%; }
.offset-three-lr { margin-left: 3%; margin-right: 3%; }
.offset-three-half-lr { margin-left: 3.5%; margin-right: 3.5%; }
.offset-four-lr { margin-left: 4%; margin-right: 4%; }
.offset-four-half-lr { margin-left: 4.5%; margin-right: 4.5%; }
.offset-five-lr { margin-left: 5%; margin-right: 5%; }
.offset-five-half-lr { margin-left: 5.5%; margin-right: 5.5%; }
.offset-six-lr { margin-left: 6%; margin-right: 6%; }
.offset-six-half-lr { margin-left: 6.5%; margin-right: 6.5%; }
.offset-seven-lr { margin-left: 7%; margin-right: 7%; }
.offset-seven-half-lr { margin-left: 7.5%; margin-right: 7.5%; }
.offset-eight-lr { margin-left: 8%; margin-right: 8%; }
.offset-eight-half-lr { margin-left: 8.5%; margin-right: 8.5%; }
.offset-nine-lr { margin-left: 9%; margin-right: 9%; }
.offset-nine-half-lr { margin-left: 9.5%; margin-right: 9.5%; }
.offset-ten-lr { margin-left: 10%; margin-right: 10%; }
.offset-ten-half-lr { margin-left: 10.5%; margin-right: 10.5%; }
.offset-eleven-lr { margin-left: 11%; margin-right: 11%; }
.offset-eleven-half-lr { margin-left: 11.5%; margin-right: 11.5%; }
.offset-twelve-lr { margin-left: 12%; margin-right: 12%; }
.offset-twelve-half-lr { margin-left: 12.5%; margin-right: 12.5%; }
.offset-thirteen-lr { margin-left: 13%; margin-right: 13%; }
.offset-thirteen-half-lr { margin-left: 13.5%; margin-right: 13.5%; }
.offset-fourteen-lr { margin-left: 14%; margin-right: 14%; }
.offset-fourteen-half-lr { margin-left: 14.5%; margin-right: 14.5%; }
.offset-fifteen-lr { margin-left: 15%; margin-right: 15%; }
.offset-fifteen-half-lr { margin-left: 15.5%; margin-right: 15.5%; }
.offset-sixteen-lr { margin-left: 16%; margin-right: 16%; }
.offset-sixteen-half-lr { margin-left: 16.5%; margin-right: 16.5%; }
.offset-seventeen-lr { margin-left: 17%; margin-right: 17%; }
.offset-seventeen-half-lr { margin-left: 17.5%; margin-right: 17.5%; }
.offset-eighteen-lr { margin-left: 18%; margin-right: 18%; }
.offset-eighteen-half-lr { margin-left: 18.5%; margin-right: 18.5%; }
.offset-nineteen-lr { margin-left: 19%; margin-right: 19%; }
.offset-nineteen-half-lr { margin-left: 19.5%; margin-right: 19.5%; }
.offset-twenty-lr { margin-left: 20%; margin-right: 20%; }
.offset-twenty-half-lr { margin-left: 20.5%; margin-right: 20.5%; }
.offset-twenty-one-lr { margin-left: 21%; margin-right: 21%; }
.offset-twenty-one-half-lr { margin-left: 21.5%; margin-right: 21.5%; }
.offset-twenty-two-lr { margin-left: 22%; margin-right: 22%; }
.offset-twenty-two-half-lr { margin-left: 22.5%; margin-right: 22.5%; }
.offset-twenty-three-lr { margin-left: 23%; margin-right: 23%; }
.offset-twenty-three-half-lr { margin-left: 23.5%; margin-right: 23.5%; }
.offset-twenty-four-lr { margin-left: 24%; margin-right: 24%; }
.offset-twenty-four-half-lr { margin-left: 24.5%; margin-right: 24.5%; }
.offset-twenty-five-lr { margin-left: 25%; margin-right: 25%; }
.offset-5px-lr { margin-left: 5px; margin-right: 5px; }
.offset-10px-lr { margin-left: 10px; margin-right: 10px; }
.offset-15px-lr { margin-left: 15px; margin-right: 15px; }
.offset-20px-lr { margin-left: 20px; margin-right: 20px; }
.offset-25px-lr { margin-left: 25px; margin-right: 25px; }
.offset-30px-lr { margin-left: 30px; margin-right: 30px; }
.offset-35px-lr { margin-left: 35px; margin-right: 35px; }
.offset-40px-lr { margin-left: 40px; margin-right: 40px; }
.offset-45px-lr { margin-left: 45px; margin-right: 45px; }
.offset-50px-lr { margin-left: 50px; margin-right: 50px; }
.offset-55px-lr { margin-left: 55px; margin-right: 55px; }
.offset-60px-lr { margin-left: 60px; margin-right: 60px; }
.offset-65px-lr { margin-left: 65px; margin-right: 65px; }
.offset-70px-lr { margin-left: 70px; margin-right: 70px; }
.offset-75px-lr { margin-left: 75px; margin-right: 75px; }
.offset-80px-lr { margin-left: 80px; margin-right: 80px; }
.offset-85px-lr { margin-left: 85px; margin-right: 85px; }
.offset-90px-lr { margin-left: 90px; margin-right: 90px; }
.offset-95px-lr { margin-left: 95px; margin-right: 95px; }
.offset-100px-lr { margin-left: 100px; margin-right: 100px; }

/* margin top and bottom */
.offset-one-tb { margin-top: 1%; margin-bottom: 1%; }
.offset-one-half-tb { margin-top: 1.5%; margin-bottom: 1.5%; }
.offset-two-tb { margin-top: 2%; margin-bottom: 2%; }
.offset-two-half-tb { margin-top: 2.5%; margin-bottom: 2.5%; }
.offset-three-tb { margin-top: 3%; margin-bottom: 3%; }
.offset-three-half-tb { margin-top: 3.5%; margin-bottom: 3.5%; }
.offset-four-tb { margin-top: 4%; margin-bottom: 4%; }
.offset-four-half-tb { margin-top: 4.5%; margin-bottom: 4.5%; }
.offset-five-tb { margin-top: 5%; margin-bottom: 5%; }
.offset-five-half-tb { margin-top: 5.5%; margin-bottom: 5.5%; }
.offset-six-tb { margin-top: 6%; margin-bottom: 6%; }
.offset-six-half-tb { margin-top: 6.5%; margin-bottom: 6.5%; }
.offset-seven-tb { margin-top: 7%; margin-bottom: 7%; }
.offset-seven-half-tb { margin-top: 7.5%; margin-bottom: 7.5%; }
.offset-eight-tb { margin-top: 8%; margin-bottom: 8%; }
.offset-eight-half-tb { margin-top: 8.5%; margin-bottom: 8.5%; }
.offset-nine-tb { margin-top: 9%; margin-bottom: 9%; }
.offset-nine-half-tb { margin-top: 9.5%; margin-bottom: 9.5%; }
.offset-ten-tb { margin-top: 10%; margin-bottom: 10%; }
.offset-ten-half-tb { margin-top: 10.5%; margin-bottom: 10.5%; }
.offset-eleven-tb { margin-top: 11%; margin-bottom: 11%; }
.offset-eleven-half-tb { margin-top: 11.5%; margin-bottom: 11.5%; }
.offset-twelve-tb { margin-top: 12%; margin-bottom: 12%; }
.offset-twelve-half-tb { margin-top: 12.5%; margin-bottom: 12.5%; }
.offset-thirteen-tb { margin-top: 13%; margin-bottom: 13%; }
.offset-thirteen-half-tb { margin-top: 13.5%; margin-bottom: 13.5%; }
.offset-fourteen-tb { margin-top: 14%; margin-bottom: 14%; }
.offset-fourteen-half-tb { margin-top: 14.5%; margin-bottom: 14.5%; }
.offset-fifteen-tb { margin-top: 15%; margin-bottom: 15%; }
.offset-fifteen-half-tb { margin-top: 15.5%; margin-bottom: 15.5%; }
.offset-sixteen-tb { margin-top: 16%; margin-bottom: 16%; }
.offset-sixteen-half-tb { margin-top: 16.5%; margin-bottom: 16.5%; }
.offset-seventeen-tb { margin-top: 17%; margin-bottom: 17%; }
.offset-seventeen-half-tb { margin-top: 17.5%; margin-bottom: 17.5%; }
.offset-eighteen-tb { margin-top: 18%; margin-bottom: 18%; }
.offset-eighteen-half-tb { margin-top: 18.5%; margin-bottom: 18.5%; }
.offset-nineteen-tb { margin-top: 19%; margin-bottom: 19%; }
.offset-nineteen-half-tb { margin-top: 19.5%; margin-bottom: 19.5%; }
.offset-twenty-tb { margin-top: 20%; margin-bottom: 20%; }
.offset-twenty-half-tb { margin-top: 20.5%; margin-bottom: 20.5%; }
.offset-twenty-one-tb { margin-top: 21%; margin-bottom: 21%; }
.offset-twenty-one-half-tb { margin-top: 21.5%; margin-bottom: 21.5%; }
.offset-twenty-two-tb { margin-top: 22%; margin-bottom: 22%; }
.offset-twenty-two-half-tb { margin-top: 22.5%; margin-bottom: 22.5%; }
.offset-twenty-three-tb { margin-top: 23%; margin-bottom: 23%; }
.offset-twenty-three-half-tb { margin-top: 23.5%; margin-bottom: 23.5%; }
.offset-twenty-four-tb { margin-top: 24%; margin-bottom: 24%; }
.offset-twenty-four-half-tb { margin-top: 24.5%; margin-bottom: 24.5%; }
.offset-twenty-five-tb { margin-top: 25%; margin-bottom: 25%; }
.offset-5px-tb { margin-top: 5px; margin-bottom: 5px; }
.offset-10px-tb { margin-top: 10px; margin-bottom: 10px; }
.offset-15px-tb { margin-top: 15px; margin-bottom: 15px; }
.offset-20px-tb { margin-top: 20px; margin-bottom: 20px; }
.offset-25px-tb { margin-top: 25px; margin-bottom: 25px; }
.offset-30px-tb { margin-top: 30px; margin-bottom: 30px; }
.offset-35px-tb { margin-top: 35px; margin-bottom: 35px; }
.offset-40px-tb { margin-top: 40px; margin-bottom: 40px; }
.offset-45px-tb { margin-top: 45px; margin-bottom: 45px; }
.offset-50px-tb { margin-top: 50px; margin-bottom: 50px; }
.offset-55px-tb { margin-top: 55px; margin-bottom: 55px; }
.offset-60px-tb { margin-top: 60px; margin-bottom: 60px; }
.offset-65px-tb { margin-top: 65px; margin-bottom: 65px; }
.offset-70px-tb { margin-top: 70px; margin-bottom: 70px; }
.offset-75px-tb { margin-top: 75px; margin-bottom: 75px; }
.offset-80px-tb { margin-top: 80px; margin-bottom: 80px; }
.offset-85px-tb { margin-top: 85px; margin-bottom: 85px; }
.offset-90px-tb { margin-top: 90px; margin-bottom: 90px; }
.offset-95px-tb { margin-top: 95px; margin-bottom: 95px; }
.offset-100px-tb { margin-top: 100px; margin-bottom: 100px; }
/*===============================
    Padding
=================================*/

.no-inset { padding: 0 !important }
.no-inset-lr { padding-left: 0 !important; padding-right: 0 !important }
.no-inset-tb { padding-top: 0 !important; padding-bottom: 0 !important }
.no-inset-top { padding-top: 0 !important }
.no-inset-bottom { padding-bottom: 0 !important }
.no-inset-left { padding-left: 0 !important }
.no-inset-right { padding-right: 0 !important }
.inset-one-all { padding: 1%; }
.inset-one-half-all { padding: 1.5%; }
.inset-two-all { padding: 2%; }
.inset-two-half-all { padding: 2.5%; }
.inset-three-all { padding: 3%; }
.inset-three-half-all { padding: 3.5%; }
.inset-four-all { padding: 4%; }
.inset-four-half-all { padding: 4.5%; }
.inset-five-all { padding: 5%; }
.inset-five-half-all { padding: 5.5%; }
.inset-six-all { padding: 6%; }
.inset-six-half-all { padding: 6.5%; }
.inset-seven-all { padding: 7%; }
.inset-seven-half-all { padding: 7.5%; }
.inset-eight-all { padding: 8%; }
.inset-eight-half-all { padding: 8.5%; }
.inset-nine-all { padding: 9%; }
.inset-nine-half-all { padding: 9.5%; }
.inset-ten-all { padding: 10%; }
.inset-ten-half-all { padding: 10.5%; }
.inset-eleven-all { padding: 11%; }
.inset-eleven-half-all { padding: 11.5%; }
.inset-twelve-all { padding: 12%; }
.inset-twelve-half-all { padding: 12.5%; }
.inset-thirteen-all { padding: 13%; }
.inset-thirteen-half-all { padding: 13.5%; }
.inset-fourteen-all { padding: 14%; }
.inset-fourteen-half-all { padding: 14.5%; }
.inset-fifteen-all { padding: 15%; }
.inset-fifteen-half-all { padding: 15.5%; }
.inset-sixteen-all { padding: 16%; }
.inset-sixteen-half-all { padding: 16.5%; }
.inset-seventeen-all { padding: 17%; }
.inset-seventeen-half-all { padding: 17.5%; }
.inset-eighteen-all { padding: 18%; }
.inset-eighteen-half-all { padding: 18.5%; }
.inset-nineteen-all { padding: 19%; }
.inset-nineteen-half-all { padding: 19.5%; }
.inset-twenty-all { padding: 20%; }
.inset-twenty-half-all { padding: 20.5%; }
.inset-twenty-one-all { padding: 21%; }
.inset-twenty-one-half-all { padding: 21.5%; }
.inset-twenty-two-all { padding: 22%; }
.inset-twenty-two-half-all { padding: 22.5%; }
.inset-twenty-three-all { padding: 23%; }
.inset-twenty-three-half-all { padding: 23.5%; }
.inset-twenty-four-all { padding: 24%; }
.inset-twenty-four-half-all { padding: 24.5%; }
.inset-twenty-five-all { padding: 25%; }
.inset-twenty-five-half-all { padding: 25.5%; }
.inset-5px-all { padding: 5px; }
.inset-10px-all { padding: 10px; }
.inset-15px-all { padding: 15px; }
.inset-20px-all { padding: 20px; }
.inset-25px-all { padding: 25px; }
.inset-30px-all { padding: 30px; }
.inset-35px-all { padding: 35px; }
.inset-40px-all { padding: 40px; }
.inset-45px-all { padding: 45px; }
.inset-50px-all { padding: 50px; }
.inset-55px-all { padding: 55px; }
.inset-60px-all { padding: 60px; }
.inset-65px-all { padding: 65px; }
.inset-70px-all { padding: 70px; }
.inset-75px-all { padding: 75px; }
.inset-80px-all { padding: 80px; }
.inset-85px-all { padding: 85px; }
.inset-90px-all { padding: 90px; }
.inset-95px-all { padding: 95px; }
.inset-100px-all { padding: 100px; }
/* padding top */
.inset-one-top { padding-top: 1%; }
.inset-one-half-top { padding-top: 1.5%; }
.inset-two-top { padding-top: 2%; }
.inset-two-half-top { padding-top: 2.5%; }
.inset-three-top { padding-top: 3%; }
.inset-three-half-top { padding-top: 3.5%; }
.inset-four-top { padding-top: 4%; }
.inset-four-half-top { padding-top: 4.5%; }
.inset-five-top { padding-top: 5%; }
.inset-five-half-top { padding-top: 5.5%; }
.inset-six-top { padding-top: 6%; }
.inset-six-half-top { padding-top: 6.5%; }
.inset-seven-top { padding-top: 7%; }
.inset-seven-half-top { padding-top: 7.5%; }
.inset-eight-top { padding-top: 8%; }
.inset-eight-half-top { padding-top: 8.5%; }
.inset-nine-top { padding-top: 9%; }
.inset-nine-half-top { padding-top: 9.5%; }
.inset-ten-top { padding-top: 10%; }
.inset-ten-half-top { padding-top: 10.5%; }
.inset-eleven-top { padding-top: 11%; }
.inset-eleven-half-top { padding-top: 11.5%; }
.inset-twelve-top { padding-top: 12%; }
.inset-twelve-half-top { padding-top: 12.5%; }
.inset-thirteen-top { padding-top: 13%; }
.inset-thirteen-half-top { padding-top: 13.5%; }
.inset-fourteen-top { padding-top: 14%; }
.inset-fourteen-half-top { padding-top: 14.5%; }
.inset-fifteen-top { padding-top: 15%; }
.inset-fifteen-half-top { padding-top: 15.5%; }
.inset-sixteen-top { padding-top: 16%; }
.inset-sixteen-half-top { padding-top: 16.5%; }
.inset-seventeen-top { padding-top: 17%; }
.inset-seventeen-half-top { padding-top: 17.5%; }
.inset-eighteen-top { padding-top: 18%; }
.inset-eighteen-half-top { padding-top: 18.5%; }
.inset-nineteen-top { padding-top: 19%; }
.inset-nineteen-half-top { padding-top: 19.5%; }
.inset-twenty-top { padding-top: 20%; }
.inset-twenty-half-top { padding-top: 20.5%; }
.inset-twenty-one-top { padding-top: 21%; }
.inset-twenty-one-half-top { padding-top: 21.5%; }
.inset-twenty-two-top { padding-top: 22%; }
.inset-twenty-two-half-top { padding-top: 22.5%; }
.inset-twenty-two-top { padding-top: 22%; }
.inset-twenty-two-half-top { padding-top: 22.5%; }
.inset-twenty-three-top { padding-top: 23%; }
.inset-twenty-three-half-top { padding-top: 23.5%; }
.inset-twenty-four-top { padding-top: 24%; }
.inset-twenty-four-half-top { padding-top: 24.5%; }
.inset-twenty-five-top { padding-top: 25%; }
.inset-5px-top { padding-top: 5px; }
.inset-10px-top { padding-top: 10px; }
.inset-15px-top { padding-top: 15px; }
.inset-20px-top { padding-top: 20px; }
.inset-25px-top { padding-top: 25px; }
.inset-30px-top { padding-top: 30px; }
.inset-35px-top { padding-top: 35px; }
.inset-40px-top { padding-top: 40px; }
.inset-45px-top { padding-top: 45px; }
.inset-50px-top { padding-top: 50px; }
.inset-55px-top { padding-top: 55px; }
.inset-60px-top { padding-top: 60px; }
.inset-65px-top { padding-top: 65px; }
.inset-70px-top { padding-top: 70px; }
.inset-75px-top { padding-top: 75px; }
.inset-80px-top { padding-top: 80px; }
.inset-85px-top { padding-top: 85px; }
.inset-90px-top { padding-top: 90px; }
.inset-95px-top { padding-top: 95px; }
.inset-100px-top { padding-top: 100px; }
/* padding bottom */
.inset-one-bottom { padding-bottom: 1%; }
.inset-one-half-bottom { padding-bottom: 1.5%; }
.inset-two-bottom { padding-bottom: 2%; }
.inset-two-half-bottom { padding-bottom: 2.5%; }
.inset-three-bottom { padding-bottom: 3%; }
.inset-three-half-bottom { padding-bottom: 3.5%; }
.inset-four-bottom { padding-bottom: 4%; }
.inset-four-half-bottom { padding-bottom: 4.5%; }
.inset-five-bottom { padding-bottom: 5%; }
.inset-five-half-bottom { padding-bottom: 5.5%; }
.inset-six-bottom { padding-bottom: 6%; }
.inset-six-half-bottom { padding-bottom: 6.5%; }
.inset-seven-bottom { padding-bottom: 7%; }
.inset-seven-half-bottom { padding-bottom: 7.5%; }
.inset-eight-bottom { padding-bottom: 8%; }
.inset-eight-half-bottom { padding-bottom: 8.5%; }
.inset-nine-bottom { padding-bottom: 9%; }
.inset-nine-half-bottom { padding-bottom: 9.5%; }
.inset-ten-bottom { padding-bottom: 10%; }
.inset-ten-half-bottom { padding-bottom: 10.5%; }
.inset-eleven-bottom { padding-bottom: 11%; }
.inset-eleven-half-bottom { padding-bottom: 11.5%; }
.inset-twelve-bottom { padding-bottom: 12%; }
.inset-twelve-half-bottom { padding-bottom: 12.5%; }
.inset-thirteen-bottom { padding-bottom: 13%; }
.inset-thirteen-half-bottom { padding-bottom: 13.5%; }
.inset-fourteen-bottom { padding-bottom: 14%; }
.inset-fourteen-half-bottom { padding-bottom: 14.5%; }
.inset-fifteen-bottom { padding-bottom: 15%; }
.inset-fifteen-half-bottom { padding-bottom: 15.5%; }
.inset-sixteen-bottom { padding-bottom: 16%; }
.inset-sixteen-half-bottom { padding-bottom: 16.5%; }
.inset-seventeen-bottom { padding-bottom: 17%; }
.inset-seventeen-half-bottom { padding-bottom: 17.5%; }
.inset-eighteen-bottom { padding-bottom: 18%; }
.inset-eighteen-half-bottom { padding-bottom: 18.5%; }
.inset-nineteen-bottom { padding-bottom: 19%; }
.inset-nineteen-half-bottom { padding-bottom: 19.5%; }
.inset-twenty-bottom { padding-bottom: 20%; }
.inset-twenty-half-bottom { padding-bottom: 20.5%; }
.inset-twenty-one-bottom { padding-bottom: 21%; }
.inset-twenty-one-half-bottom { padding-bottom: 21.5%; }
.inset-twenty-two-bottom { padding-bottom: 22%; }
.inset-twenty-two-half-bottom { padding-bottom: 22.5%; }
.inset-twenty-three-bottom { padding-bottom: 23%; }
.inset-twenty-three-half-bottom { padding-bottom: 23.5%; }
.inset-twenty-four-bottom { padding-bottom: 24%; }
.inset-twenty-four-half-bottom { padding-bottom: 24.5%; }
.inset-twenty-five-bottom { padding-bottom: 25%; }
.inset-5px-bottom { padding-bottom: 5px; }
.inset-10px-bottom { padding-bottom: 10px; }
.inset-15px-bottom { padding-bottom: 15px; }
.inset-20px-bottom { padding-bottom: 20px; }
.inset-25px-bottom { padding-bottom: 25px; }
.inset-30px-bottom { padding-bottom: 30px; }
.inset-35px-bottom { padding-bottom: 35px; }
.inset-40px-bottom { padding-bottom: 40px; }
.inset-45px-bottom { padding-bottom: 45px; }
.inset-50px-bottom { padding-bottom: 50px; }
.inset-55px-bottom { padding-bottom: 55px; }
.inset-60px-bottom { padding-bottom: 60px; }
.inset-65px-bottom { padding-bottom: 65px; }
.inset-70px-bottom { padding-bottom: 70px; }
.inset-75px-bottom { padding-bottom: 75px; }
.inset-80px-bottom { padding-bottom: 80px; }
.inset-85px-bottom { padding-bottom: 85px; }
.inset-90px-bottom { padding-bottom: 90px; }
.inset-95px-bottom { padding-bottom: 95px; }
.inset-100px-bottom { padding-bottom: 100px; }
/* padding right */
.inset-one-right { padding-right: 1%; }
.inset-one-half-right { padding-right: 1.5%; }
.inset-two-right { padding-right: 2%; }
.inset-two-half-right { padding-right: 2.5%; }
.inset-three-right { padding-right: 3%; }
.inset-three-half-right { padding-right: 3.5%; }
.inset-four-right { padding-right: 4%; }
.inset-four-half-right { padding-right: 4.5%; }
.inset-five-right { padding-right: 5%; }
.inset-five-half-right { padding-right: 5.5%; }
.inset-six-right { padding-right: 6%; }
.inset-six-half-right { padding-right: 6.5%; }
.inset-seven-right { padding-right: 7%; }
.inset-seven-half-right { padding-right: 7.5%; }
.inset-eight-right { padding-right: 8%; }
.inset-eight-half-right { padding-right: 8.5%; }
.inset-nine-right { padding-right: 9%; }
.inset-nine-half-right { padding-right: 9.5%; }
.inset-ten-right { padding-right: 10%; }
.inset-ten-half-right { padding-right: 10.5%; }
.inset-eleven-right { padding-right: 11%; }
.inset-eleven-half-right { padding-right: 11.5%; }
.inset-twelve-right { padding-right: 12%; }
.inset-twelve-half-right { padding-right: 12.5%; }
.inset-thirteen-right { padding-right: 13%; }
.inset-thirteen-half-right { padding-right: 13.5%; }
.inset-fourteen-right { padding-right: 14%; }
.inset-fourteen-half-right { padding-right: 14.5%; }
.inset-fifteen-right { padding-right: 15%; }
.inset-fifteen-half-right { padding-right: 15.5%; }
.inset-sixteen-right { padding-right: 16%; }
.inset-sixteen-half-right { padding-right: 16.5%; }
.inset-seventeen-right { padding-right: 17%; }
.inset-seventeen-half-right { padding-right: 17.5%; }
.inset-eighteen-right { padding-right: 18%; }
.inset-eighteen-half-right { padding-right: 18.5%; }
.inset-nineteen-right { padding-right: 19%; }
.inset-nineteen-half-right { padding-right: 19.5%; }
.inset-twenty-right { padding-right: 20%; }
.inset-twenty-half-right { padding-right: 20.5%; }
.inset-twenty-one-right { padding-right: 21%; }
.inset-twenty-one-half-right { padding-right: 21.5%; }
.inset-twenty-two-right { padding-right: 22%; }
.inset-twenty-two-half-right { padding-right: 22.5%; }
.inset-twenty-three-right { padding-right: 23%; }
.inset-twenty-three-half-right { padding-right: 23.5%; }
.inset-twenty-four-right { padding-right: 24%; }
.inset-twenty-four-half-right { padding-right: 24.5%; }
.inset-twenty-five-right { padding-right: 25%; }
.inset-5px-right { padding-right: 5px; }
.inset-10px-right { padding-right: 10px; }
.inset-15px-right { padding-right: 15px; }
.inset-20px-right { padding-right: 20px; }
.inset-25px-right { padding-right: 25px; }
.inset-30px-right { padding-right: 30px; }
.inset-35px-right { padding-right: 35px; }
.inset-40px-right { padding-right: 40px; }
.inset-45px-right { padding-right: 45px; }
.inset-50px-right { padding-right: 50px; }
.inset-55px-right { padding-right: 55px; }
.inset-60px-right { padding-right: 60px; }
.inset-65px-right { padding-right: 65px; }
.inset-70px-right { padding-right: 70px; }
.inset-75px-right { padding-right: 75px; }
.inset-80px-right { padding-right: 80px; }
.inset-85px-right { padding-right: 85px; }
.inset-90px-right { padding-right: 90px; }
.inset-95px-right { padding-right: 95px; }
.inset-100px-right { padding-right: 100px; }
/* padding left */
.inset-one-left { padding-left: 1%; }
.inset-one-half-left { padding-left: 1.5%; }
.inset-two-left { padding-left: 2%; }
.inset-two-half-left { padding-left: 2.5%; }
.inset-three-left { padding-left: 3%; }
.inset-three-half-left { padding-left: 3.5%; }
.inset-four-left { padding-left: 4%; }
.inset-four-half-left { padding-left: 4.5%; }
.inset-five-left { padding-left: 5%; }
.inset-five-half-left { padding-left: 5.5%; }
.inset-six-left { padding-left: 6%; }
.inset-six-half-left { padding-left: 6.5%; }
.inset-seven-left { padding-left: 7%; }
.inset-seven-half-left { padding-left: 7.5%; }
.inset-eight-left { padding-left: 8%; }
.inset-eight-half-left { padding-left: 8.5%; }
.inset-nine-left { padding-left: 9%; }
.inset-nine-half-left { padding-left: 9.5%; }
.inset-ten-left { padding-left: 10%; }
.inset-ten-half-left { padding-left: 10.5%; }
.inset-eleven-left { padding-left: 11%; }
.inset-eleven-half-left { padding-left: 11.5%; }
.inset-twelve-left { padding-left: 12%; }
.inset-twelve-half-left { padding-left: 12.5%; }
.inset-thirteen-left { padding-left: 13%; }
.inset-thirteen-half-left { padding-left: 13.5%; }
.inset-fourteen-left { padding-left: 14%; }
.inset-fourteen-half-left { padding-left: 14.5%; }
.inset-fifteen-left { padding-left: 15%; }
.inset-fifteen-half-left { padding-left: 15.5%; }
.inset-sixteen-left { padding-left: 16%; }
.inset-sixteen-half-left { padding-left: 16.5%; }
.inset-seventeen-left { padding-left: 17%; }
.inset-seventeen-half-left { padding-left: 17.5%; }
.inset-eighteen-left { padding-left: 18%; }
.inset-eighteen-half-left { padding-left: 18.5%; }
.inset-nineteen-left { padding-left: 19%; }
.inset-nineteen-half-left { padding-left: 19.5%; }
.inset-twenty-left { padding-left: 20%; }
.inset-twenty-half-left { padding-left: 20.5%; }
.inset-twenty-one-left { padding-left: 21%; }
.inset-twenty-one-half-left { padding-left: 21.5%; }
.inset-twenty-two-left { padding-left: 22%; }
.inset-twenty-two-half-left { padding-left: 22.5%; }
.inset-twenty-three-left { padding-left: 23%; }
.inset-twenty-three-half-left { padding-left: 23.5%; }
.inset-twenty-four-left { padding-left: 24%; }
.inset-twenty-four-half-left { padding-left: 24.5%; }
.inset-twenty-five-left { padding-left: 25%; }
.inset-5px-left { padding-left: 5px; }
.inset-10px-left { padding-left: 10px; }
.inset-15px-left { padding-left: 15px; }
.inset-20px-left { padding-left: 20px; }
.inset-25px-left { padding-left: 25px; }
.inset-30px-left { padding-left: 30px; }
.inset-35px-left { padding-left: 35px; }
.inset-40px-left { padding-left: 40px; }
.inset-45px-left { padding-left: 45px; }
.inset-50px-left { padding-left: 50px; }
.inset-55px-left { padding-left: 55px; }
.inset-60px-left { padding-left: 60px; }
.inset-65px-left { padding-left: 65px; }
.inset-70px-left { padding-left: 70px; }
.inset-75px-left { padding-left: 75px; }
.inset-80px-left { padding-left: 80px; }
.inset-85px-left { padding-left: 85px; }
.inset-90px-left { padding-left: 90px; }
.inset-95px-left { padding-left: 95px; }
.inset-100px-left { padding-left: 100px; }
/* padding top and bottom */
.inset-one-tb { padding-top: 1%; padding-bottom: 1%; }
.inset-one-half-tb { padding-top: 1.5%; padding-bottom: 1.5%; }
.inset-two-tb { padding-top: 2%; padding-bottom: 2%; }
.inset-two-half-tb { padding-top: 2.5%; padding-bottom: 2.5%; }
.inset-three-tb { padding-top: 3%; padding-bottom: 3%; }
.inset-three-half-tb { padding-top: 3.5%; padding-bottom: 3.5%; }
.inset-four-tb { padding-top: 4%; padding-bottom: 4%; }
.inset-four-half-tb { padding-top: 4.5%; padding-bottom: 4.5%; }
.inset-five-tb { padding-top: 5%; padding-bottom: 5%; }
.inset-five-half-tb { padding-top: 5.5%; padding-bottom: 5.5%; }
.inset-six-tb { padding-top: 6%; padding-bottom: 6%; }
.inset-six-half-tb { padding-top: 6.5%; padding-bottom: 6.5%; }
.inset-seven-tb { padding-top: 7%; padding-bottom: 7%; }
.inset-seven-half-tb { padding-top: 7.5%; padding-bottom: 7.5%; }
.inset-eight-tb { padding-top: 8%; padding-bottom: 8%; }
.inset-eight-half-tb { padding-top: 8.5%; padding-bottom: 8.5%; }
.inset-nine-tb { padding-top: 9%; padding-bottom: 9%; }
.inset-nine-half-tb { padding-top: 9.5%; padding-bottom: 9.5%; }
.inset-ten-tb { padding-top: 10%; padding-bottom: 10%; }
.inset-ten-half-tb { padding-top: 10.5%; padding-bottom: 10.5%; }
.inset-eleven-tb { padding-top: 11%; padding-bottom: 11%; }
.inset-eleven-half-tb { padding-top: 11.5%; padding-bottom: 11.5%; }
.inset-twelve-tb { padding-top: 12%; padding-bottom: 12%; }
.inset-twelve-half-tb { padding-top: 12.5%; padding-bottom: 12.5%; }
.inset-thirteen-tb { padding-top: 13%; padding-bottom: 13%; }
.inset-thirteen-half-tb { padding-top: 13.5%; padding-bottom: 13.5%; }
.inset-fourteen-tb { padding-top: 14%; padding-bottom: 14%; }
.inset-fourteen-half-tb { padding-top: 14.5%; padding-bottom: 14.5%; }
.inset-fifteen-tb { padding-top: 15%; padding-bottom: 15%; }
.inset-fifteen-half-tb { padding-top: 15.5%; padding-bottom: 15.5%; }
.inset-sixteen-tb { padding-top: 16%; padding-bottom: 16%; }
.inset-sixteen-half-tb { padding-top: 16.5%; padding-bottom: 16.5%; }
.inset-seventeen-tb { padding-top: 17%; padding-bottom: 17%; }
.inset-seventeen-half-tb { padding-top: 17.5%; padding-bottom: 17.5%; }
.inset-eighteen-tb { padding-top: 18%; padding-bottom: 18%; }
.inset-eighteen-half-tb { padding-top: 18.5%; padding-bottom: 18.5%; }
.inset-nineteen-tb { padding-top: 19%; padding-bottom: 19%; }
.inset-nineteen-half-tb { padding-top: 19.5%; padding-bottom: 19.5%; }
.inset-twenty-tb { padding-top: 20%; padding-bottom: 20%; }
.inset-twenty-half-tb { padding-top: 20.5%; padding-bottom: 20%; }
.inset-twenty-one-tb { padding-top: 21%; padding-bottom: 21%; }
.inset-twenty-one-half-tb { padding-top: 21.5%; padding-bottom: 21.5%; }
.inset-twenty-two-tb { padding-top: 22%; padding-bottom: 22%; }
.inset-twenty-two-half-tb { padding-top: 22.5%; padding-bottom: 22.5%; }
.inset-twenty-three-tb { padding-top: 23%; padding-bottom: 23%; }
.inset-twenty-three-half-tb { padding-top: 23.5%; padding-bottom: 23.5%; }
.inset-twenty-four-tb { padding-top: 24%; padding-bottom: 24%; }
.inset-twenty-four-half-tb { padding-top: 24.5%; padding-bottom: 24.5%; }
.inset-twenty-five-tb { padding-top: 25%; padding-bottom: 25%; }
.inset-thirty-tb { padding-top: 30%; padding-bottom: 30%; }
.inset-5px-tb { padding-top: 5px; padding-bottom: 5px; }
.inset-10px-tb { padding-top: 10px; padding-bottom: 10px; }
.inset-15px-tb { padding-top: 15px; padding-bottom: 15px; }
.inset-20px-tb { padding-top: 20px; padding-bottom: 20px; }
.inset-25px-tb { padding-top: 25px; padding-bottom: 25px; }
.inset-30px-tb { padding-top: 30px; padding-bottom: 30px; }
.inset-35px-tb { padding-top: 35px; padding-bottom: 35px; }
.inset-40px-tb { padding-top: 40px; padding-bottom: 40px; }
.inset-45px-tb { padding-top: 45px; padding-bottom: 45px; }
.inset-50px-tb { padding-top: 50px; padding-bottom: 50px; }
.inset-55px-tb { padding-top: 55px; padding-bottom: 55px; }
.inset-60px-tb { padding-top: 60px; padding-bottom: 60px; }
.inset-65px-tb { padding-top: 65px; padding-bottom: 65px; }
.inset-70px-tb { padding-top: 70px; padding-bottom: 70px; }
.inset-75px-tb { padding-top: 75px; padding-bottom: 75px; }
.inset-80px-tb { padding-top: 80px; padding-bottom: 80px; }
.inset-85px-tb { padding-top: 85px; padding-bottom: 85px; }
.inset-90px-tb { padding-top: 90px; padding-bottom: 90px; }
.inset-95px-tb { padding-top: 95px; padding-bottom: 95px; }
.inset-100px-tb { padding-top: 100px; padding-bottom: 100px; }
/* padding left and right */
.inset-one-lr { padding-left: 1%; padding-right: 1%; }
.inset-one-half-lr { padding-left: 1.5%; padding-right: 1.5%; }
.inset-two-lr { padding-left: 2%; padding-right: 2%; }
.inset-two-half-lr { padding-left: 2.5%; padding-right: 2.5%; }
.inset-three-lr { padding-left: 3%; padding-right: 3%; }
.inset-three-half-lr { padding-left: 3.5%; padding-right: 3.5%; }
.inset-four-lr { padding-left: 4%; padding-right: 4%; }
.inset-four-half-lr { padding-left: 4.5%; padding-right: 4.5%; }
.inset-five-lr { padding-left: 5%; padding-right: 5%; }
.inset-five-half-lr { padding-left: 5.5%; padding-right: 5.5%; }
.inset-six-lr { padding-left: 6%; padding-right: 6%; }
.inset-six-half-lr { padding-left: 6.5%; padding-right: 6.5%; }
.inset-seven-lr { padding-left: 7%; padding-right: 7%; }
.inset-seven-half-lr { padding-left: 7.5%; padding-right: 7.5%; }
.inset-eight-lr { padding-left: 8%; padding-right: 8%; }
.inset-eight-half-lr { padding-left: 8.5%; padding-right: 8.5%; }
.inset-nine-lr { padding-left: 9%; padding-right: 9%; }
.inset-nine-half-lr { padding-left: 9.5%; padding-right: 9.5%; }
.inset-ten-lr { padding-left: 10%; padding-right: 10%; }
.inset-ten-half-lr { padding-left: 10.5%; padding-right: 10.5%; }
.inset-eleven-lr { padding-left: 11%; padding-right: 11%; }
.inset-eleven-half-lr { padding-left: 11.5%; padding-right: 11.5%; }
.inset-twelve-lr { padding-left: 12%; padding-right: 12%; }
.inset-twelve-half-lr { padding-left: 12.5%; padding-right: 12.5%; }
.inset-thirteen-lr { padding-left: 13%; padding-right: 13%; }
.inset-thirteen-half-lr { padding-left: 13.5%; padding-right: 13.5%; }
.inset-fourteen-lr { padding-left: 14%; padding-right: 14%; }
.inset-fourteen-half-lr { padding-left: 14.5%; padding-right: 14.5%; }
.inset-fifteen-lr { padding-left: 15%; padding-right: 15%; }
.inset-fifteen-half-lr { padding-left: 15.5%; padding-right: 15.5%; }
.inset-sixteen-lr { padding-left: 16%; padding-right: 16%; }
.inset-sixteen-half-lr { padding-left: 16.5%; padding-right: 16.5%; }
.inset-seventeen-lr { padding-left: 17%; padding-right: 17%; }
.inset-seventeen-half-lr { padding-left: 17.5%; padding-right: 17.5%; }
.inset-eighteen-lr { padding-left: 18%; padding-right: 18%; }
.inset-eighteen-half-lr { padding-left: 18.5%; padding-right: 18.5%; }
.inset-nineteen-lr { padding-left: 19%; padding-right: 19%; }
.inset-nineteen-half-lr { padding-left: 19.5%; padding-right: 19.5%; }
.inset-twenty-lr { padding-left: 20%; padding-right: 20%; }
.inset-twenty-half-lr { padding-left: 20.5%; padding-right: 20.5%; }
.inset-twenty-one-lr { padding-left: 21%; padding-right: 21%; }
.inset-twenty-one-half-lr { padding-left: 21.5%; padding-right: 21.5%; }
.inset-twenty-two-lr { padding-left: 22%; padding-right: 22%; }
.inset-twenty-two-half-lr { padding-left: 22.5%; padding-right: 22.5%; }
.inset-twenty-three-lr { padding-left: 23%; padding-right: 23%; }
.inset-twenty-three-half-lr { padding-left: 23.5%; padding-right: 23.5%; }
.inset-twenty-four-lr { padding-left: 24%; padding-right: 24%; }
.inset-twenty-four-half-lr { padding-left: 24.5%; padding-right: 24.5%; }
.inset-twenty-five-lr { padding-left: 25%; padding-right: 25%; }
.inset-5px-lr { padding-left: 5px; padding-right: 5px; }
.inset-10px-lr { padding-left: 10px; padding-right: 10px; }
.inset-15px-lr { padding-left: 15px; padding-right: 15px; }
.inset-20px-lr { padding-left: 20px; padding-right: 20px; }
.inset-25px-lr { padding-left: 25px; padding-right: 25px; }
.inset-30px-lr { padding-left: 30px; padding-right: 30px; }
.inset-35px-lr { padding-left: 35px; padding-right: 35px; }
.inset-40px-lr { padding-left: 40px; padding-right: 40px; }
.inset-45px-lr { padding-left: 45px; padding-right: 45px; }
.inset-50px-lr { padding-left: 50px; padding-right: 50px; }
.inset-55px-lr { padding-left: 55px; padding-right: 55px; }
.inset-60px-lr { padding-left: 60px; padding-right: 60px; }
.inset-65px-lr { padding-left: 65px; padding-right: 65px; }
.inset-70px-lr { padding-left: 70px; padding-right: 70px; }
.inset-75px-lr { padding-left: 75px; padding-right: 75px; }
.inset-80px-lr { padding-left: 80px; padding-right: 80px; }
.inset-85px-lr { padding-left: 85px; padding-right: 85px; }
.inset-90px-lr { padding-left: 90px; padding-right: 90px; }
.inset-95px-lr { padding-left: 95px; padding-right: 95px; }
.inset-100px-lr { padding-left: 100px; padding-right: 100px; }

//custom offset
.offset-top-30 {
	margin-top: 30px;
}

.offset-top-34 {
	margin-top: 34px;
}

.offset-sm-top-20 {
	@media (min-width: 768px) {
		margin-top: 20px;
	}
}

.offset-xl-top-50 {
	@media (min-width: 1600px) {
		margin-top: 35px;
	}
}

// Row spacing
.row-10 {	@include grid-offset(10px); }
.row-20 {	@include grid-offset(20px); }
.row-30 {	@include grid-offset(30px); }