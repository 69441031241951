/* ===================================
    Reset
====================================== */

body {
	font-family: $font-base;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-size: 14px;
	color: #6f6f6f;
	font-weight: 400;
	line-height: 24px;
}

body, html {
	height: 100%;
	-moz-osx-font-smoothing: grayscale;
}

a, a:active, a:focus {
	color: #6f6f6f;
	text-decoration: none;
}

a:hover, a:active {
	color: $primary;
	text-decoration: none;
}

a:focus, a:active, button:focus, button:active, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
	outline: none;
}

img {
	max-width: 100%;
	height: auto;
}

video {
	background-size: cover;
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}

input, textarea, select {
	border: 1px solid #d1d1d1;
	font-size: 14px;
	padding: 8px 15px;
	width: 100%;
	margin: 0 0 20px 0;
	max-width: 100%;
	resize: none;
}

input[type="submit"] {
	width: auto
}

input[type="button"], input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea, input[type="submit"] {
	-webkit-appearance: none;
	outline: none;
}

input:focus, textarea:focus {
	border-color: #585858;
	outline: none;
}

input[type="button"]:focus {
	outline: none;
}

select::-ms-expand {
	display: none;
}

iframe {
	border: 0;
}

p {
	margin: 0 0 25px
}

b, strong {
	font-weight: 600;
}

.last-paragraph-no-margin p:last-of-type {
	margin-bottom: 0
}

.text-font-sec strong {
	font-weight: 600
}

ul, ol, dl {
	list-style-position: outside;
	margin-bottom: 25px
}

::selection {
	color: $black;
	background: $gray-200;
}

::-moz-selection {
	color: $black;
	background: $gray-200;
}

::-webkit-input-placeholder {
	color: #6f6f6f;
	text-overflow: ellipsis;
}

::-moz-placeholder {
	color: #6f6f6f;
	text-overflow: ellipsis;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #6f6f6f;
	text-overflow: ellipsis;
	opacity: 1;
}

a,
button {
	&:focus {
		outline: none !important;
	}
}

button::-moz-focus-inner {
	border: 0;
}

*:focus {
	outline: none;
}

blockquote {
	padding: 0;
	margin: 0;
}

input,
button,
select,
textarea {
	outline: none;
}

label {
	margin-bottom: 0;
}

p {
	// margin: 0;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: block;
	}
}

dl {
	margin: 0;
}

dt,
dd {
	line-height: inherit;
}

dt {
	font-weight: inherit;
}

dd {
	margin-bottom: 0;
}

cite {
	font-style: normal;
}

form {
	margin-bottom: 0;
}

blockquote {
	padding-left: 0;
	border-left: 0;
}

address {
	margin-top: 0;
	margin-bottom: 0;
}

figure {
	margin-bottom: 0;
}

html p a {
	&:hover {
		text-decoration: none;
	}
}