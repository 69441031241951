/* magnific popup - v1.1.0 */

.mfp-container *{transition-timing-function:none;-moz-transition-timing-function:none;-webkit-transition-timing-function:none;-o-transition-timing-function:none;transition-duration:0s;-moz-transition-duration:0s;-webkit-transition-duration:0s;-o-transition-duration:0s;}
.mfp-bg{top:0;left:0;width:100%;height:100%;z-index:1042;overflow:hidden;position:fixed;background:#0b0b0b;opacity:0.8;}
.mfp-wrap{top:0;left:0;width:100%;height:100%;z-index:1043;position:fixed;outline:none !important;-webkit-backface-visibility:hidden;}
.mfp-container{text-align:center;position:absolute;width:100%;height:100%;left:0;top:0;padding:0 8px;box-sizing:border-box;}
.mfp-container:before{content:'';display:inline-block;height:100%;vertical-align:middle;}
.mfp-align-top .mfp-container:before{display:none;}
.mfp-content{position:relative;display:inline-block;vertical-align:middle;margin:0 auto;text-align:left;z-index:1045;}
.mfp-inline-holder .mfp-content,.mfp-ajax-holder .mfp-content{width:100%;cursor:auto;
  margin-top: 44px;}
.mfp-ajax-cur{cursor:progress;}
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close{cursor:-moz-zoom-out;cursor:-webkit-zoom-out;cursor:zoom-out;}
.mfp-zoom{cursor:pointer;cursor:-webkit-zoom-in;cursor:-moz-zoom-in;cursor:zoom-in;}
.mfp-auto-cursor .mfp-content{cursor:auto;}
.mfp-close,.mfp-arrow,.mfp-preloader,.mfp-counter{-webkit-user-select:none;-moz-user-select:none;user-select:none;}
.mfp-loading.mfp-figure{display:none;}
.mfp-hide{display:none !important;}
.mfp-preloader{color:#CCC;position:absolute;top:50%;width:auto;text-align:center;margin-top:-0.8em;left:8px;right:8px;z-index:1044;}
.mfp-preloader a{color:#CCC;}
.mfp-preloader a:hover{color:$white;}
.mfp-s-ready .mfp-preloader{display:none;}
.mfp-s-error .mfp-content{display:none;}
button.mfp-close,button.mfp-arrow{overflow:visible;cursor:pointer;background:transparent;border:0;-webkit-appearance:none;display:block;outline:none;padding:0;z-index:1046;box-shadow:none;touch-action:manipulation;}
button::-moz-focus-inner{padding:0;border:0;}
.mfp-close{width:44px;height:44px;line-height:44px;position:absolute;right:0;top:0;text-decoration:none;text-align:center;opacity:0.65;padding:0 0 18px 10px;color:$white;font-style:normal;font-size:28px;font-family:Arial, Baskerville, monospace;}
.mfp-close:hover, .mfp-close:focus{opacity:1;}
.mfp-close:active{top:1px;}
.mfp-close-btn-in .mfp-close{color:#333;}
.mfp-image-holder .mfp-close,.mfp-iframe-holder .mfp-close{color:$white;right:-6px;text-align:right;padding-right:6px;width:100%;}
.mfp-counter{position:absolute;top:0;right:0;color:#CCC;font-size:12px;line-height:18px;white-space:nowrap;}
.mfp-arrow{position:absolute;opacity:0.65;margin:0;top:50%;margin-top:-55px;padding:0;width:90px;height:110px;-webkit-tap-highlight-color:transparent;}
.mfp-arrow:active{margin-top:-54px;}
.mfp-arrow:hover, .mfp-arrow:focus{opacity:1;}
.mfp-arrow:before, .mfp-arrow:after{content:'';display:block;width:0;height:0;position:absolute;left:0;top:0;margin-top:35px;margin-left:35px;border:medium inset transparent;}
.mfp-arrow:after{border-top-width:13px;border-bottom-width:13px;top:8px;}
.mfp-arrow:before{border-top-width:21px;border-bottom-width:21px;opacity:0.7;}
.mfp-arrow-left{left:0;}
.mfp-arrow-left:after{border-right:17px solid $white;margin-left:31px;}
.mfp-arrow-left:before{margin-left:25px;border-right:27px solid #3F3F3F;}
.mfp-arrow-right{right:0;}
.mfp-arrow-right:after{border-left:17px solid $white;margin-left:39px;}
.mfp-arrow-right:before{border-left:27px solid #3F3F3F;}
.mfp-iframe-holder{padding-top:40px;padding-bottom:40px;}
.mfp-iframe-holder .mfp-content{line-height:0;width:100%;max-width:900px;}
.mfp-iframe-holder .mfp-close{top:-40px;}
.mfp-iframe-scaler{width:100%;height:0;overflow:hidden;padding-top:56.25%;}
.mfp-iframe-scaler iframe{position:absolute;display:block;top:0;left:0;width:100%;height:100%;box-shadow:0 0 8px rgba($black, 0.6);background:$black;}
img.mfp-img{width:auto;max-width:100%;height:auto;display:block;line-height:0;box-sizing:border-box;padding:40px 0 40px;margin:0 auto;}
.mfp-figure{line-height:0;}
.mfp-figure:after{content:'';position:absolute;left:0;top:40px;bottom:40px;display:block;right:0;width:auto;height:auto;z-index:-1;box-shadow:0 0 8px rgba($black, 0.6);background:#444;}
.mfp-figure small{color:#BDBDBD;display:block;font-size:12px;line-height:14px;}
.mfp-figure figure{margin:0;}
.mfp-bottom-bar{margin-top:-36px;position:absolute;top:100%;left:0;width:100%;cursor:auto;}
.mfp-title{text-align:left;line-height:18px;color:#F3F3F3;word-wrap:break-word;padding-right:36px;}
.mfp-image-holder .mfp-content{max-width:100%;}
.mfp-gallery .mfp-image-holder .mfp-figure{cursor:pointer;}

@media screen and (max-width:800px) and (orientation:landscape), screen and (max-height:300px) {
  
  /* remove all paddings around the image on small screen */
  .mfp-img-mobile .mfp-image-holder{padding-left:0;padding-right:0;}
  .mfp-img-mobile img.mfp-img{padding:0;}
  .mfp-img-mobile .mfp-figure:after{top:0;bottom:0;}
  .mfp-img-mobile .mfp-figure small{display:inline;margin-left:5px;}
  .mfp-img-mobile .mfp-bottom-bar{background:rgba($black, 0.6);bottom:0;margin:0;top:auto;padding:3px 5px;position:fixed;box-sizing:border-box;}
  .mfp-img-mobile .mfp-bottom-bar:empty{padding:0;}
  .mfp-img-mobile .mfp-counter{right:5px;top:3px;}
  .mfp-img-mobile .mfp-close{top:0;right:0;width:35px;height:35px;line-height:35px;background:rgba($black, 0.6);position:fixed;text-align:center;padding:0;}
  
}

@media all and (max-width:900px){
  
  .mfp-arrow{-webkit-transform:scale(0.75);transform:scale(0.75);}
  .mfp-arrow-left{-webkit-transform-origin:0;transform-origin:0;}
  .mfp-arrow-right{-webkit-transform-origin:100%;transform-origin:100%;}
  .mfp-container{padding-left:6px;padding-right:6px;}
  
}

/* ===================================
    Magnific popup
====================================== */

.mfp-bg { background: $black; opacity: 0.93; }

.mfp-bg, .mfp-wrap { z-index: 10007 }

#popup-form { cursor: default; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close { color: $white; background: transparent }

.mfp-close, .mfp-close:active { opacity: 1; background-color: transparent;  right: 30px; top: 30px; }

.modal-popup-main .mfp-close, .modal-popup-main .mfp-close:active { right: 0; top: 0; }

.lightbox-gallery a { position: relative; display: block }

.lightbox-gallery .gallary-over-layer { position: absolute; top: 0; left: 0; }

.mfp-arrow-right:after, .mfp-arrow-left:after { display: none }

.mfp-arrow-right:before { border-left: none; content: "\e628"; font-size: 2em; font-family: 'themify'; color: $white; }

.mfp-arrow-left:before { border-right: none; content: "\e629"; font-size: 2em; font-family: 'themify'; color: $white; }

.mfp-bottom-bar { margin-top: -24px; font-size: 12px } 

.mfp-close { font-size: 36px; font-family: $font-sec;  font-weight: 400 }

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.mfp-figure { -webkit-animation-name: fadeIn; -moz-animation-name: fadeIn; -o-animation-name: fadeIn; -ms-animation-name: fadeIn; animation-name: fadeIn; -webkit-animation-duration: .4s; -moz-animation-duration: .4s; -ms-animation-duration: .4s; -o-animation-duration: .4s; animation-duration: .4s; -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; -ms-animation-fill-mode: both; -o-animation-fill-mode: both; animation-fill-mode: both; webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; }

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog { opacity: 0; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; -webkit-transform: scale(0.8); -moz-transform: scale(0.8); -ms-transform: scale(0.8); -o-transform: scale(0.8); transform: scale(0.8); }

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog { opacity: 1; -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); }

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog { -webkit-transform: scale(0.8); -moz-transform: scale(0.8); -ms-transform: scale(0.8); -o-transform: scale(0.8); transform: scale(0.8); opacity: 0; }

/* dark overlay, start state */
.my-mfp-zoom-in.mfp-bg { opacity: 0; -webkit-transition: opacity 0.3s ease-out; -moz-transition: opacity 0.3s ease-out; -o-transition: opacity 0.3s ease-out; -ms-transition: opacity 0.3s ease-out; transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg { opacity: 0.8; }

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg { opacity: 0; }

/* fade move animation for second dialog */
.my-mfp-slide-bottom .zoom-anim-dialog { opacity: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg); -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg); -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg); -o-transform: translateY(-20px) perspective(600px) rotateX(10deg); -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg); transform: translateY(-20px) perspective(600px) rotateX(10deg); }

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog { opacity: 1; -webkit-transform: translateY(0) perspective(600px) rotateX(0); -moz-transform: translateY(0) perspective(600px) rotateX(0); -ms-transform: translateY(0) perspective(600px) rotateX(0); -o-transform: translateY(0) perspective(600px) rotateX(0); transform: translateY(0) perspective(600px) rotateX(0); }

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog { opacity: 0; -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg); -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg); -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg); -o-transform: translateY(-10px) perspective(600px) rotateX(10deg); transform: translateY(-10px) perspective(600px) rotateX(10deg); }

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg { opacity: 0; -webkit-transition: opacity 0.3s ease-out; -moz-transition: opacity 0.3s ease-out; -o-transition: opacity 0.3s ease-out; -ms-transition: opacity 0.3s ease-out; transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg { opacity: 0.8; }

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg { opacity: 0; }

/* simple fade transition */
.mfp-fade.mfp-bg { opacity: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -ms-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }

.mfp-fade.mfp-bg.mfp-ready { opacity: 0.97; }

.mfp-fade.mfp-bg.mfp-removing { opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content { opacity: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -ms-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content { opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content { opacity: 0; }

/* grid */
.show-grid [class^="col-"] { background-color: #f5f5f5; border: 1px solid rgba($black, 0.2); margin-bottom: 15px; padding: 10px 0; text-align: center; }

/* message box */
.alert-dismissable .close, .alert-dismissible .close { top: 0 }