//----------------------------------------------------------
//                     Reset Button
//----------------------------------------------------------
// reset the button styles

@mixin reset-button{
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
  
  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}