.thumbnail-creative {
	position: relative;
	z-index: 1;
	display: block;
	padding-bottom: 100%;
	overflow: hidden;
	img {
		position: absolute;
		z-index: 1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 101%;
		height: 101%;
		max-width: none;
		max-height: none;
		object-fit: cover;
		object-position: center center;
		will-change: transfrom;
	}
}

.thumbnail-creative-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3;
	background-color: rgba($primary, .6);
	opacity: 0;
	transition: .3s ease-out all;
	.icon {
		z-index: 4;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $white;
		font-size: 25px;
		transition: .3s ease-out all;
	}
}

.thumbnail-creative:hover {
	.thumbnail-creative-overlay {
		opacity: 1;
	}
}

.row-narrow {
	margin-right: -5px;
	margin-left: -5px;
	
	> [class*="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	}
}

.popup-youtube {
	&:hover {
		opacity: .85;
	}
}