/* ===================================
    Background color
====================================== */

.bg-transparent {
	background-color: transparent;
}

.bg-white {
	background-color: $white;
}

.bg-black {
	background-color: $black;
}

.bg-extra-dark-gray {
	background-color: $gray-900;
}

.bg-dark-gray {
	background-color: $gray-800;
}

.bg-extra-medium-gray {
	background-color: $gray-700;
}

.bg-medium-gray {
	background-color: $gray-200;
}

.bg-extra-light-gray {
	background-color: $gray-600
}

.bg-medium-light-gray {
	background-color: $gray-500
}

.bg-light-gray {
	background-color: $gray-100
}

.bg-very-light-gray {
	background-color: $gray-300
}

.bg-secondary {
	background: $gradient;
}

.bg-transparent-white {
	background-color: rgba(255, 255, 255, 0.3);
	background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(37%, rgba(255, 255, 255, 0)), color-stop(96%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
	background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
	background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='$white', GradientType=1);
}

.bg-transparent-black {
	background-color: rgba($black, 0);
	background: -moz-linear-gradient(left, rgba($black, 0) 0%, rgba($black, 0) 37%, rgba($black, 1) 96%, rgba($black, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba($black, 0)), color-stop(37%, rgba($black, 0)), color-stop(96%, rgba($black, 1)), color-stop(100%, rgba($black, 1)));
	background: -webkit-linear-gradient(left, rgba($black, 0) 0%, rgba($black, 0) 37%, rgba($black, 1) 96%, rgba($black, 1) 100%);
	background: -o-linear-gradient(left, rgba($black, 0) 0%, rgba($black, 0) 37%, rgba($black, 1) 96%, rgba($black, 1) 100%);
	background: -ms-linear-gradient(left, rgba($black, 0) 0%, rgba($black, 0) 37%, rgba($black, 1) 96%, rgba($black, 1) 100%);
	background: linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 0) 37%, rgba($black, 1) 96%, rgba($black, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$black', endColorstr='$black', GradientType=1);
}

.bg-white-opacity {
	background-color: rgba(255, 255, 255, 0.85);
}

.bg-black-opacity {
	background-color: rgba($black, 0.85);
}

.bg-black-opacity-light {
	background-color: rgba($black, 0.5);
}

.bg-secondary-opacity {
	background-color: rgba($primary, 0.85);
}

.bg-charcoal-gray {
	background-color: #0e0f10;
}