/* ===================================
    Aside style
====================================== */

.aside-title { position: relative; overflow: hidden }

.aside-title span { display: inline-block; vertical-align: middle; margin-right: 20px; }

.aside-title:after { content: ""; display: inline-block; width: 100%; background: $primary; height: 1px; top: 50%; position: absolute; }

.right-sidebar { padding-right: 45px; }

.left-sidebar { padding-left: 45px; }