/*
*
* Select 2
*/

/*
Version: 3.5.4 Timestamp: Sun Aug 30 13:30:32 EDT 2015
*/
// variables
$select-color:                $form-input-color;
$select-bg:                   $form-input-background;
$select-bg-disabled:          #f4f4f4;
$select-arrow:                '\f236';
$select-border:               $form-input-border-color;
$select-border-radius:        $form-input-border-radius;
$select-border-active:        $primary;
$select-padding-vertical:     $form-input-padding-vertical;
$select-padding-horizontal:   $form-input-padding-horizontal;

$select-highlighted-bg:       $primary;
$select-highlighted-color:    $white;

.select2-container {
	margin: 0;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 0;
	
	&-active {
		border: 1px solid $primary;
	}
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
	/*
		Force border-box so that % widths fit the parent
		container without overlap because of margin/padding.
		More Info : http://www.quirksmode.org/css/box.html
	*/
	-webkit-box-sizing: border-box; /* webkit */
	-moz-box-sizing: border-box; /* firefox */
	box-sizing: border-box; /* css3 */
}


.select2-container .select2-choice {
	display: block;
	position: relative;
	color: $select-color;
	background-color: $select-bg;
	padding: $select-padding-vertical $select-padding-horizontal;
	border-radius: $select-border-radius;
	text-decoration: none;
	
	> .select2-chosen {
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.select2-dropdown-open .select2-choice {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-arrow {
	display: inline-block;
	position: absolute;
	right: $select-padding-horizontal;
	top: 50%;
	transform: translateY(-50%);
	line-height: 1;
	
	b {
		&:before {
			display: inline-block;
			font-weight: 400;
			content: $select-arrow;
			font-family: $mdi;
			font-size: 22px;
			transform: rotate(0deg);
		}
	}
	
	.select2-dropdown-open & {
		b:before {
			transform: rotate(180deg);
		}
	}
}

.select2-container .select2-choice abbr {
	display: none;
}

.select2-drop-mask {
	border: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	/* styles required for IE to work */
	background-color: $white;
	filter: alpha(opacity=0);
}

.select2-drop {
	width: 100%;
	margin-top: -1px;
	position: absolute;
	z-index: 100;
	top: 100%;
	background: $white;
	color: $select-color;
	border: 1px solid $select-border;
	border-top: 0;
	border-radius: 0 0 $select-border-radius $select-border-radius;
	transition: 0s ease-out all;
	
	&-active {
		border: 1px solid $select-border-active;
		border-top: 0;
	}
}
//select drop above
.select2-drop.select2-drop-above {
	margin-top: 1px;
	border-top: 1px solid $select-border;
	border-bottom: 0;
}

.select2-drop.select2-drop-above.select2-drop-active {
	border: 1px solid $select-border-active;
}

.select2-search {
	display: inline-block;
	width: 100%;
	padding: 4px 4px 0;
	position: relative;
	z-index: 10000;
	white-space: nowrap;
	
	&:before{
		position: absolute;
		right: $select-padding-horizontal;
		top: 50%;
		transform: translateY(calc(-50% + 4px));
		
		display: inline-block;
		content: '\f43b';
		font-family: $mdi;
		font-size: 20px;
		line-height: 1;
	}
}

.select2-search input {
	width: 100%;
	padding: 5px 35px 5px 5px;
	margin: 0;
	
	outline: 0;
	font-size: 1em;
	
	background-color: $select-bg;
	color: $select-color;
	border: 1px solid $select-border;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
	border: 1px solid $select-border;
	border-top-color: transparent;
}

.select2-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* results */
.select2-results {
	padding: 4px 4px;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-result {
	border-radius: $select-border-radius;
	transition: .1s;
}

.select2-results li.select2-result-with-children > .select2-result-label {
	font-weight: bold;
}

.select2-results .select2-result-label {
	padding: 3px 7px 4px;
	margin: 0;
	cursor: pointer;
	min-height: 1em;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.select2-results .select2-highlighted {
	color: $select-highlighted-color;
	background: $select-highlighted-bg;
}

.select2-results li em {
	background: $white;
	font-style: normal;
}

.select2-results .select2-highlighted em {
	background: transparent;
}

.select2-results .select2-highlighted ul {
	background: $white;
	color: $black;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
	color: $select-highlighted-color;
	background: $select-highlighted-bg;
	display: list-item;
	cursor: default;
}

.select2-results .select2-disabled {
	background: $gray-400;
	display: list-item;
	cursor: default;
}

.select2-results .select2-selected {
	display: none;
}

.select2-results .select2-ajax-error {
	background: rgba(255, 50, 50, .2);
}

.select2-more-results {
	background: $select-bg-disabled;
	display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
	background-color: $select-bg-disabled;
	background-image: none;
	border: 1px solid #ddd;
	cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
	background-color: $select-bg-disabled;
	background-image: none;
	border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
	display: none;
}

/* multiselect */
.select2-container-multi {
	display: flex;
	align-items: stretch;
	height: auto;
	min-height: $form-input-height;
}
.select2-container-multi .select2-choices {
	display: flex;
	flex-flow: row;
	align-items: center;
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	
	line-height: 0;
	cursor: text;
	overflow: hidden;
}

.select2-locked {
	padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
	min-height: 26px;
}

.select2-container-multi .select2-choices .select2-search-field {
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
	width: 100% !important;
	
	outline: 0;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
	padding: 4px 5px 4px 20px;
	margin: 3px 0 3px 5px;
	position: relative;
	
	line-height: 13px;
	cursor: default;
	border: 1px solid darken($form-input-border-color, 10%);
	
	background-clip: padding-box;
	
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	
	background-color: darken($form-input-border-color, 10%);
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
	cursor: default;
}

.select2-search-choice-close {
	display: block;
	width: 12px;
	height: 13px;
	position: absolute;
	right: 3px;
	top: 3px;
	color: $gray-700;
	
	&:before{
		display: inline-block;
		content: '\f24c';
		font-family: $mdi;
		font-size: 12px;
		line-height: 1;
	}
}

.select2-container-multi .select2-search-choice-close {
	left: 3px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
	background-color: $select-bg-disabled;
	background-image: none;
	border: 1px solid #ddd;
	cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
	padding: 3px 5px 3px 5px;
	border: 1px solid #ddd;
	background-image: none;
	background-color: $select-bg-disabled;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
	display: none;
	background: none;
}

/* end multiselect */

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
	text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
	clip: rect(0 0 0 0) !important;
	width: 1px !important;
	height: 1px !important;
	border: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	overflow: hidden !important;
	position: absolute !important;
	outline: 0 !important;
	left: 0px !important;
	top: 0px !important;
}

.select2-display-none {
	display: none;
}

.select2-measure-scrollbar {
	position: absolute;
	top: -10000px;
	left: -10000px;
	width: 100px;
	height: 100px;
	overflow: scroll;
}

.bg-extra-dark-gray,
.bg-black {
	.select2-container .select2-choice {
		color: $gray-custom-1;
		background-color: transparent;
	}
	.select2-chosen {
		color: $gray-custom-1;
	}
}