/* ===================================
    Button
====================================== */

.btn {
  display: inline-block;
  border: 2px solid transparent;
  letter-spacing: .5px;
  line-height: inherit;
  border-radius: 0;
  text-transform: uppercase;
  width: auto;
  font-family: $font-sec;
  font-weight: 500;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out
}

/* button size */
.btn.btn-very-small {
  font-size: 9px;
  padding: 1px 17px;
  line-height: 22px;
}

.btn.btn-small {
  font-size: 11px;
  padding: 4px 24px;
}

.btn.btn-medium {
  font-size: 12px;
  padding: 6px 25px 5px;
}

.btn.btn-large {
  font-size: 13px;
  padding: 9px 34px;
  line-height: 25px
}

.btn.btn-extra-large {
  font-size: 15px;
  padding: 12px 40px 13px;
  line-height: 25px
}

.btn-dual .btn {
  margin: 0 10px;
}

.btn i {
  margin-left: 6px;
  vertical-align: middle;
  position: relative;
  top: -1px
}

/* button background */
.btn.btn-white {
  background: $white;
  border-color: $white;
  color: #232323
}

.btn.btn-white:hover, .btn.btn-white:focus {
  background: transparent;
  color: $white
}

.btn.btn-black {
  background: $black;
  border-color: $black;
  color: $white
}

.btn.btn-black:hover, .btn.btn-black:focus {
  background: transparent;
  color: $black
}

.btn.btn-dark-gray {
  background: #232323;
  border-color: #232323;
  color: $white
}

.btn.btn-dark-gray:hover, .btn.btn-dark-gray:focus {
  background: transparent;
  color: #232323
}

.btn.btn-dark-gray-2 {
  background: #232323;
  border-color: #232323;
  color: $white
}

.btn.btn-dark-gray-2:hover, .btn.btn-dark-gray-2:focus {
  background: $white;
  color: #232323
}

.btn.btn-light-gray {
  background: $gray-200;
  border-color: $gray-200;
  color: #232323
}

.btn.btn-light-gray:hover, .btn.btn-light-gray:focus {
  background: transparent;
  border-color: $gray-200;
  color: $gray-200
}

.btn.btn-secondary {
  background: $primary;
  border-color: $primary;
  color: $white
}

.btn.btn-secondary:hover, .btn.btn-secondary:focus {
  background: transparent;
  border-color: $primary;
  color: $primary
}

/* button transparent */
.btn.btn-transparent-white {
  background: transparent;
  border-color: $white;
  color: $white
}

.btn.btn-transparent-white:hover, .btn.btn-transparent-white:focus {
  background: $white;
  border-color: $white;
  color: #232323
}

.btn.btn-transparent-black {
  background: transparent;
  border-color: $black;
  color: $black
}

.btn.btn-transparent-black:hover, .btn.btn-transparent-black:focus {
  background: $black;
  border-color: $black;
  color: $white
}

.btn.btn-transparent-dark-gray {
  background: transparent;
  border-color: #232323;
  color: #232323
}

.btn.btn-transparent-dark-gray:hover, .btn.btn-transparent-dark-gray:focus {
  background: #232323;
  border-color: #232323;
  color: $white
}

.btn.btn-transparent-light-gray {
  background: transparent;
  border-color: $gray-200;
  color: $gray-200
}

.btn.btn-transparent-light-gray:hover, .btn.btn-transparent-light-gray:focus {
  background: $gray-200;
  border-color: $gray-200;
  color: #232323
}

.btn.btn-transparent-secondary {
  background: transparent;
  border-color: $primary;
  color: $primary
}

.btn.btn-transparent-secondary:hover, .btn.btn-transparent-secondary:focus {
  background: $primary;
  border-color: $primary;
  color: $white
}

/* button rounded */
.btn.btn-rounded {
  border-radius: 50px
}

.btn.btn-rounded.btn-very-small {
  padding: 2px 23px 1px;
}

.btn.btn-rounded.btn-small {
  padding: 5px 29px;
}

.btn.btn-rounded.btn-medium {
  padding: 6px 32px;
}

.btn.btn-rounded.btn-large {
  padding: 9px 38px;
}

.btn.btn-rounded.btn-extra-large {
  padding: 12px 45px 13px;
}

/* image button */
.image-button {
  width: 100%;
  background: rgba($black, 0.80);
  padding: 26px;
  font-family: $font-base;
  font-size: 16px;
}

.image-button:hover {
  background: rgba($black, 0.5);
}

.tag-cloud a {
  font-size: 10px;
  padding: 3px 8px;
  border: 1px solid #d9d9d9;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-transform: uppercase;
  color: #232323;
  line-height: 18px;
}

.tag-cloud a:hover {
  background: #232323;
  color: $white !important;
  border: 1px solid #232323;
}

/* dropdown style 1 */
.dropdown-style-1 .btn {
  color: $white;
  font-size: 11px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  font-weight: normal
}

.dropdown-style-1 .btn:hover, .custom-dropdown btn:focus {
  color: $primary;
}

.dropdown-style-1 .dropdown-menu {
  margin-top: 2px;
  min-width: 130px;
  border-radius: 0;
  border: none;
  z-index: 444;
  right: 0;
  left: auto;
}

.dropdown-style-1 .dropdown-menu > li > a {
  padding: 6px 15px;
  font-size: 12px
}

.dropdown-style-1 .dropdown-menu > li > a:hover, .dropdown-style-1 .dropdown-menu > li > a:focus {
  background: $gray-500
}